import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";
import { BsDownload, BsPlusCircleFill } from "react-icons/bs";
import { AddUpdateCommunication } from "./AddUpdateCommunication";
import { useCommunicationColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Input } from "@/components/ui/input";
import Loader, { InLineLoader } from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import { Case } from "@/api/cases";
import {
  Communication,
  communicationsApi,
  legacyCommunicationsApi,
} from "@/api/communications";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { usePermission } from "@/hooks/usePermission";
import { Trans, useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

function CommunicationTable({
  reviewCase,
  refreshKey,
}: {
  reviewCase: Case;
  refreshKey?: string;
}) {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const {
    loading: loadingList,
    refresh,
    apiResult,
    search,
    pagination,
    order,
  } = useListApi<Communication>({
    baseApiObject: communicationsApi,
    defaultParams: {
      case_id: reviewCase.id,
    },
    enable: true,
    defaultOrder: "-start_date",
  });
  const [selectedItem, selectItem] = useState<Communication>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [totalMinutes, setTotalMinutes] = useState<number>(0);
  const errorHandler = useApiErrorHandler("CommunicationTable", {});
  const hasAddCommunicationsPermission = usePermission(
    "communications.add_communication",
  );

  const fetchTotalTime = useCallback(async () => {
    try {
      const results = await communicationsApi.totalTime({
        token: user?.access,
        params: { case_id: reviewCase.id, search: search.value },
      });
      setTotalHours(results.data.hours);
      setTotalMinutes(results.data.minutes);
    } catch (error) {
      errorHandler(error);
    }
  }, [user, reviewCase.id, search.value, errorHandler]);

  async function refreshData() {
    await fetchTotalTime();
    await refresh();
  }

  const columns = useCommunicationColumn({
    refresh: refreshData,
    edit: (item: Communication) => {
      selectItem(item);
      setOpenEdit(true);
    },
    setOrder: order.set,
  });

  const handleDownloadCaseCommunications = async () => {
    try {
      setLoading(true);
      const response =
        await legacyCommunicationsApi.downloadAllCommunicationsOfCase({
          caseId: reviewCase.id,
          token: user?.access,
        });
      FileDownload(response.data, `communications_${reviewCase.id}.pdf`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const totalHoursToMinutes = totalHours * 60;
  const totalCommunicationTimeMinutes = totalHoursToMinutes + totalMinutes;
  const aggregatedHours = Math.floor(totalCommunicationTimeMinutes / 60);
  const aggregatedMinutes = totalCommunicationTimeMinutes % 60;

  useEffect(() => {
    if (refreshKey) {
      refreshData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  useEffect(() => {
    if (user?.access && reviewCase) {
      fetchTotalTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.access, reviewCase.id, search.value]);

  return (
    <div className="w-full my-8">
      <div className="flex flex-wrap gap-4 md:gap-0 justify-between items-center">
        {hasAddCommunicationsPermission && (
          <AddUpdateCommunication
            reviewCase={reviewCase}
            open={openCreate}
            setOpen={setOpenCreate}
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("communications.button.add")}
              </Button>
            }
            refresh={refreshData}
          />
        )}
        <Button
          className="flex gap-2"
          onClick={handleDownloadCaseCommunications}
          disabled={loading}
        >
          <BsDownload /> {t("communications.button.download")}
          {loading && <InLineLoader size={8} />}
        </Button>
        <AddUpdateCommunication
          reviewCase={reviewCase}
          open={openEdit}
          setOpen={setOpenEdit}
          communication={selectedItem}
          refresh={refreshData}
        />
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loadingList ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            {!!totalCommunicationTimeMinutes && (
              <div className="w-full flex justify-center items-center gap-2 mt-4">
                <span>{t("communications.label.total_time")}</span>
                <span>
                  <Trans
                    i18nKey="communications.label.hours_and_minutes"
                    components={{
                      hours: (
                        <b>
                          {aggregatedHours} {t("communications.label.hours")}
                        </b>
                      ),
                      minutes: (
                        <b>
                          {aggregatedMinutes}{" "}
                          {t("communications.label.minutes")}
                        </b>
                      ),
                    }}
                  />
                </span>
              </div>
            )}
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CommunicationTable;
