import { BaseApi } from "@/api/index";
import { Source } from "./source";
import { Case } from "./cases";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface Note {
  id: number;

  title: string;
  author: string;
  date: string;
  private: boolean;
  description: string;
  starting_page: number | null;
  ending_page: number | null;
  starting_bates_number: string;
  ending_bates_number: string;
  file: string | File | null;

  source: Source;
  case: Case;

  // Only for create and update form
  source_id: number;
  case_id: number;

  created_by: number | null;
  updated_by: number | null;
  deleted_by: number | null;
  deleted_at: string | null;
  created_date: string;
  updated_date: string | null;
}

class LegacyNotesApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "notes" });
  }

  downloadCaseNotes({ token, caseId }: { token?: string; caseId: number }) {
    return this.request({
      method: "get",
      url: `/notes-download/${caseId}/`,
      token,
      responseType: "arraybuffer",
    });
  }

  downloadAllNotes({ token }: { token?: string }) {
    return this.request({
      method: "get",
      url: "/notes-download-all/",
      token,
      responseType: "arraybuffer",
    });
  }
}

class NotesApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/notes" });
  }
}

export const legacyNotesApi = new LegacyNotesApi();
export const notesApi = new NotesApi<Note>();
