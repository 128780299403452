import React, { useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { useEventColumn } from "./hooks";
import { Button } from "@/components/ui/button";
import { BsPlusCircleFill } from "react-icons/bs";
import { AddUpdateEvents } from "./AddUpdateEvents";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import Loader from "@/components/ui/loader";
import { Input } from "@/components/ui/input";
import { useListApi } from "@/hooks/useListApi";
import { CaseEvent, caseEventApi } from "@/api/events";
import { Source } from "@/api/source";
import { ResourceInfoModal } from "../ResourceInfoModal";
import { convertFileUrlToFileName } from "@/lib/utils";
import sanitizeHtml from "sanitize-html";
import { usePermission } from "@/hooks/usePermission";
import { casesApi } from "@/api/cases";
import { useReadApi } from "@/hooks/useReadApi";
import { useTranslation } from "react-i18next";
import { PDFFileViewerModal } from "./PDFFileViewerModal";

function SourceEventTable({ source }: { source: Source }) {
  const { t } = useTranslation();
  const [selectedItem, selectItem] = useState<CaseEvent>();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const { loading, apiResult, pagination, search, refresh, order } =
    useListApi<CaseEvent>({
      baseApiObject: caseEventApi,
      defaultParams: { source: source.id },
      enable: true,
      defaultOrder: "title",
    });
  const hasAddEventPermission = usePermission("review.add_event");

  const columns = useEventColumn({
    edit: (item: CaseEvent) => {
      selectItem(item);
      setOpenEdit(true);
    },
    info: (item: CaseEvent) => {
      selectItem(item);
      setOpenInfo(true);
    },
    fileInfo: (item: CaseEvent) => {
      selectItem(item);
      setOpenFile(true);
    },
    refresh,
    setOrder: order.set,
  });

  const { apiResult: reviewCase } = useReadApi(
    casesApi,
    source.case?.toString(),
  );

  return (
    <div className="w-full my-8">
      <div className="flex justify-start gap-4 items-center">
        {reviewCase && (
          <AddUpdateEvents
            open={openEdit}
            setOpen={setOpenEdit}
            caseEvent={selectedItem}
            refresh={refresh}
            defaultCase={reviewCase}
            defaultSource={source}
          />
        )}

        {hasAddEventPermission && reviewCase && (
          <AddUpdateEvents
            open={openCreate}
            setOpen={setOpenCreate}
            refresh={refresh}
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("events.button.add")}
              </Button>
            }
            defaultCase={reviewCase}
            defaultSource={source}
          />
        )}
        {selectedItem?.source?.file && (
          <PDFFileViewerModal
            open={openFile}
            title={selectedItem.title}
            setOpen={setOpenFile}
            file={selectedItem?.source?.file as string}
            pageFrom={
              selectedItem.starting_page
                ? parseInt(selectedItem.starting_page)
                : 0
            }
            pageTo={
              selectedItem.ending_page ? parseInt(selectedItem.ending_page) : 0
            }
          />
        )}
        <ResourceInfoModal
          open={openInfo}
          setOpen={setOpenInfo}
          title={t("events.title.event")}
          mappings={{
            data: [
              {
                label: t("common.label.title"),
                value: selectedItem?.title || "--",
              },
              {
                label: t("common.label.author"),
                value: selectedItem?.author || "--",
              },
              {
                label: t("common.label.date"),
                value: selectedItem?.date || "--",
              },
              {
                label: t("common.label.description"),
                value: sanitizeHtml(selectedItem?.description || "--"),
              },
              {
                label: t("common.label.source"),
                value: selectedItem?.source?.title || "--",
              },
              {
                label: t("events.label.case_tags"),
                value:
                  selectedItem?.tags?.map((item) => item.name).join(", ") ||
                  "--",
              },
              {
                label: t("common.label.source_file"),
                value: selectedItem?.source?.file ? (
                  <a
                    href={selectedItem.source.file.toString()}
                    target="_blank"
                    className="text-left text-primary hover:underline cursor-pointer break-all"
                    rel="noreferrer"
                  >
                    {convertFileUrlToFileName(
                      selectedItem.source.file.toString(),
                    )}
                  </a>
                ) : (
                  "--"
                ),
              },
              {
                label: t("common.label.start_page"),
                value: selectedItem?.starting_page || "--",
              },
              {
                label: t("common.label.end_page"),
                value: selectedItem?.ending_page || "--",
              },
              {
                label: t("events.label.include_in_timeline"),
                value:
                  selectedItem?.include_in_timeline.toString() === "true"
                    ? t("common.values.yes")
                    : t("common.values.no"),
              },
            ],
          }}
        />
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={apiResult?.results || []} />
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
              <CustomPageSelector
                pageSize={pagination.pageSize}
                onPageSizeChange={(value) => {
                  pagination.setPageSize(value);
                  pagination.setPage(1);
                }}
              />
              <CustomPaginationInfo
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
              />
            </div>
            <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
              <CustomPagination
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
                onPageChange={(value) => pagination.setPage(value)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SourceEventTable;
