import React, { useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useTranslation } from "react-i18next";

const HOUR_UNIT = 60 * 60 * 1000;
const MINUTES_UNIT = 60 * 1000;

const timeout = 2 * HOUR_UNIT; // 2 Hours
const promptBeforeIdle = 10 * MINUTES_UNIT; // Show Prompt 10 minutes before 2 hours complete

function UserIdle() {
  const { t } = useTranslation();
  const { logout, refreshToken } = useContext(AuthContext) as AuthContextType;
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    logout?.();
    setOpen(false);
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    refreshToken?.();
    activate();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("common.statictext.are_you_still_here")}</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <p className="text-l mb-4">
            {t("common.statictext.logging_out_time_remaining", {
              remaining_time: remaining,
            })}
          </p>
          <Button onClick={handleStillHere}>
            {t("common.statictext.i_am_still_here")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UserIdle;
