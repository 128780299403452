import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import * as z from "zod";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import {
  SearchParams,
  SearchResearchDocument,
  searchResearchDocumentsApi,
  SearchSource,
  searchSourcesApi,
} from "@/api/search";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useListApi } from "@/hooks/useListApi";
import { DynamicSelector } from "../DynamicSelector";
import { Source, sourcesApi } from "@/api/source";
import { Case, casesApi } from "@/api/cases";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { useTranslation } from "react-i18next";

function SearchPage() {
  const { t } = useTranslation();
  const errorHandler = useApiErrorHandler("SearchPage", {});
  const [queryParams, setQueryParams] = useState<{
    q?: string;
    facets?: string;
  }>({});

  const {
    loading: searchSourceLoading,
    pagination: sourceSearchPagination,
    apiResult: sourceSearchApiResult,
  } = useListApi<SearchSource>({
    baseApiObject: searchSourcesApi,
    enable: Object.keys(queryParams).length > 0,
    defaultParams: queryParams,
  });

  const {
    loading: searchResearchDocumentLoading,
    pagination: researchDocumentSearchPagination,
    apiResult: researchDocumentSearchApiResult,
  } = useListApi<SearchResearchDocument>({
    baseApiObject: searchResearchDocumentsApi,
    enable: Object.keys(queryParams).length > 0,
    defaultParams: queryParams,
  });

  const {
    loading: casesLoading,
    search: caseSearch,
    loadNext: loadNextCase,
    list: casesList,
  } = useListApi<Case>({ baseApiObject: casesApi, enable: true });

  const sourcesType = t("search.type.sources");
  const researchDocumentsType = t("search.type.documents");

  const FormSchema = z.object({
    search: z.string().min(1, t("search.statictext.input_required")),
    case: z.string().optional(),
    source: z.string().optional(),
    type: z.enum([sourcesType, researchDocumentsType], {
      required_error: t("search.statictext.search_type_required"),
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      search: "",
      type: sourcesType,
      case: "",
      source: "",
    },
  });

  const caseId = useWatch({
    name: "case",
    control: form.control,
  });

  const {
    loading: caseSourceLoading,
    list: caseSources,
    loadNext,
    search,
  } = useListApi<Source>({
    baseApiObject: sourcesApi,
    defaultParams: { case_id: caseId },
    enable: !!caseId,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const params: SearchParams = {};
      const facets = [];
      if (data.source && data.source !== "all") {
        facets.push(`source:${data.source}`);
      }
      if (data.case && data.case !== "all") {
        facets.push(`case:${data.case}`);
      }
      if (data.search) {
        params.q = data.search;
      }
      if (facets.length > 0) {
        params.facets = facets.join(",");
      }
      setQueryParams(params);
    } catch (error) {
      errorHandler(error);
    }
  }

  const searchType = form.getValues().type;

  return (
    <>
      <div className="w-full my-8 flex flex-col items-center justify-center">
        <h3 className="text-3xl font-bold text-center mb-8">
          {t("search.title.search")}
        </h3>
        <div className="md:w-[60%]">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full flex flex-col gap-8"
            >
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("search.label.search")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("search.placeholder.enter_query")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="case"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("common.label.case")}{" "}
                      {casesLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <DynamicSelector
                      onValueChange={field.onChange}
                      value={field.value || undefined}
                      options={casesList.map((item) => ({
                        label: item.name,
                        value: item.id.toString(),
                      }))}
                      className="selector"
                      onLoadNext={loadNextCase}
                      onSearch={(searchValue) => {
                        caseSearch.set(searchValue);
                      }}
                      placeholder={t("search.placeholder.select_case")}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="source"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("common.label.source")}{" "}
                      {caseSourceLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <DynamicSelector
                      onValueChange={field.onChange}
                      value={field.value || undefined}
                      options={caseSources.map((item) => ({
                        label: item.title,
                        value: item.id.toString(),
                      }))}
                      className="selector"
                      onLoadNext={loadNext}
                      onSearch={(searchValue) => {
                        search.set(searchValue);
                      }}
                      placeholder={t("search.placeholder.source")}
                      includeAllOption={true}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>{t("search.label.search_type")}</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-col space-y-1"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={sourcesType} />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {sourcesType}
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={researchDocumentsType} />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {researchDocumentsType}
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">{t("common.button.search")}</Button>
            </form>
          </Form>
        </div>

        <div className="w-full my-8 md:w-[60%] flex flex-col gap-4">
          {searchSourceLoading || searchResearchDocumentLoading
            ? t("common.button.loading")
            : ""}

          {searchType === sourcesType && (
            <>
              {sourceSearchApiResult?.results.map((item) => (
                <Card key={item.title}>
                  <CardHeader>
                    <CardTitle>{item.title}</CardTitle>
                    <CardDescription>{item.description}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p>
                      <b>{t("search.label.text")}:</b> {item.text}
                    </p>
                    <p>
                      <b>{t("search.label.extracted_text")}:</b>{" "}
                      {item.extracted_text || "--"}
                    </p>
                    <p>
                      <b>{t("search.label.institution")}:</b>{" "}
                      {item?.institution || "--"}
                    </p>
                    <br />
                    <p>
                      <b>{t("common.label.case")}: </b>
                      {casesList.find((subItem) => subItem.id === item?.case)
                        ?.name || "--"}
                    </p>
                    <p>
                      <b>{t("common.label.source")}: </b>
                      {caseSources.find(
                        (subItem) => subItem.id === item?.source,
                      )?.title || "--"}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
          {searchType === researchDocumentsType && (
            <>
              {researchDocumentSearchApiResult?.results.map((item) => (
                <Card key={item.title}>
                  <CardHeader>
                    <CardTitle>{item.title}</CardTitle>
                    <CardDescription>{item.text}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p>
                      <b>{t("search.label.journal_name")}:</b>{" "}
                      {item?.journal_name || "--"}
                    </p>
                    <p>
                      <b>{t("search.label.volume")}:</b> {item?.volume || "--"}
                    </p>
                    <p>
                      <b>{t("search.label.supplement_or_issue")}:</b>{" "}
                      {item?.supplement_or_issue || "--"}
                    </p>
                    <p>
                      <b>{t("search.label.extracted_text")}:</b>{" "}
                      {item.extracted_text || "--"}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </div>
      </div>

      {searchType === sourcesType && (
        <div className="w-full my-8">
          <CustomPageSelector
            pageSize={sourceSearchPagination.pageSize}
            onPageSizeChange={(value) => {
              sourceSearchPagination.setPageSize(value);
              sourceSearchPagination.setPage(1);
            }}
          />
          <CustomPagination
            totalCount={sourceSearchApiResult?.count || 0}
            pageSize={sourceSearchPagination.pageSize}
            currentPage={sourceSearchPagination.page}
            onPageChange={(value) => sourceSearchPagination.setPage(value)}
          />
        </div>
      )}

      {searchType === researchDocumentsType && (
        <div className="w-full my-8">
          <CustomPageSelector
            pageSize={researchDocumentSearchPagination.pageSize}
            onPageSizeChange={(value) => {
              researchDocumentSearchPagination.setPageSize(value);
              researchDocumentSearchPagination.setPage(1);
            }}
          />
          <CustomPagination
            totalCount={researchDocumentSearchApiResult?.count || 0}
            pageSize={researchDocumentSearchPagination.pageSize}
            currentPage={researchDocumentSearchPagination.page}
            onPageChange={(value) =>
              researchDocumentSearchPagination.setPage(value)
            }
          />
        </div>
      )}
    </>
  );
}

export default SearchPage;
