import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";
import { BsDownload, BsPlusCircleFill } from "react-icons/bs";
import { AddUpdateNote } from "./AddUpdateNote";
import { useNotesColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Input } from "@/components/ui/input";
import Loader, { InLineLoader } from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import { Case } from "@/api/cases";
import { legacyNotesApi, Note, notesApi } from "@/api/notes";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { ResourceInfoModal } from "../ResourceInfoModal";
import { convertFileUrlToFileName } from "@/lib/utils";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

function NotesTable({
  reviewCase,
  refreshKey,
}: {
  reviewCase: Case;
  refreshKey?: string;
}) {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;

  const {
    loading: loadingList,
    refresh,
    apiResult,
    search,
    pagination,
    order,
  } = useListApi<Note>({
    baseApiObject: notesApi,
    defaultParams: { case: reviewCase.id },
    enable: true,
  });
  const [selectedItem, selectItem] = useState<Note>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const hasAddNotePermission = usePermission("notes.add_note");

  const errorHandler = useApiErrorHandler("NotesTable", {});

  const columns = useNotesColumn({
    refresh,
    setOrder: order.set,
    edit: (item: Note) => {
      selectItem(item);
      setOpenEdit(true);
    },
    info: (item: Note) => {
      selectItem(item);
      setOpenInfo(true);
    },
  });

  const handleDownloadCaseCommunications = async () => {
    try {
      setLoading(true);
      const response = await legacyNotesApi.downloadCaseNotes({
        caseId: reviewCase.id,
        token: user?.access,
      });
      FileDownload(response.data, `notes_${reviewCase.id}.pdf`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (refreshKey) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  return (
    <div className="w-full my-8">
      <div className="flex flex-wrap justify-between items-center gap-4 md:gap-0">
        {hasAddNotePermission && (
          <AddUpdateNote
            reviewCaseId={reviewCase.id}
            open={openCreate}
            setOpen={setOpenCreate}
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("notes.button.add")}
              </Button>
            }
            refresh={refresh}
          />
        )}
        <ResourceInfoModal
          open={openInfo}
          setOpen={setOpenInfo}
          title={t("notes.title.note")}
          mappings={{
            data: [
              {
                label: t("common.label.title"),
                value: selectedItem?.title || "--",
              },
              {
                label: t("common.label.author"),
                value: selectedItem?.author || "--",
              },
              {
                label: t("common.label.date"),
                value: selectedItem?.date || "--",
              },
              {
                label: t("common.label.description"),
                value: selectedItem?.description || "--",
              },
              {
                label: t("common.label.file"),
                value: selectedItem?.file ? (
                  <a
                    href={selectedItem.file.toString()}
                    target="_blank"
                    rel="noreferrer"
                    className="text-left text-primary hover:underline cursor-pointer break-all"
                  >
                    {convertFileUrlToFileName(selectedItem.file.toString())}
                  </a>
                ) : (
                  "--"
                ),
              },
              {
                label: t("common.label.source"),
                value: selectedItem?.source?.title || "--",
              },
              {
                label: t("common.label.case"),
                value: selectedItem?.case?.name.toString() || "--",
              },
              {
                label: t("common.label.source_file"),
                value: selectedItem?.source?.file ? (
                  <a
                    href={selectedItem?.source?.file.toString()}
                    target="_blank"
                    className="text-left text-primary hover:underline cursor-pointer break-all"
                    rel="noreferrer"
                  >
                    {convertFileUrlToFileName(
                      selectedItem.source?.file.toString(),
                    )}
                  </a>
                ) : (
                  "--"
                ),
              },
              {
                label: t("common.label.start_page"),
                value: selectedItem?.starting_page || "--",
              },
              {
                label: t("common.label.end_page"),
                value: selectedItem?.ending_page || "--",
              },
              {
                label: t("common.label.start_bates_number"),
                value: selectedItem?.starting_bates_number || "--",
              },
              {
                label: t("common.label.end_bates_number"),
                value: selectedItem?.ending_bates_number || "--",
              },
            ],
          }}
        />
        <Button
          className="flex gap-2"
          onClick={handleDownloadCaseCommunications}
          disabled={loading}
        >
          <BsDownload /> {t("notes.button.download")}
          {loading && <InLineLoader size={8} />}
        </Button>
        <AddUpdateNote
          reviewCaseId={reviewCase.id}
          open={openEdit}
          setOpen={setOpenEdit}
          note={selectedItem}
          refresh={refresh}
        />
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loadingList ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default NotesTable;
