import { Margin, Resolution } from "react-to-pdf";

export const pdfOptions = {
  resolution: Resolution.HIGH,
  page: {
    margin: Margin.MEDIUM,
    format: "letter",
    orientation: "landscape" as
      | "landscape"
      | "p"
      | "portrait"
      | "l"
      | undefined,
  },
  canvas: {
    mimeType: "image/png" as "image/png" | "image/jpeg" | undefined,
    qualityRatio: 1,
  },
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};
