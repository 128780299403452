import React from "react";
import { useTranslation } from "react-i18next";

export const CustomPaginationInfo = ({
  currentPage,
  totalCount,
  pageSize,
}: {
  currentPage: number;
  totalCount: number;
  pageSize: number;
}) => {
  const { t } = useTranslation();
  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(startRecord + pageSize - 1, totalCount);

  if (totalCount > 0) {
    return (
      <div className="md:text-left text-center  mx-4 mt-6 ">
        {t("components.statictext.pagination", {
          start: startRecord,
          end: endRecord,
          total: totalCount,
        })}
      </div>
    );
  }

  return null;
};
