import React from "react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Input } from "@/components/ui/input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { InLineLoader } from "@/components/ui/loader";
import { useTranslation } from "react-i18next";

function Login() {
  const { t } = useTranslation();
  const location = useLocation();
  const { login, values: contextValues } = React.useContext(
    AuthContext,
  ) as AuthContextType;
  const { isLoggedin } = contextValues;
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const [error, setError] = React.useState<string | undefined>();

  const formSchema = z.object({
    username: z.string().min(2, {
      message: t("auth.statictext.username_too_short"),
    }),
    password: z.string().min(8, {
      message: t("auth.statictext.password_too_short"),
    }),
    otp_code: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
      otp_code: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    setLoading(true);
    login?.(values.username, values.password, values.otp_code)
      .then(() => {
        setError(undefined);
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      })
      .catch((errorMessage) => {
        console.error(errorMessage);
        setError(t("auth.statictext.invalid_or_wrong_2fa"));
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      });
  }

  if (isLoggedin) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center gap-8">
      {!!error && (
        <Alert variant="destructive" className="w-[350px]">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{t("common.title.error")}</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <Card className="w-[350px]">
            <CardHeader>
              <CardTitle>{t("auth.title.login")}</CardTitle>
              <CardDescription>
                {t("auth.statictext.login_to_casereview")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-4">
                  <FormField
                    control={form.control}
                    name="username"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("common.label.username")}</FormLabel>
                        <FormControl>
                          <Input
                            placeholder={t("auth.placeholder.username")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("auth.label.password")}</FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            placeholder={t("auth.placeholder.password")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="otp_code"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("auth.label.enter_2fa")}</FormLabel>
                        <FormControl>
                          <Input
                            type="otp_code"
                            placeholder={t("auth.placeholder.enter_2fa")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button type="submit">
                {loading ? <InLineLoader size={8} /> : t("auth.button.login")}
              </Button>
              <Button
                onClick={() => navigate("/forgot-password")}
                variant="ghost"
              >
                {t("auth.statictext.forgot_password")}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  );
}

export default Login;
