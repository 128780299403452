import React, { useContext } from "react";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function UserNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { values: contextValues, logout } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { user } = contextValues;

  return user ? (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="relative h-8 w-8 rounded-full ring-2 ring-primary"
        >
          <Avatar className="h-8 w-8">
            <AvatarFallback>
              {user?.user.username.substring(0, 2)}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {user?.user.username}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {user?.user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate("/setup-2fa")}>
          {t("components.button.2fa")}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            logout?.();
          }}
        >
          {t("components.button.logout")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : null;
}
