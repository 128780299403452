import { BaseApi } from "@/api/index";
import { Case } from "./cases";
import { User } from "./users";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface ReviewCaseAddEditDefaultTags {
  id: number;
  url: string;
  name: string;
  checked: boolean;
}
export interface ReviewCaseAddEditCustomTags {
  id: number;
  url: string;
  placeholder: string;
  name: string;
  checked: boolean;
}
export interface ReviewCaseAddEditTags {
  case_id: number;
  default_tags: ReviewCaseAddEditDefaultTags[];
  custom_tags: ReviewCaseAddEditCustomTags[];
}
export interface ReviewCasePermission {
  case: Case;
  allowed_users: number[];
  groups: [string, User[]][];
}
export interface CasePermission {
  model: "case" | "source";
  permission: "access_case" | "access_source";
  operation: "add" | "remove";
  user_id: number;
}

export interface CaseSourcePermission {
  operation: "add" | "remove";
  user_id: number;
}

class ReviewApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "review" });
  }

  requestInformation({
    token,
    title,
    caseId,
    message,
  }: {
    token?: string;
    title: string;
    caseId: number;
    message: string;
  }) {
    return this.request({
      method: "post",
      url: "/api/information-request/",
      token,
      data: {
        title,
        case: caseId,
        message,
      },
    });
  }

  downloadReviewCase({ id, token }: { id: number; token?: string }) {
    return this.request({
      method: "get",
      url: `/download_resources/${id}/`,
      token,
      responseType: "arraybuffer",
    });
  }

  caseTimeline({
    id,
    token,
    tags,
    year,
  }: {
    id: number;
    token?: string;
    tags?: string;
    year?: string;
  }) {
    return this.request({
      method: "get",
      url: `/case_timeline/${id}/`,
      token,
      params: {
        tags: tags,
        year,
      },
    });
  }

  getCaseTags({ id, token }: { id: number; token?: string }) {
    return this.request<ReviewCaseAddEditTags>({
      method: "get",
      url: `/case-tags/${id}/`,
      token,
    });
  }

  addEditCaseTags({
    id,
    token,
    data,
  }: {
    id: number;
    token?: string;
    data: { tags: { key: string; value: string }[] };
  }) {
    return this.request<{ tags: { key: string; value: string }[] }>({
      method: "post",
      url: `/case-tags/${id}/`,
      token,
      data,
    });
  }

  getCasePermissions({ id, token }: { id: number; token?: string }) {
    return this.request<ReviewCasePermission>({
      method: "get",
      url: `/case-management-api/${id}/`,
      token,
    });
  }

  updatePermissions({
    id,
    token,
    data,
  }: {
    id: number;
    token?: string;
    data: CasePermission;
  }) {
    return this.request<CasePermission>({
      method: "POST",
      url: `/api/case-permission/${id}/`,
      token,
      data,
    });
  }

  updateCaseSourcesPermissions({
    id,
    token,
    data,
  }: {
    id: number;
    token?: string;
    data: CaseSourcePermission;
  }) {
    return this.request<CaseSourcePermission>({
      method: "POST",
      url: `/api/source-permission/${id}/`,
      token,
      data,
    });
  }
}

export const reviewApi = new ReviewApi();
