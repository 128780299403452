import { BaseApi } from "@/api/index";
import { Source } from "./source";
import { CaseTag } from "./tags";

export interface CaseEvent {
  id: number;

  // Custom Fields for front-end to handle create/update
  // There is code on backend serializer to handle this fields.
  source_id?: string;
  case_tag_ids?: number[];

  title: string;
  author: string;
  date: string;
  description: string; // can be html code.
  starting_page: string | null;
  ending_page: string | null;
  starting_bates_number: string;
  ending_bates_number: string;
  include_in_timeline: boolean;
  background_color: string;

  source: Source | null;
  tags: CaseTag[];
  case_tags: number[];

  created_by: number | null;
  updated_by: number | null;
  deleted_by: number | null;
  deleted_at: string | null;
  created_date: string;
  updated_date: string | null;
}

export interface CaseTimelineResults {
  column: string;
  events: CaseEvent[];
}

export interface CaseTimeline {
  end_date: string;
  end_period: string;
  results: CaseTimelineResults[];
  start_date: string;
  start_period: string;
}

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

class CaseEventApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/events/" });
  }
}

class LegacyEventsApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "events" });
  }

  downloadEvents({
    token,
    caseId,
    filterCaseIds,
    params,
  }: {
    token?: string;
    caseId: number;
    filterCaseIds?: number[];
    params?: Record<string, unknown>;
  }) {
    return this.request({
      method: "get",
      url: `/download_events/${caseId}/`,
      ...(filterCaseIds ? { event_ids: filterCaseIds.join(",") } : {}),
      token,
      params,
      responseType: "arraybuffer",
    });
  }
}
export const caseEventApi = new CaseEventApi<CaseEvent>();
export const legacyCaseEventApi = new LegacyEventsApi();
