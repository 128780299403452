import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { useCaseSourceColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Button } from "@/components/ui/button";
import { BsPlusCircleFill } from "react-icons/bs";
import { AddUpdateCaseSource } from "./AddUpdateCaseSource";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import Loader, { InLineLoader } from "@/components/ui/loader";
import { Input } from "@/components/ui/input";
import { legacySourcesApi, Source, sourcesApi } from "@/api/source";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { usePermission } from "@/hooks/usePermission";
import { Case } from "@/api/cases";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

function CaseSourceTableForCase({
  reviewCase,
  refreshKey,
}: {
  reviewCase: Case;
  refreshKey?: string;
}) {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const [selectedCaseSource, selectCaseSource] = useState<Source>();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [loadingAll, setLoadingAll] = useState<boolean>(false);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const errorHandler = useApiErrorHandler("CaseSourceTableForCase", {});
  const hasAddSourcePermission = usePermission("review.add_source");

  const { refresh, loading, pagination, apiResult, search, order } =
    useListApi<Source>({
      baseApiObject: sourcesApi,
      defaultParams: { case_id: reviewCase.id },
      enable: true,
    });

  const fetchTotalPages = useCallback(async () => {
    try {
      const results = await sourcesApi.totalPages({
        token: user?.access,
        params: { case_id: reviewCase.id, search: search.value },
      });
      setTotalPages(results.data.total_pages__sum);
    } catch (error) {
      errorHandler(error);
    }
  }, [user, reviewCase.id, search.value, errorHandler]);

  async function refreshData() {
    await fetchTotalPages();
    await refresh();
  }

  const { columns } = useCaseSourceColumn({
    refresh: refreshData,
    setOrder: order.set,
    edit: (item: Source) => {
      selectCaseSource(item);
      setOpenEdit(true);
    },
  });

  const handleDownloadList = async () => {
    try {
      setLoadingList(true);
      const response = await legacySourcesApi.downloadExcelList({
        token: user?.access,
        caseId: reviewCase.id,
      });
      FileDownload(response.data, `sources_list_${reviewCase.name}.xlsx`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingList(false);
    }
  };

  const handleDownloadAllSources = async () => {
    try {
      setLoadingAll(true);
      const response = await legacySourcesApi.downloadAllSources({
        token: user?.access,
        caseId: reviewCase.id,
      });
      FileDownload(response.data, `all_sources_${reviewCase.name}.zip`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingAll(false);
    }
  };

  useEffect(() => {
    if (refreshKey) {
      refreshData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  useEffect(() => {
    if (user?.access && reviewCase) {
      fetchTotalPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.access, reviewCase.id, search.value]);

  return (
    <div className="w-full my-8">
      <div className="flex flex-wrap justify-start gap-4 pb-4 items-center">
        <AddUpdateCaseSource
          open={openEdit}
          setOpen={setOpenEdit}
          caseSource={selectedCaseSource}
          refresh={refreshData}
          defaultCase={reviewCase}
        />
        {hasAddSourcePermission && (
          <AddUpdateCaseSource
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("sources.button.add")}
              </Button>
            }
            open={openCreate}
            setOpen={setOpenCreate}
            refresh={refreshData}
            defaultCase={reviewCase}
          />
        )}
        <Button onClick={() => handleDownloadList()} disabled={loadingList}>
          {t("sources.button.download_list")}
          {loadingList && <InLineLoader size={8} />}
        </Button>
        <Button
          onClick={() => handleDownloadAllSources()}
          disabled={loadingAll}
        >
          {t("sources.button.download_all")}
          {loadingAll && <InLineLoader size={8} />}
        </Button>
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loading ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="w-full flex justify-center items-center gap-2 mt-4">
              <span>
                {t("sources.label.total_number_of_pages_all_sources")}
              </span>
              <span>
                <b>{totalPages}</b>
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
              <CustomPageSelector
                pageSize={pagination.pageSize}
                onPageSizeChange={(value) => {
                  pagination.setPageSize(value);
                  pagination.setPage(1);
                }}
              />
              <CustomPaginationInfo
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
              />
            </div>
            <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
              <CustomPagination
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
                onPageChange={(value) => pagination.setPage(value)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CaseSourceTableForCase;
