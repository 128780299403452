import React, { ReactNode, useContext, useState } from "react";
import { MainNav } from "@/components/custom/main-nav";
import { UserNav } from "@/components/custom/user-nav";
import { Toaster } from "@/components/ui/toaster";
import { InfoRequest } from "@/components/custom/InfoRequest/InfoRequest";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Button } from "@/components/ui/button";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <div className="border-b pb-2 md:pb-0">
      <div className="flex flex-wrap h-18 md:h-16 items-center px-4">
        <h2 className="text-xl md:text-3xl font-bold tracking-tight w-full md:w-auto text-center md:text-left my-2 md:my-0">
          <Link to="/">
            <span className="text-primary">{t("common.title.case")}</span>
            {t("common.title.review")}
          </Link>
        </h2>
        <div className="md:ml-auto flex justify-center items-center md:space-x-4 w-full md:w-auto text-center md:text-left">
          <MainNav className="mx-2 md:mx-6" />
          <UserNav />
        </div>
      </div>
    </div>
  );
}

function Footer() {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const [openRequestInfo, setOpenRequestInfo] = useState<boolean>(false);

  return (
    <div className="border-t flex flex-wrap-reverse justify-center gap-2 md:gap-0 md:justify-between items-center px-4 w-full">
      <div className="flex flex-col text-center gap-2 h-16 items-center md:px-4 md:flex-row md:text-left">
        <span>
          {t("layout.statictext.copyright", {
            currentYear: new Date().getFullYear(),
          })}
        </span>
      </div>
      {!!user && (
        <InfoRequest
          open={openRequestInfo}
          setOpen={setOpenRequestInfo}
          trigger={
            <Button variant="link" className="flex gap-1">
              <BsFillInfoCircleFill /> {t("layout.button.request_info")}
            </Button>
          }
        />
      )}
    </div>
  );
}

function Content({ children }: { children: ReactNode }) {
  return <div className="mb-auto px-4 py-8 min-h-screen">{children}</div>;
}

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <ScrollToTop />
      <Toaster />
      <div className="flex-col min-h-screen justify-between">
        <Header />
        <Content>{children}</Content>
        <Footer />
      </div>
    </>
  );
}
