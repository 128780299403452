import React from "react";
import { DataTable } from "@/components/ui/data-table";
import { useTimelineColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Timeline, timelinesApi } from "@/api/timelines";
import { Input } from "@/components/ui/input";
import Loader from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

function TimelinesTable() {
  const { t } = useTranslation();
  const { loading, apiResult, search, pagination, refresh } =
    useListApi<Timeline>({ baseApiObject: timelinesApi, enable: true });

  const columns = useTimelineColumn({
    refresh,
  });

  return (
    <div className="w-full my-8">
      <h3 className="text-3xl font-bold text-center mb-8">
        {t("timelines.title.timelines")}
      </h3>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loading ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TimelinesTable;
