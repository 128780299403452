import React, { useCallback, useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import {
  ResearchAuthor,
  researchAuthorApi,
  ResearchDocument,
  researchDocumentApi,
} from "@/api/research";
import { useListApi } from "@/hooks/useListApi";
import { useTranslation } from "react-i18next";

export function DeleteResearchDocument({
  trigger,
  document,
  refresh,
}: {
  document: ResearchDocument;
  trigger: JSX.Element;
  refresh: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading: researchAuthorLoading, apiResult } =
    useListApi<ResearchAuthor>({
      baseApiObject: researchAuthorApi,
      defaultParams: {
        research: document.id,
      },
      enable: open,
    });

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const errorHandler = useApiErrorHandler("DeleteResearchDocument", {});

  const onSubmit = useCallback(async () => {
    try {
      setLoading?.(true);
      await researchDocumentApi.delete({
        id: document.id.toString(),
        token: user?.access,
        data: {
          deleted_by: user?.user.id,
        },
      });
      await Promise.all(
        apiResult?.results
          .filter((item) => item.research === document.id)
          .map((item) => {
            return researchAuthorApi.delete({
              id: item.id.toString(),
              token: user?.access,
              data: {
                deleted_by: user?.user.id,
              },
            });
          }) || [],
      );
      toast({
        title: t("common.toast.deleted_successfully", {
          resource: t("research.title.research_document"),
        }),
      });
      await refresh();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }, [apiResult, document.id, errorHandler, refresh, setLoading, user, t]);

  function onCancel() {
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("research.title.delete_document")}</DialogTitle>
          <DialogDescription>
            {t("common.statictext.confirm_delete", { name: document.title })}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant="secondary" disabled={loading} onClick={onCancel}>
            {loading ? t("common.button.loading") : t("common.button.cancel")}
          </Button>
          <Button
            variant="destructive"
            disabled={loading || researchAuthorLoading}
            onClick={onSubmit}
          >
            {loading || researchAuthorLoading
              ? t("common.button.loading")
              : t("common.button.confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
