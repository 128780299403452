import React, { useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import {
  researchDocumentLegacyApi,
  ResearchFolder,
  researchFolderApi,
} from "@/api/research";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import {
  RenderDefaultDocumentsList,
  RenderDocumentsList,
} from "../Documents/ResearchDocumentList";
import { AddUpdateResearchFolder } from "./AddUpdateResearchFolder";
import { DeleteResearchFolder } from "./DeleteResearchFolder";
import { AddUpdateResearchDocument } from "../Documents/AddUpdateResearchDocument";
import { useListApi } from "@/hooks/useListApi";
import { useIsCreatorOrAdmin, usePermission } from "@/hooks/usePermission";
import { InLineLoader } from "@/components/ui/loader";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

const DEFAULT_PUBLIC_FOLDER_ID = 0;
const DEFAULT_PRIVATE_FOLDER_ID = -1;

function ResearchFolderItem({
  item,
  handleSelection,
  selected,
  refresh,
}: {
  item: ResearchFolder;
  handleSelection: React.Dispatch<React.SetStateAction<number>>;
  selected: boolean;
  refresh: () => Promise<void>;
}) {
  const additionalClassName = selected
    ? "bg-primary text-white"
    : "hover:bg-primary hover:text-white";

  const isCreatorOrAdmin = useIsCreatorOrAdmin(item);

  return (
    <div
      onClick={() => handleSelection(item.id)}
      className={
        "flex justify-between px-4 h-[100%] py-4 " + additionalClassName
      }
    >
      <div>{item.name}</div>
      <div className="flex gap-2 items-center">
        {isCreatorOrAdmin && (
          <AddUpdateResearchFolder
            trigger={
              <BsFillPencilFill className="hover:text-black cursor-pointer" />
            }
            folder={item}
            refresh={refresh}
          />
        )}
        {isCreatorOrAdmin && (
          <DeleteResearchFolder
            trigger={
              <BsTrashFill className="hover:text-black cursor-pointer" />
            }
            folder={item}
            refresh={refresh}
          />
        )}
      </div>
    </div>
  );
}

function ResearchFolders() {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const [openAddResearchDocument, setOpenAddResearchDocument] =
    useState<boolean>(false);

  const { apiResult, refresh } = useListApi<ResearchFolder>({
    baseApiObject: researchFolderApi,
    enable: true,
    defaultPageSize: 100,
  });
  const researchFoldersList = apiResult?.results || [];

  const [selectedFolderId, selectFolderId] = useState<number>(0);
  const [citationType, setCitationType] = useState<"bluebook" | "apa">(
    "bluebook",
  );
  const errorHandler = useApiErrorHandler("ResearchFolders", {});
  const hasAddResearchPermission = usePermission("research.add_research");

  const [loadingAllDocument, setLoadingAllDocument] = useState<boolean>(false);
  const [loadingListPdf, setLoadingListPdf] = useState<boolean>(false);

  const getFolderName = (folderId: number) => {
    const folderName = researchFoldersList.find((item) => item.id === folderId);
    if (!folderName?.name) {
      if (folderId === -1) {
        return t("research.values.default_private_folder");
      }
      return t("research.values.default_public_folder");
    }
    return folderName.name;
  };

  const handleDownloadAllDocments = async (folderId: number) => {
    try {
      setLoadingAllDocument(true);
      const response = await researchDocumentLegacyApi.downloadAllDocuments({
        token: user?.access,
        folderId,
      });
      const folderName = getFolderName(folderId);
      FileDownload(response.data, `${folderName}.zip`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingAllDocument(false);
    }
  };

  const handleDownloadListPdf = async (folderId: number) => {
    try {
      setLoadingListPdf(true);
      const response = await researchDocumentLegacyApi.downloadListInPdf({
        token: user?.access,
        folderId,
      });
      const folderName = getFolderName(folderId);
      FileDownload(response.data, `${folderName}_list.pdf`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingListPdf(false);
    }
  };

  const defaultPublicFolderClassName =
    selectedFolderId === DEFAULT_PUBLIC_FOLDER_ID
      ? "bg-primary text-white"
      : "hover:bg-primary hover:text-white";
  const defaultPrivateFolderClassName =
    selectedFolderId === DEFAULT_PRIVATE_FOLDER_ID
      ? "bg-primary text-white"
      : "hover:bg-primary hover:text-white";

  return (
    <div className="flex flex-col text-center justify-center w-full items-center">
      <h3 className="text-3xl font-bold">
        {t("research.title.research_documents")}
      </h3>
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col md:flex-row flex-wrap gap-4 w-full justify-center my-4">
          {hasAddResearchPermission && (
            <AddUpdateResearchDocument
              trigger={<Button>{t("research.button.add_document")}</Button>}
              open={openAddResearchDocument}
              setOpen={setOpenAddResearchDocument}
              refresh={refresh}
            />
          )}
          <AddUpdateResearchFolder
            trigger={<Button>{t("research.button.add_folder")}</Button>}
            refresh={refresh}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full h-auto mt-16">
        <div className="w-[100%] md:w-[30%]">
          <div className="flex flex-col gap-8 my-4">
            <h4 className="font-bold text-xl">
              {t("research.title.public_folders")}
            </h4>
            <div className="flex flex-col gap-4 h-[200px] md:h-auto md:max-h-[400px] overflow-y-auto border md:border-none">
              {researchFoldersList
                .filter((item) => item.public)
                .map((item) => (
                  <ResearchFolderItem
                    key={item.id}
                    item={item}
                    selected={selectedFolderId === item.id}
                    handleSelection={selectFolderId}
                    refresh={refresh}
                  />
                ))}
              <div
                onClick={() => selectFolderId(DEFAULT_PUBLIC_FOLDER_ID)}
                className={
                  "flex justify-between px-4 h-[100%] py-4 " +
                  defaultPublicFolderClassName
                }
              >
                <div>{t("research.values.default_public_folder")}</div>
              </div>
            </div>
          </div>

          <hr />

          <div className="flex flex-col gap-8 my-4">
            <h4 className="font-bold text-xl">
              {t("research.title.private_folders")}
            </h4>
            <div className="flex flex-col gap-4 h-[200px] md:h-auto md:max-h-[400px] overflow-y-auto border md:border-none">
              {researchFoldersList
                .filter((item) => !item.public)
                .map((item) => (
                  <ResearchFolderItem
                    key={item.id}
                    item={item}
                    selected={selectedFolderId === item.id}
                    handleSelection={selectFolderId}
                    refresh={refresh}
                  />
                ))}
              <div
                onClick={() => selectFolderId(DEFAULT_PRIVATE_FOLDER_ID)}
                className={
                  "flex justify-between px-4 h-[100%] py-4 " +
                  defaultPrivateFolderClassName
                }
              >
                <div>{t("research.values.default_private_folder")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] md:w-[70%] border p-4">
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between mt-4">
            <div className="flex flex-wrap gap-2 items-center">
              <Button
                disabled={selectedFolderId === undefined || loadingAllDocument}
                onClick={() => handleDownloadAllDocments(selectedFolderId)}
              >
                {t("research.button.download_add_documents")}
                {loadingAllDocument && <InLineLoader size={8} />}
              </Button>
              <Button
                disabled={selectedFolderId === undefined || loadingListPdf}
                onClick={() => handleDownloadListPdf(selectedFolderId)}
              >
                {t("research.button.download_list_as_pdf")}
                {loadingListPdf && <InLineLoader size={8} />}
              </Button>
            </div>
            <div className="flex items-center">
              <Button
                onClick={() => setCitationType("bluebook")}
                variant="link"
                className={citationType === "bluebook" ? "underline" : ""}
              >
                {t("research.button.bluebook")}
              </Button>
              |
              <Button
                onClick={() => setCitationType("apa")}
                variant="link"
                className={citationType === "apa" ? "underline" : ""}
              >
                {t("research.button.apa")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col py-4">
            {[0, -1].includes(selectedFolderId) ? (
              <RenderDefaultDocumentsList
                researchFolderId={selectedFolderId}
                citationType={citationType}
              />
            ) : (
              <RenderDocumentsList
                researchFolderId={selectedFolderId}
                citationType={citationType}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResearchFolders;
