import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";
import { Button } from "../ui/button";

interface DescriptionProps {
  text: string;
  className?: string;
}
const LongDescription: React.FC<DescriptionProps> = ({ text, className }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to trim text at word boundary
  const trimTextAtWordBoundary = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    const trimmed = text.slice(0, maxLength);
    return trimmed.slice(0, trimmed.lastIndexOf(" ")) + " ...";
  };

  const trimmedText = trimTextAtWordBoundary(text, 200);

  return (
    <div className={cn("text-justify", className)}>
      <p>
        {isExpanded ? text : trimmedText}
        {text.length > 200 && (
          <>
            {" "}
            <Button
              onClick={toggleReadMore}
              className="p-0 m-0 h-auto"
              variant="link"
            >
              {isExpanded
                ? t("components.button.show_less")
                : t("components.button.show_more")}
            </Button>
          </>
        )}
      </p>
    </div>
  );
};
export default LongDescription;
