import React from "react";
import { DOTS, usePagination } from "@/hooks/usePagination";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IoIosArrowDown } from "react-icons/io";

export const CustomPagination = ({
  onPageChange,
  totalCount = 1,
  siblingCount = 1,
  currentPage = 1,
  pageSize,
}: {
  onPageChange: (value: number) => void;
  totalCount?: number;
  pageSize: number;
  siblingCount?: number;
  currentPage?: number;
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
    siblingCount,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Pagination>
      <PaginationContent className="flex-wrap">
        {currentPage !== 1 && (
          <PaginationItem>
            <PaginationPrevious onClick={onPrevious} />
          </PaginationItem>
        )}

        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <PaginationItem key={index}>
                <PaginationEllipsis />
              </PaginationItem>
            );
          }

          return (
            <PaginationItem key={index}>
              <PaginationLink
                onClick={() => onPageChange(pageNumber as number)}
                isActive={pageNumber === currentPage}
              >
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        {currentPage !== lastPage && (
          <PaginationItem>
            <PaginationNext onClick={onNext} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
};

export const CustomPageSelector = ({
  options = [10, 25, 30, 50],
  onPageSizeChange,
  pageSize,
}: {
  options?: number[];
  onPageSizeChange: (value: number) => void;
  pageSize: number;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="my-2 border p-2 flex items-center justify-center gap-4 mt-4">
        <span>{pageSize}</span> <IoIosArrowDown />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {options.map((item) => (
          <DropdownMenuItem key={item} onClick={() => onPageSizeChange(item)}>
            {item}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
