import { BaseApi } from "@/api/index";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface Deadline {
  id: number;

  case: number;
  name: string;
  date: string; // actual date
  notes: string;
  private: boolean;

  created_date: string;
  updated_date: string | null;
  deleted_at: string | null;
  created_by: number;
  updated_by: number | null;
  deleted_by: number | null;
}

class DeadlineApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/deadlines" });
  }
}

export const deadlinesApi = new DeadlineApi<Deadline>();
