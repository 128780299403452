import React, { useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { AlertCircle, CheckCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Input } from "@/components/ui/input";
import { caseReviewAuthApi } from "@/api/auth";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm, UseFormSetError, useWatch } from "react-hook-form";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { InLineLoader } from "@/components/ui/loader";
import ReactPasswordChecklist from "react-password-checklist";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { isLoggedin } = contextValues;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();
  const [forgetPasswordSuccess, setForgetPasswordSuccess] = useState<
    string | undefined
  >();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const minPasswordLength = 8;

  const resetPasswordFormScheme = z.object({
    email: z
      .string()
      .min(1, { message: t("common.statictext.field_required") })
      .email(t("common.statictext.invalid_email")),
  });

  const confirmPasswordScheme = z
    .object({
      new_password1: z.string().min(8, {
        message: t("auth.statictext.password_too_short"),
      }),
      new_password2: z.string().min(8, {
        message: t("auth.statictext.confirm_password_too_short"),
      }),
      token: z.string().optional(),
      uid: z.string().optional(),
    })
    .refine((data) => data.new_password1 === data.new_password2, {
      message: t("auth.statictext.passwords_dont_match"),
      path: ["new_password2"],
    });

  const form = useForm<z.infer<typeof resetPasswordFormScheme>>({
    resolver: zodResolver(resetPasswordFormScheme),
    defaultValues: {
      email: "",
    },
  });

  const confirmPasswordForm = useForm<z.infer<typeof confirmPasswordScheme>>({
    resolver: zodResolver(confirmPasswordScheme),
    defaultValues: {
      new_password1: "",
      new_password2: "",
    },
  });

  const passwordResetErrorHandler = useApiErrorHandler("ForgotPassword", {
    setFormError: confirmPasswordForm.setError as UseFormSetError<
      Record<string, unknown>
    >,
  });

  const new_password1 = useWatch({
    name: "new_password1",
    control: confirmPasswordForm.control,
  });
  const new_password2 = useWatch({
    name: "new_password2",
    control: confirmPasswordForm.control,
  });

  function onResetPasswordEmailSubmit(
    values: z.infer<typeof resetPasswordFormScheme>,
  ) {
    setLoading(true);
    caseReviewAuthApi
      .sendResetPasswordLink({ email: values.email })
      .then((response) => {
        setError(undefined);
        setForgetPasswordSuccess(response.data.detail);
      })
      .catch((errorMessage) => {
        console.error(errorMessage);
        setForgetPasswordSuccess(undefined);
        setError(t("auth.statictext.email_not_found"));
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      });
  }

  function onResetPasswordSubmit(
    values: z.infer<typeof confirmPasswordScheme>,
  ) {
    if (!!uid && !!token) {
      setLoading(true);
      caseReviewAuthApi
        .resetPasswordConfirm({
          new_password1: values.new_password1,
          new_password2: values.new_password2,
          uid: uid,
          token: token,
        })
        .then((response) => {
          setError(undefined);
          setSuccess(response.data.detail);
          toast({
            title: t("auth.toast.changed_successfully"),
            action: (
              <ToastAction
                onClick={() => navigate("/login")}
                altText={t("auth.button.login")}
              >
                {t("auth.button.login")}
              </ToastAction>
            ),
          });
        })
        .catch((errorMessage) => {
          console.error(errorMessage);
          setSuccess(undefined);
          passwordResetErrorHandler(errorMessage);
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setError(undefined);
          }, 3000);
        });
    } else {
      navigate("/login");
    }
  }

  if (isLoggedin) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center gap-8">
      {!!confirmPasswordForm.formState.errors.token ||
        (!!confirmPasswordForm.formState.errors.uid && (
          <Alert variant="destructive" className="w-[350px]">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>{t("auth.title.link_expired")}</AlertTitle>
            <AlertDescription>
              {t("auth.statictext.link_expired")}
            </AlertDescription>
          </Alert>
        ))}
      {!!error && (
        <Alert variant="destructive" className="w-[350px]">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{t("common.title.error")}</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {!!success && (
        <Card className="w-[350px] ">
          <div className="flex items-center justify-center">
            <div className=" p-6 text-center items-center">
              <div className="flex items-center justify-center mb-4">
                <CheckCircle className="h-10 w-10" />
              </div>
              <AlertTitle>{t("auth.title.password_changed")}</AlertTitle>
              <AlertDescription>
                {t("auth.toast.changed_successfully")}
              </AlertDescription>
              <Button className="mt-6" onClick={() => navigate("/login")}>
                {t("auth.button.login")}
              </Button>
            </div>
          </div>
        </Card>
      )}
      {!!forgetPasswordSuccess && (
        <Card className="w-[350px] ">
          <div className="flex items-center justify-center">
            <div className=" p-6 text-center items-center">
              <div className="flex items-center justify-center mb-4">
                <CheckCircle className="h-10 w-10" />
              </div>
              <AlertTitle>{t("auth.title.reset_link_sent")}</AlertTitle>
              <AlertDescription>
                {t("auth.statictext.check_email")}
              </AlertDescription>
              <Button className="mt-6" onClick={() => navigate("/login")}>
                {t("auth.button.go_to_login")}
              </Button>
            </div>
          </div>
        </Card>
      )}
      {!!uid && !!token ? (
        <Form {...confirmPasswordForm}>
          <form
            onSubmit={confirmPasswordForm.handleSubmit(onResetPasswordSubmit)}
            className="space-y-8"
          >
            <Card className="w-[350px]">
              <CardHeader>
                <CardTitle>{t("auth.title.update_your_password")}</CardTitle>
                <CardDescription>
                  {t("auth.statictext.enter_and_confirm")}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid w-full items-center gap-4 mb-4">
                  <div className="flex flex-col space-y-4">
                    <FormField
                      control={confirmPasswordForm.control}
                      name="new_password1"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("auth.label.new_password")}</FormLabel>
                          <FormControl>
                            <Input
                              type="password"
                              placeholder={t("auth.placeholder.new_password")}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={confirmPasswordForm.control}
                      name="new_password2"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("auth.label.confirm_new")}</FormLabel>
                          <FormControl>
                            <Input
                              type="password"
                              placeholder={t("auth.placeholder.confirm_new")}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <ReactPasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "capital",
                    "number",
                    "match",
                  ]}
                  minLength={minPasswordLength}
                  value={new_password1 || ""}
                  valueAgain={new_password2 || ""}
                  onChange={(isValid) => {
                    if (isValid) {
                      setPasswordValid(true);
                    } else {
                      setPasswordValid(false);
                    }
                  }}
                  messages={{
                    minLength: t("auth.validation.min_length", {
                      length: minPasswordLength,
                    }),
                    specialChar: t("auth.validation.special_char"),
                    capital: t("auth.validation.capital"),
                    number: t("auth.validation.number"),
                    match: t("auth.validation.match"),
                  }}
                />
              </CardContent>
              <CardFooter className="flex justify-between">
                <Button type="submit" disabled={!passwordValid}>
                  {loading ? (
                    <InLineLoader size={8} />
                  ) : (
                    t("auth.button.change_password")
                  )}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </Form>
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onResetPasswordEmailSubmit)}
            className="space-y-8"
          >
            <Card className="w-[350px]">
              <CardHeader>
                <CardTitle>{t("auth.title.forgot_password")}</CardTitle>
                <CardDescription>
                  {t("auth.statictext.send_reset_password_email")}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid w-full items-center gap-4">
                  <div className="flex flex-col space-y-4">
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("auth.label.your_email")}:</FormLabel>
                          <FormControl>
                            <Input
                              placeholder={t("auth.placeholder.your_email")}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </CardContent>
              <CardFooter className="flex justify-between">
                <Button onClick={() => navigate("/login")} variant="ghost">
                  {t("auth.button.login")}
                </Button>
                <Button type="submit">
                  {loading ? (
                    <InLineLoader size={8} />
                  ) : (
                    t("auth.button.send_reset_password_link")
                  )}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </Form>
      )}
    </div>
  );
}

export default ForgotPassword;
