import React from "react";
import { Loader2 } from "lucide-react";

export default function Loader({ size = 8 }: { size?: number }) {
  return (
    <div className="w-full text-center flex items-center justify-center my-4">
      <Loader2 className={`h-${size} w-${size} animate-spin`} />
    </div>
  );
}

export function InLineLoader({ size = 8 }: { size?: number }) {
  return <Loader2 className={`h-${size} w-${size} animate-spin mx-2`} />;
}
