import { useState, useEffect, useCallback, useContext } from "react";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { BaseApi } from "@/api";

export const useReadApi = <T>(baseApiObject: BaseApi<T>, id?: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiResult, setApiResult] = useState<T | undefined>(undefined);
  const { values } = useContext<AuthContextType>(AuthContext);
  const errorHandler = useApiErrorHandler("useReadApi", {
    callback403: () => {}, // surpress toast messages.
  });

  const { user, isLoggedin } = values;

  const refresh = useCallback(async () => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await baseApiObject.read({
          token: user?.access,
          id,
        });
        setApiResult(data);
      } catch (error) {
        setApiResult(undefined);
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [baseApiObject, errorHandler, user?.access, id]);

  useEffect(() => {
    if (isLoggedin) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedin, id]);

  return {
    loading,
    apiResult,
    refresh,
  };
};
