import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import React, { useContext } from "react";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { Link } from "react-router-dom";
import { useAdminPermission, usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

interface NavigationLink {
  label: string;
  url: string;
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
  // eslint-disable-next-line react/prop-types
>(({ className, title, href }, ref) => {
  return (
    <Link
      ref={ref}
      className={cn(
        "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
        className,
      )}
      to={href || ""}
    >
      <div className="text-sm font-medium leading-none">{title}</div>
    </Link>
  );
});
ListItem.displayName = "ListItem";

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;

  const authenticated_urls: NavigationLink[] = [
    { label: t("main_nav.statictext.home"), url: "/home" },
    { label: t("main_nav.statictext.search"), url: "/search" },
  ];
  const hasAdminPermission = useAdminPermission();
  const hasResearchPermission = usePermission("research.add_research");

  const list_views: NavigationLink[] = [
    { label: t("common.label.cases"), url: "/home" },
    { label: t("main_nav.statictext.archived_cases"), url: "/archived-cases" },
    { label: t("main_nav.statictext.sources"), url: "/sources" },
    { label: t("main_nav.statictext.timer"), url: "/timer" },

    ...(hasAdminPermission
      ? [
          {
            label: t("main_nav.statictext.contacts"),
            url: "/attorney-contacts",
          },
          { label: t("main_nav.statictext.users"), url: "/users" },
          { label: t("main_nav.statictext.deadlines"), url: "/deadlines" },
        ]
      : []),
    ...(hasResearchPermission
      ? [
          {
            label: t("main_nav.statictext.research_documents"),
            url: "/research-folders",
          },
        ]
      : []),

    { label: t("main_nav.statictext.timelines"), url: "/timelines" },
  ];

  const unauthenticated_urls = [
    { label: t("main_nav.statictext.home"), url: "/" },
    { label: t("main_nav.statictext.login"), url: "/login" },
  ];

  const user_urls = user ? authenticated_urls : unauthenticated_urls;

  return (
    <nav className={cn("flex items-center", className)} {...props}>
      <NavigationMenu>
        <NavigationMenuList>
          {user_urls.map((item) => {
            return (
              <NavigationMenuItem key={item.url}>
                <Link to={item.url} className={navigationMenuTriggerStyle()}>
                  {item.label}
                </Link>
              </NavigationMenuItem>
            );
          })}

          {!!user && (
            <NavigationMenuItem>
              <NavigationMenuTrigger>
                {t("main_nav.statictext.manage")}
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="flex flex-col min-w-[200px]">
                  {list_views.map((component) => (
                    <ListItem
                      key={component.url}
                      title={component.label}
                      href={component.url}
                    />
                  ))}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          )}
        </NavigationMenuList>
      </NavigationMenu>
    </nav>
  );
}
