import React from "react";

const JsonDisplay = ({ data }: { data: NonNullable<unknown> }) => {
  return (
    <div>
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          <>
            <strong className="capitalize">{key.replaceAll("_", " ")}:</strong>{" "}
            {value}
          </>
        </div>
      ))}
    </div>
  );
};

export default JsonDisplay;
