import { BaseApi } from "@/api/index";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface SearchSource {
  text: string;
  title: string;
  institution?: string | null;
  description?: string | null;
  case?: number;
  source: number;
  extracted_text: string | null;
}

export interface SearchResearchDocument {
  text: string;
  title: string;
  journal_name?: string | null;
  volume?: string | null;
  supplement_or_issue?: string | null;
  extracted_text: string | null;
}

export interface SearchParams {
  q?: string;
  facets?: string; // Example: facets=case:10,source:36
  limit?: number;
  offset?: number;
}

class SearchResearchDocumentApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "search/research_documents" });
  }
}

class SearchSourceApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "search/sources" });
  }
}

export const searchResearchDocumentsApi =
  new SearchResearchDocumentApi<SearchResearchDocument>();
export const searchSourcesApi = new SearchSourceApi<SearchSource>();
