import React, { useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError, useWatch } from "react-hook-form";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { User, userApi } from "@/api/users";
import PasswordChecklist from "react-password-checklist";
import { useTranslation } from "react-i18next";

export function SetPassword({
  userObject,
  trigger,
  refresh,
}: {
  userObject: User;
  trigger: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();

  const FormSchema = z
    .object({
      new_password: z.string().min(8, {
        message: t("auth.statictext.password_too_short"),
      }),
      confirm_new_password: z.string().min(8, {
        message: t("auth.statictext.confirm_password_too_short"),
      }),
    })
    .superRefine(({ confirm_new_password, new_password }, ctx) => {
      if (confirm_new_password !== new_password) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["confirm_new_password"],
          fatal: true,
          message: t("auth.statictext.passwords_dont_match"),
        });
      }
    });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;

  const { user } = contextValues;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const errorHandler = useApiErrorHandler("SetPassword", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  const new_password = useWatch({
    name: "new_password",
    control: form.control,
  });
  const confirm_new_password = useWatch({
    name: "confirm_new_password",
    control: form.control,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading(true);
      await userApi.update({
        id: userObject.id.toString() + "/",
        data: {
          password: data.new_password,
          updated_by: user?.user.id,
        },
        token: user?.access,
      });
      toast({
        title: t("auth.toast.added_successfully"),
      });
      await refresh?.();
      setOpen(false);
      form.reset();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {t("auth.title.update_password_for")} {userObject.username}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="new_password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("auth.label.new_password")}</FormLabel>
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirm_new_password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("auth.label.confirm_new")}</FormLabel>
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <PasswordChecklist
              rules={["minLength", "specialChar", "capital", "number", "match"]}
              minLength={8}
              value={new_password || ""}
              valueAgain={confirm_new_password || ""}
              onChange={(isValid) => {
                if (isValid) {
                  setPasswordValid(true);
                } else {
                  setPasswordValid(false);
                }
              }}
            />
            <DialogFooter>
              <Button disabled={loading || !passwordValid} type="submit">
                {loading
                  ? t("common.button.loading")
                  : t("auth.button.change_password")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
