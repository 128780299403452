import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { cn, zodKeys } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { useListApi } from "@/hooks/useListApi";
import { CaseTimer, caseTimerApi } from "@/api/caseTimer";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { Case, casesApi } from "@/api/cases";
import { TimePicker } from "../TimePicker";
import { useTranslation } from "react-i18next";

const FormSchema = z.object({
  case_id: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  start_time: z.date().nullable().optional(),
  end_time: z.date().nullable().optional(),
});

export function AddUpdateCaseTimer({
  open,
  setOpen,
  caseTimer,
  trigger,
  refresh,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseTimer?: CaseTimer;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { loading, user } = contextValues;
  const { setLoading } = set;

  const {
    loading: caseLoading,
    search: caseSearch,
    loadNext: loadNextCases,
    list: casesList,
    apiResult: casesResult,
    pagination,
  } = useListApi<Case>({
    baseApiObject: casesApi,
    defaultPageSize: 10,
    enable: open,
    additionalListIds: [caseTimer?.case?.id.toString()],
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      case_id: null,
      description: "",
      start_time: new Date(),
      end_time: new Date(),
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdateCaseTimer", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      if (caseTimer && caseTimer.id) {
        await caseTimerApi
          .update({
            id: caseTimer.id.toString() + "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              user_id: user?.user.id,
              case_id: data.case_id ? parseInt(data.case_id) : undefined,
              description: data.description,
              start_time: data.start_time?.toISOString(),
              end_time: data.end_time?.toISOString(),
              updated_by: user?.user.id,
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.updated_successfully", {
                resource: t("casetimer.title.timer"),
              }),
            });
          });
      } else {
        await caseTimerApi
          .create({
            url: "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              user_id: user?.user.id,
              case_id: data.case_id ? parseInt(data.case_id) : undefined,
              description: data.description,
              start_time: data.start_time?.toISOString(),
              end_time: data.end_time?.toISOString(),
              created_by: user?.user.id,
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.added_successfully", {
                resource: t("casetimer.title.timer"),
              }),
            });
            form.reset();
          });
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (caseTimer) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...caseTimer,
            start_time: caseTimer.start_time
              ? new Date(caseTimer.start_time)
              : caseTimer.start_time,
            end_time: caseTimer.end_time
              ? new Date(caseTimer.end_time)
              : caseTimer.end_time,
            case_id: caseTimer.case ? caseTimer.case.id.toString() : undefined,
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseTimer]);

  const buttonAction = caseTimer?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("casetimer.title.action_case_timer_suffix")}
              </DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.description")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("common.placeholder.description")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="start_time"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-left">
                    {t("casetimer.label.start_time")}
                  </FormLabel>
                  <Popover>
                    <FormControl>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-[280px] justify-start text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value ? (
                            format(field.value, "PPP HH:mm:ss")
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                    </FormControl>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={field.value || undefined}
                        onSelect={field.onChange}
                        initialFocus
                      />
                      <div className="p-3 border-t border-border">
                        <TimePicker
                          setDate={field.onChange}
                          date={field.value || undefined}
                        />
                      </div>
                    </PopoverContent>
                  </Popover>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="end_time"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-left">
                    {t("casetimer.label.end_time")}
                  </FormLabel>
                  <Popover>
                    <FormControl>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-[280px] justify-start text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value ? (
                            format(field.value, "PPP HH:mm:ss")
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                    </FormControl>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={field.value || undefined}
                        onSelect={field.onChange}
                        initialFocus
                      />
                      <div className="p-3 border-t border-border">
                        <TimePicker
                          setDate={field.onChange}
                          date={field.value || undefined}
                        />
                      </div>
                    </PopoverContent>
                  </Popover>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="case_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("common.label.case")}{" "}
                    {caseLoading ? t("common.button.loading_with_parens") : ""}
                  </FormLabel>
                  <DynamicSelectorV2
                    onValueChange={field.onChange}
                    value={field.value || undefined}
                    options={casesList.map((item) => ({
                      label: item.name,
                      value: item.id.toString(),
                    }))}
                    className="selector"
                    onLoadNext={loadNextCases}
                    onSearch={(searchValue) => {
                      caseSearch.set(searchValue);
                    }}
                    placeholder={t("common.placeholder.case_select")}
                    totalCount={casesResult?.count || 0}
                    pageSize={pagination.pageSize}
                    currentPage={pagination.page}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
