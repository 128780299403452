import { BaseApi } from "@/api/index";
import { Case } from "./cases";
import { User } from "./users";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface AssociatedPleading {
  id: number;
  title: string;
}

export interface Pleading {
  id: number;

  case: Case;
  user: User;
  associated_pleading: AssociatedPleading | null;

  // Only use for creating.
  case_id?: number;
  user_id?: number;
  associated_pleading_id?: number;

  title: string;
  party: string;
  description: string;
  type: string;
  filing_date: string;
  response_necessary: boolean;
  due_date?: string | null;
  file: File | string | null | undefined;

  created_date: string;
  updated_date: string | null;
  deleted_at: string | null;
  created_by: number;
  updated_by: number | null;
  deleted_by: number | null;
}

class PleadingApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/pleadings" });
  }
}

export const pleadingApi = new PleadingApi<Pleading>();
