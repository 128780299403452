import { useContext } from "react";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { UserPermissions } from "@/api/auth";

export const usePermission = (permissionName: keyof UserPermissions) => {
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { userPermission, isAdmin } = contextValues;

  if (isAdmin === true) {
    return true;
  } else {
    return userPermission?.[permissionName] === true;
  }
};

export const useAdminPermission = () => {
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { isAdmin } = contextValues;
  return isAdmin === true;
};

export const useEditorPermission = () => {
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { isEditor, isAdmin } = contextValues;
  if (isAdmin === true) {
    return true;
  }
  return isEditor === true;
};

// export const useReviewerPermission = () => {
//   const { values: contextValues } = useContext(AuthContext) as AuthContextType;
//   const { isReviewer } = contextValues;
//   return isReviewer === true;
// };

export const useIsCreatorOrAdmin = <T extends { created_by: number | null }>(
  resourceObject?: T,
) => {
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { isAdmin, user } = contextValues;
  if (isAdmin === true) {
    return true;
  }
  if (resourceObject !== undefined) {
    return (
      typeof resourceObject.created_by === "number" &&
      resourceObject.created_by === user?.user.id
    );
  }
  return false;
};
