import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import React, { ReactNode } from "react";

interface InfoMapping {
  data: {
    label: string;
    value?: string | ReactNode;
  }[];
}

export function ResourceInfoModal({
  open,
  setOpen,
  title,
  mappings,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  mappings: InfoMapping;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <ul>
            {mappings.data.map((item, index) => {
              return (
                <li key={index} className="mb-2">
                  <b>{item.label}</b>
                  <p>{item.value}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
}
