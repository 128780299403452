import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Case, CaseCivil, CaseCriminal } from "@/api/cases";
import { ColumnDef } from "@tanstack/react-table";
import {
  BsArrowDownCircleFill,
  BsFillPencilFill,
  BsFillPersonFill,
  BsFillQuestionCircleFill,
  BsPlus,
  BsTrashFill,
  BsX,
} from "react-icons/bs";
import { DeleteReviewCase } from "./DeleteReviewCase";
import { reviewApi } from "@/api/review";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { useNavigate } from "react-router-dom";
import { User } from "@/api/users";
import { Button } from "@/components/ui/button";
import { useAdminPermission } from "@/hooks/usePermission";
import { CustomToolTip } from "../CustomToolTip";
import { renderDateForTable } from "@/lib/utils";
import { toast } from "@/components/ui/use-toast";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

export const useCaseColumn = ({
  refresh,
  editCriminal,
  editCivil,
  editPermissions,
  handleRequestInfo,
}: {
  refresh: () => Promise<void>;
  editCriminal: (item: CaseCriminal) => void;
  editCivil: (item: CaseCivil) => void;
  editPermissions?: (item: Case) => void;
  handleRequestInfo: (item: Case) => void;
}) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();
  const hasAdminPermission = useAdminPermission();

  const { user } = contextValues;
  const errorHandler = useApiErrorHandler("useCaseColumn", {});

  const handleDownloadResouces = async (id: number, name: string) => {
    try {
      const response = await reviewApi.downloadReviewCase({
        id,
        token: user?.access,
      });
      FileDownload(response.data, `review_case_${name}.pdf`);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns: ColumnDef<Case>[] = [
    {
      accessorKey: "name",
      header: () => <div className="text-left">{t("common.label.name")}</div>,
      cell: ({ row }) => {
        const reviewCase = row.original;

        const value = (row.getValue("name") as string) || "--";
        return (
          <div
            className="text-left cursor-pointer hover:underline hover:text-primary"
            onClick={() => navigate(`/cases/${reviewCase.id}`)}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessorKey: "court",
      header: () => <div className="text-left">{t("common.label.court")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("court") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "exact_trial_date",
      header: () => (
        <div className="text-left">{t("cases.label.exact_trial_date")}</div>
      ),
      cell: ({ row }) => {
        const value = renderDateForTable(
          row.getValue("exact_trial_date") || "--",
        );
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "inexact_trial_date",
      header: () => (
        <div className="text-left">{t("cases.label.inexact_trial_date")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("inexact_trial_date") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const reviewCase = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasAdminPermission && (
              <>
                {reviewCase.is_civil ? (
                  <BsFillPencilFill
                    className="hover:text-primary cursor-pointer"
                    onClick={() => editCivil(reviewCase)}
                  />
                ) : (
                  <BsFillPencilFill
                    className="hover:text-primary cursor-pointer"
                    onClick={() => editCriminal(reviewCase)}
                  />
                )}
                {editPermissions && (
                  <CustomToolTip
                    tip={t("cases.statictext.edit_user_permissions")}
                    trigger={
                      <BsFillPersonFill
                        className="hover:text-primary cursor-pointer"
                        onClick={() => editPermissions(reviewCase)}
                      />
                    }
                  />
                )}
                <CustomToolTip
                  tip={t("common.button.download_as_pdf")}
                  trigger={
                    <BsArrowDownCircleFill
                      className="hover:text-primary cursor-pointer"
                      onClick={() =>
                        handleDownloadResouces(reviewCase.id, reviewCase.name)
                      }
                    />
                  }
                />

                <DeleteReviewCase
                  trigger={
                    <BsTrashFill className="hover:text-primary cursor-pointer" />
                  }
                  refresh={refresh}
                  caseItem={reviewCase}
                />
              </>
            )}
            <CustomToolTip
              tip={t("cases.statictext.request_info")}
              trigger={
                <BsFillQuestionCircleFill
                  className="hover:text-primary cursor-pointer"
                  onClick={() => handleRequestInfo(reviewCase)}
                />
              }
            />
          </div>
        );
      },
    },
  ];

  return columns;
};

export const useCasePermissionsColumn = (
  allowed_users: number[],
  caseId?: number,
  refresh?: () => Promise<void>,
) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user: globalUser } = contextValues;

  const errorHandler = useApiErrorHandler("useCasePermissionsColumn", {});

  async function updateCasePermission(
    userId: number,
    operation: "add" | "remove",
  ) {
    try {
      if (caseId) {
        toast({
          title: t("common.toast.updating"),
        });
        await reviewApi.updatePermissions({
          id: caseId,
          token: globalUser?.access,
          data: {
            user_id: userId,
            operation: operation,
            model: "case",
            permission: "access_case",
          },
        });
        await refresh?.();
        toast({
          title: t("common.toast.permissions_updated"),
        });
      }
    } catch (error) {
      errorHandler(error);
      toast({
        title: t("common.toast.permissions_update_failed"),
      });
    }
  }

  const columns: ColumnDef<User>[] = [
    {
      accessorKey: "username",
      header: () => (
        <div className="text-left">{t("common.label.username")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("username") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "first_name",
      header: () => (
        <div className="text-left">{t("common.label.full_name")}</div>
      ),
      cell: ({ row }) => {
        const user = row.original;
        const value = (row.getValue("first_name") as string) || "--";
        return (
          <div className="text-left">
            {value} {user.last_name}
          </div>
        );
      },
    },
    {
      accessorKey: "email",
      header: () => <div className="text-left">{t("common.label.email")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("email") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "is_active",
      header: () => (
        <div className="text-left">{t("cases.label.access_type")}</div>
      ),
      cell: ({ row }) => {
        const user = row.original;
        return (
          <div className="text-left">
            {allowed_users.includes(user.id) ? (
              <Button
                variant="link"
                className="p-0 m-0 text-green-600"
                onClick={() => updateCasePermission(user.id, "remove")}
              >
                <BsX className="text-3xl" /> {t("common.values.allowed")}
              </Button>
            ) : (
              <Button
                variant="link"
                className="p-0 m-0 text-red-600"
                onClick={() => updateCasePermission(user.id, "add")}
              >
                <BsPlus className="text-3xl" /> {t("common.values.not_allowed")}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};

export const useArchivedCaseColumn = ({
  refresh,

  editCriminal,
  editCivil,
  editPermissions,
  handleRequestInfo,
  setOrder,
}: {
  refresh: () => Promise<void>;
  editCriminal: (item: CaseCriminal) => void;
  editCivil: (item: CaseCivil) => void;
  editPermissions?: (item: Case) => void;
  handleRequestInfo: (item: Case) => void;
  setOrder: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();
  const hasAdminPermission = useAdminPermission();

  const { user } = contextValues;
  const errorHandler = useApiErrorHandler("useCaseColumn", {});

  const handleDownloadResouces = async (id: number, name: string) => {
    try {
      const response = await reviewApi.downloadReviewCase({
        id,
        token: user?.access,
      });
      FileDownload(response.data, `review_case_${name}.pdf`);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "name";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<Case>[] = [
    {
      accessorKey: "name",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("name");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.name")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "name"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "name"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const reviewCase = row.original;

        const value = (row.getValue("name") as string) || "--";
        return (
          <div
            className="text-left cursor-pointer hover:underline hover:text-primary"
            onClick={() => navigate(`/cases/${reviewCase.id}`)}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessorKey: "court",
      header: () => <div className="text-left">{t("common.label.court")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("court") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "exact_trial_date",
      header: () => (
        <div className="text-left">{t("cases.label.exact_trial_date")}</div>
      ),
      cell: ({ row }) => {
        const value = renderDateForTable(
          row.getValue("exact_trial_date") || "--",
        );
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "inexact_trial_date",
      header: () => (
        <div className="text-left">{t("cases.label.inexact_trial_date")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("inexact_trial_date") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const reviewCase = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasAdminPermission && (
              <>
                {reviewCase.is_civil ? (
                  <BsFillPencilFill
                    className="hover:text-primary cursor-pointer"
                    onClick={() => editCivil(reviewCase)}
                  />
                ) : (
                  <BsFillPencilFill
                    className="hover:text-primary cursor-pointer"
                    onClick={() => editCriminal(reviewCase)}
                  />
                )}
                {editPermissions && (
                  <CustomToolTip
                    tip={t("cases.statictext.edit_user_permissions")}
                    trigger={
                      <BsFillPersonFill
                        className="hover:text-primary cursor-pointer"
                        onClick={() => editPermissions(reviewCase)}
                      />
                    }
                  />
                )}
                <CustomToolTip
                  tip={t("common.button.download_as_pdf")}
                  trigger={
                    <BsArrowDownCircleFill
                      className="hover:text-primary cursor-pointer"
                      onClick={() =>
                        handleDownloadResouces(reviewCase.id, reviewCase.name)
                      }
                    />
                  }
                />

                <DeleteReviewCase
                  trigger={
                    <BsTrashFill className="hover:text-primary cursor-pointer" />
                  }
                  refresh={refresh}
                  caseItem={reviewCase}
                />
              </>
            )}
            <CustomToolTip
              tip={t("cases.statictext.request_info")}
              trigger={
                <BsFillQuestionCircleFill
                  className="hover:text-primary cursor-pointer"
                  onClick={() => handleRequestInfo(reviewCase)}
                />
              }
            />
          </div>
        );
      },
    },
  ];

  return columns;
};
