import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Source } from "@/api/source";
import { useCaseSourceDeleteApi } from "./hooks";
import { useTranslation } from "react-i18next";

export function DeleteCaseSource({
  trigger,
  caseSource,
  refresh,
}: {
  caseSource: Source;
  trigger: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { loading, deleteItem } = useCaseSourceDeleteApi({ refresh });

  async function onSubmit() {
    deleteItem(caseSource.id.toString()).finally(() => {
      setOpen(false);
    });
  }

  function onCancel() {
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("sources.title.delete")}</DialogTitle>
          <DialogDescription>
            {t("common.statictext.confirm_delete", {
              name: caseSource.title + " " + caseSource.institution,
            })}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant="secondary" disabled={loading} onClick={onCancel}>
            {loading ? t("common.button.loading") : t("common.button.cancel")}
          </Button>
          <Button variant="destructive" disabled={loading} onClick={onSubmit}>
            {loading ? t("common.button.loading") : t("common.button.confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
