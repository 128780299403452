import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { DeleteEvent } from "./DeleteEvent";
import { CaseEvent } from "@/api/events";
import { usePermission } from "@/hooks/usePermission";
import { invertColor } from "@/lib/colors";
import { renderDateForTable } from "@/lib/utils";
import { convert } from "html-to-text";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import LongDescription from "../LongDescription";
import { useTranslation } from "react-i18next";

export const useEventColumn = ({
  refresh,
  edit,
  info,
  fileInfo,
  setOrder,
}: {
  refresh: () => Promise<void>;
  edit: (item: CaseEvent) => void;
  fileInfo: (item: CaseEvent) => void;
  info: (item: CaseEvent) => void;
  setOrder: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const hasEditPermission = usePermission("review.change_event");
  const hasDeletePermission = usePermission("review.delete_event");

  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<CaseEvent>[] = [
    {
      accessorKey: "title",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("title");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("events.label.title")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "title"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "title"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const caseEventItem = row.original;
        const value = (row.getValue("title") as string) || "--";
        return (
          <div
            className={`text-left text-[${invertColor(caseEventItem.background_color as string, true)}] underline cursor-pointer`}
            onClick={() => info(caseEventItem)}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("date");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.date")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "date"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "date"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = renderDateForTable(row.getValue("date") || "--");
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "description",
      header: () => (
        <div className="text-left">{t("common.label.description")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("description") as string) || "--";
        return (
          <LongDescription
            text={convert(value)}
            className="text-left max-w-[400px]"
          />
        );
      },
    },
    {
      accessorKey: "author",
      header: () => <div className="text-left">{t("common.label.author")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("author") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "tags",
      header: () => (
        <div className="text-left">{t("events.label.case_tags")}</div>
      ),
      cell: ({ row }) => {
        const caseEventItem = row.original;
        return (
          <div className="text-left flex gap-2 flex-wrap">
            {caseEventItem.tags.map(({ tag, name }) => (
              // eslint-disable-next-line react/jsx-key
              <img
                alt={name || tag.display_name}
                src={`${process.env.REACT_APP_BACKEND_URL}${tag.icon}`}
                className="w-[24px] h-[24px] border border-white rounded-full"
              />
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "starting_page",
      header: () => (
        <div className="text-left">
          {t("events.label.page_and_bates_numbers")}
        </div>
      ),
      cell: ({ row }) => {
        const caseEventItem = row.original;

        const starting_page = caseEventItem.starting_page;
        const ending_page = caseEventItem.ending_page;
        return (
          <div
            className={
              caseEventItem.source?.file
                ? "text-left cursor-pointer text-primary underline"
                : "text-left "
            }
            onClick={() => {
              caseEventItem.source?.file && fileInfo(caseEventItem);
            }}
          >
            {starting_page}
            {"-"}
            {ending_page}
          </div>
        );
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const caseEventItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasEditPermission && (
              <BsFillPencilFill
                className="hover:text-primary cursor-pointer"
                onClick={() => edit(caseEventItem)}
              />
            )}
            {hasDeletePermission && (
              <DeleteEvent
                caseEvent={caseEventItem}
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
