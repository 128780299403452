import React, { useState, useEffect } from "react";
import { BsArrowUp } from "react-icons/bs";
import { Button } from "../ui/button";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <Button
          onClick={scrollToTop}
          className="fixed bottom-[20px] md:bottom-[35px] right-[20px] md:right-[35px] background-primary text-white rounded-full w-[50px] h-[50px] text-[24px] z-50"
        >
          <BsArrowUp />
        </Button>
      )}
    </div>
  );
};
export default ScrollToTop;
