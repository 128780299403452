import React, { useContext } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { discoveryApi } from "@/api/discovery";
import { useTranslation } from "react-i18next";

const FormSchema = z.object({
  files: z.instanceof(FileList).nullable(),
});

export function AddDiscovery({
  open,
  setOpen,
  trigger,
  refresh,
  caseId,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
  caseId: number;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { loading, user } = contextValues;
  const { setLoading } = set;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      files: null,
    },
  });

  const errorHandler = useApiErrorHandler("AddDiscovery", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      const fileListAsArray = data.files ? Array.from(data.files) : [];
      if (fileListAsArray.length > 0) {
        await Promise.all(
          fileListAsArray.map((item: File) =>
            discoveryApi.create({
              url: "/",
              headers: {
                "content-type": "multipart/form-data",
              },
              data: {
                file: item,
                case_id: caseId,
                created_by: user?.user.id,
              },
              token: user?.access,
            }),
          ),
        );
        toast({
          title: t("common.toast.added_successfully", {
            resource: t("discovery.title.discovery"),
          }),
        });
        await refresh?.();
        setOpen(false);
        form.reset();
      } else {
        toast({
          title: t("discovery.toast.select_files"),
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  const buttonAction = t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("discovery.title.discovery")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="files"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t("discovery.label.files")}</FormLabel>
                    <FormControl>
                      <Input
                        type="file"
                        multiple
                        placeholder={t("discovery.placeholder.files")}
                        {...field}
                        value={undefined}
                        onChange={(event) =>
                          form.setValue(
                            "files",
                            event.target.files ? event.target.files : null,
                          )
                        }
                      />
                    </FormControl>
                    <FormDescription>
                      {t("common.statictext.upload_files")}
                      <p className="text-sm text-info">
                        {t("common.statictext.upload_files_detail")}
                      </p>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
