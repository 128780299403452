import { BaseApi } from "@/api/index";
import { User } from "./users";
import { Case } from "./cases";
import { AxiosResponse } from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface Communication {
  id: number;

  // to create and update
  case_id?: number;
  user_id?: number;

  user: User;
  case: Case;

  person: string;
  communication_type: string;
  start_date: string;
  duration_unit: string;
  duration_amount: number;
  public: boolean;
  notes?: string | null;

  created_date: string;
  updated_date: string | null;
  deleted_at: string | null;
  created_by: number;
  updated_by: number | null;
  deleted_by: number | null;
}

class LegacyCommunicationsApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "communications" });
  }

  downloadAllCommunicationsOfCase({
    caseId,
    token,
  }: {
    caseId: number;
    token?: string;
  }) {
    return this.request({
      method: "get",
      url: `/download/${caseId}/`,
      token,
      responseType: "arraybuffer",
    });
  }

  downloadAllCommunications({ token }: { token?: string }) {
    return this.request({
      method: "get",
      url: "/download_all/",
      token,
      responseType: "arraybuffer",
    });
  }
}
class CommunicationsApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/communications" });
  }

  totalTime({
    token,
    params,
  }: {
    token?: string;
    params: Record<string, unknown>;
  }) {
    return this.request<
      unknown,
      AxiosResponse<{ hours: number; minutes: number }>
    >({
      method: "GET",
      url: "/total_time/",
      token,
      params: params,
    });
  }
}

export const legacyCommunicationsApi = new LegacyCommunicationsApi();
export const communicationsApi = new CommunicationsApi<Communication>();
