import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import {
  cn,
  convertFileUrlToFileName,
  renderDateString,
  zodKeys,
} from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { useListApi } from "@/hooks/useListApi";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { FaPaperclip } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { Note, notesApi } from "@/api/notes";
import { Source, sourcesApi } from "@/api/source";
import { CustomToolTip } from "../CustomToolTip";
import { useTranslation } from "react-i18next";

export function AddUpdateNote({
  open,
  setOpen,
  note,
  trigger,
  refresh,
  reviewCaseId,
  defaultSourceId,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  note?: Note;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
  reviewCaseId: number;
  defaultSourceId?: number;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { loading, user } = contextValues;
  const { setLoading } = set;

  const {
    loading: sourceLoading,
    search: sourceSearch,
    loadNext: loadNextSources,
    list: sourceList,
    apiResult: sourceResult,
    pagination,
  } = useListApi<Source>({
    baseApiObject: sourcesApi,
    defaultParams: { case_id: reviewCaseId },
    enable: open,
    additionalListIds: [note?.source?.id?.toString()],
  });

  const FormSchema = z.object({
    source_id: z.string().nullable().optional(),
    title: z.string().min(1, t("common.statictext.field_required")),
    author: z.string().min(1, t("common.statictext.field_required")),
    date: z.date().nullable().optional(),
    private: z.boolean().default(false),
    description: z.string().min(1, t("common.statictext.field_required")),
    starting_page: z.string().nullable().optional(),
    ending_page: z.string().nullable().optional(),
    starting_bates_number: z.string().optional(),
    ending_bates_number: z.string().optional(),
    file: z.instanceof(File).or(z.string()).nullable().optional(),
  });

  const RefinedFormSchema = FormSchema.refine(
    (data) => {
      const startPage = data.starting_page || 0;
      const endPage = data.ending_page || 0;
      return +startPage <= +endPage;
    },
    {
      message: t("common.statictext.endpage_must_be_greater_than_startpage"),
      path: ["ending_page"], // specify the field that will be invalid
    },
  ).refine(
    (data) => {
      const startBatesNumber = data.starting_bates_number || 0;
      const endBatesNumber = data.ending_bates_number || 0;
      return startBatesNumber <= endBatesNumber;
    },
    {
      message: t("common.statictext.endbates_must_be_greater_than_startbates"),
      path: ["ending_bates_number"], // specify the field that will be invalid
    },
  );

  const form = useForm<z.infer<typeof RefinedFormSchema>>({
    resolver: zodResolver(RefinedFormSchema),
    defaultValues: {
      source_id: null,
      title: "",
      author: "",
      date: null,
      private: false,
      description: "",
      starting_page: null,
      ending_page: null,
      starting_bates_number: "",
      ending_bates_number: "",
      file: null,
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdateNote", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof RefinedFormSchema>) {
    try {
      setLoading?.(true);
      if (note && note.id) {
        await notesApi
          .update({
            id: note.id.toString() + "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              case_id: reviewCaseId,
              source_id: data.source_id ? parseInt(data.source_id) : undefined,
              title: data.title,
              author: data.author,
              date: data.date?.toLocaleDateString("sv"),
              private: data.private,
              description: data.description,
              starting_page: data.starting_page
                ? parseInt(data.starting_page)
                : null,
              ending_page: data.ending_page ? parseInt(data.ending_page) : null,
              starting_bates_number: data.starting_bates_number,
              ending_bates_number: data.ending_bates_number,
              ...(typeof data.file === "string" ? {} : { file: data.file }),
              updated_by: user?.user.id,
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.updated_successfully", {
                resource: t("notes.title.note"),
              }),
            });
          });
      } else {
        await notesApi
          .create({
            url: "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              case_id: reviewCaseId,
              source_id: data.source_id ? parseInt(data.source_id) : undefined,
              title: data.title,
              author: data.author,
              date: data.date?.toLocaleDateString("sv"),
              private: data.private,
              description: data.description,
              starting_page: data.starting_page
                ? parseInt(data.starting_page)
                : null,
              ending_page: data.ending_page ? parseInt(data.ending_page) : null,
              starting_bates_number: data.starting_bates_number,
              ending_bates_number: data.ending_bates_number,
              ...(typeof data.file === "string" ? {} : { file: data.file }),
              created_by: user?.user.id,
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.added_successfully", {
                resource: t("notes.title.note"),
              }),
            });
            form.reset();
          });
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (note) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...note,
            date: note.date
              ? new Date(renderDateString(note.date, true))
              : note.date,
            source_id: note.source ? note.source.id.toString() : undefined,
            associated_note: undefined, // pop it out
            case: undefined, // pop it out
            user: undefined, // pop it out
            source: undefined, // pop it out
            case_id: reviewCaseId.toString(),
            starting_page: note.starting_page
              ? note.starting_page.toString()
              : null,
            ending_page: note.ending_page ? note.ending_page.toString() : null,
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, reviewCaseId]);

  useEffect(() => {
    if (defaultSourceId !== undefined && open) {
      form.setValue("source_id", defaultSourceId.toString(), {
        shouldTouch: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSourceId, open]);

  const buttonAction = note?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("notes.title.note")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.title")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("notes.placeholder.title")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="private"
              render={({ field }) => (
                <div>
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("common.label.private")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            <FormField
              control={form.control}
              name="author"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.author")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.author")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>* {t("common.label.date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("notes.statictext.date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.description")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("common.placeholder.description")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="source_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    * {t("common.label.source")}{" "}
                    {sourceLoading
                      ? t("common.button.loading_with_parens")
                      : ""}
                  </FormLabel>
                  <DynamicSelectorV2
                    onValueChange={field.onChange}
                    value={field.value || undefined}
                    options={sourceList.map((item) => ({
                      label: item.title,
                      value: item.id.toString(),
                    }))}
                    className="selector"
                    onLoadNext={loadNextSources}
                    onSearch={(searchValue) => {
                      sourceSearch.set(searchValue);
                    }}
                    placeholder={t("notes.placeholder.source")}
                    totalCount={sourceResult?.count || 0}
                    pageSize={pagination.pageSize}
                    currentPage={pagination.page}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="starting_page"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.start_page")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.start_page")}
                      {...field}
                      value={field.value || undefined}
                      type="number"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ending_page"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.end_page")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.end_page")}
                      {...field}
                      value={field.value || undefined}
                      type="number"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="starting_bates_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.start_bates_number")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.start_bates_number")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ending_bates_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.end_bates_number")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.end_bates_number")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => {
                let fileName = null;
                if (typeof field.value === "string") {
                  fileName = convertFileUrlToFileName(field.value);
                }
                return (
                  <FormItem>
                    <FormLabel>{t("common.label.file")}</FormLabel>
                    {fileName && (
                      <div className="flex gap-2 items-center">
                        <FaPaperclip color="skyblue" />
                        <a
                          href={field.value as string}
                          target="_blank"
                          rel="noreferrer"
                          className="underline text-primary break-all"
                        >
                          {fileName}
                        </a>
                        <CustomToolTip
                          trigger={
                            <ImCross
                              className="cursor-pointer"
                              onClick={() => {
                                form.setValue("file", null);
                              }}
                              color="red"
                            />
                          }
                          tip={t("common.statictext.remove_file")}
                        />
                      </div>
                    )}
                    <FormControl>
                      <Input
                        type="file"
                        {...field}
                        value={undefined}
                        onChange={(event) =>
                          form.setValue(
                            "file",
                            event.target.files ? event.target.files[0] : null,
                          )
                        }
                      />
                    </FormControl>
                    <FormDescription>
                      {t("common.statictext.upload_files")}
                      <p className="text-sm text-info">
                        {t("common.statictext.upload_files_detail")}
                      </p>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
