import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { DeleteCommunication } from "./DeleteCommunication";
import { Communication } from "@/api/communications";
import { usePermission } from "@/hooks/usePermission";
import { renderDateForTable } from "@/lib/utils";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import LongDescription from "../LongDescription";
import { useTranslation } from "react-i18next";

export const useCommunicationColumn = ({
  refresh,
  edit,
  setOrder,
}: {
  refresh: () => Promise<void>;
  edit: (item: Communication) => void;
  setOrder: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const hasEditPermission = usePermission("pleadings.change_pleading");
  const hasDeletePermission = usePermission("pleadings.delete_pleading");

  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);
  const columns: ColumnDef<Communication>[] = [
    {
      accessorKey: "person",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("person");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("communications.label.person")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "person"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "person"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("person") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "start_date",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("start_date");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.date")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "start_date"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "start_date"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = renderDateForTable(row.getValue("start_date") || "--");
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "duration_unit",
      header: () => (
        <div className="text-left">{t("communications.label.duration")}</div>
      ),
      cell: ({ row }) => {
        const communicationItem = row.original;
        const duration_unit = (row.getValue("duration_unit") as string) || "--";
        return (
          <div className="text-left">
            {communicationItem.duration_amount} {duration_unit}
          </div>
        );
      },
    },
    {
      accessorKey: "communication_type",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("communication_type");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.type")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "communication_type"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "communication_type"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("communication_type") as string) || "--";
        return (
          <div className="text-left capitalize">
            {value.replaceAll("_", " ")}
          </div>
        );
      },
    },
    {
      accessorKey: "notes",
      header: () => <div className="text-left">{t("common.label.notes")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("notes") as string) || "--";
        return (
          <LongDescription text={value} className="text-left max-w-[400px]" />
        );
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const communicationItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasEditPermission && (
              <BsFillPencilFill
                className="hover:text-primary cursor-pointer"
                onClick={() => edit(communicationItem)}
              />
            )}
            {hasDeletePermission && (
              <DeleteCommunication
                communication={communicationItem}
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
