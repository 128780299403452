import React from "react";
import { Timeline } from "@/api/timelines";
import { ColumnDef } from "@tanstack/react-table";
import { BsTrashFill } from "react-icons/bs";
import { DeleteTimeline } from "./DeleteTimeline";
import { CaseEvent } from "@/api/events";
import { useEditorPermission } from "@/hooks/usePermission";
import { renderDateForTable } from "@/lib/utils";
import { useTranslation } from "react-i18next";

export const useTimelineColumn = ({
  refresh,
}: {
  refresh: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const hasDeletePermission = useEditorPermission();

  const columns: ColumnDef<Timeline>[] = [
    {
      accessorKey: "title",
      header: () => <div className="text-left">{t("common.label.title")}</div>,
      cell: ({ row }) => {
        const timelineItem = row.original;
        const value = (row.getValue("title") as string) || "--";
        return (
          <div className="text-left">
            <a
              href={`/timelines/case/${
                timelineItem.case.id
              }?title=${encodeURIComponent(
                timelineItem.title,
              )}&timeline=${encodeURIComponent(
                timelineItem.id,
              )}&period=${encodeURIComponent(
                timelineItem.period,
              )}&start_date=${encodeURIComponent(
                timelineItem.start_date || "",
              )}&end_date=${encodeURIComponent(
                timelineItem.end_date || "",
              )}&selected_tags=${encodeURIComponent(
                timelineItem.selected_tags,
              )}`}
              className="text-left text-primary hover:underline flex gap-2 items-center cursor-pointer"
            >
              {value}
            </a>
          </div>
        );
      },
    },
    {
      accessorKey: "case.name",
      header: () => <div className="text-left">{t("common.label.case")}</div>,
      cell: ({ row }) => {
        const timelineItem = row.original;
        return <div className="text-left">{timelineItem.case.name}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const timelineItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasDeletePermission && (
              <DeleteTimeline
                timeline={timelineItem}
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};

export const useTimelineEventColumn = () => {
  const { t } = useTranslation();
  const columns: ColumnDef<CaseEvent>[] = [
    {
      accessorKey: "date",
      header: () => <div className="text-left">{t("common.label.date")}</div>,
      cell: ({ row }) => {
        const value = renderDateForTable(row.getValue("date") || "--");
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "title",
      header: () => <div className="text-left">{t("common.label.title")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("title") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "description",
      header: () => (
        <div className="text-left">{t("common.label.description")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("description") as string) || "--";
        return <div className="text-left max-w-[400px]">{value}</div>;
      },
    },

    {
      accessorKey: "tags",
      header: () => <div className="text-left">{t("common.label.tags")}</div>,
      cell: ({ row }) => {
        const caseEventItem = row.original;
        return (
          <div className="text-left flex gap-2 flex-wrap">
            {caseEventItem.tags.map(({ tag, name }) => (
              <img
                key={tag.id}
                alt={name || tag.display_name}
                src={`${process.env.REACT_APP_BACKEND_URL}${tag.icon}`}
                className="w-[32px] h-[32px] border border-white rounded-full"
              />
            ))}
          </div>
        );
      },
    },
  ];

  return columns;
};
