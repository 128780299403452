import { BaseApi } from "@/api/index";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { useCallback, useContext, useEffect, useState } from "react";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface ImageTag {
  id: number;
  name: string;
  slug: string;
  icon: string;
  display_name: string;
  default: boolean;
  order: number;
}

export interface CaseTag {
  id: number;
  tag: ImageTag;
  name: string;
  case: number;

  created_date: string;
  updated_date: string | null;
  deleted_at: string | null;
  created_by: number;
  updated_by: number | null;
  deleted_by: number | null;
}

class ImageTagsApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/image_tags" });
  }
}

class CaseTagsApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/case_tags" });
  }
}

export const imageTagsApi = new ImageTagsApi<ImageTag>();
export const caseTagsApi = new CaseTagsApi<CaseTag>();

export const useCaseTags = (reviewCaseId?: number | string) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<CaseTag[]>([]);
  const { values } = useContext<AuthContextType>(AuthContext);
  const errorHandler = useApiErrorHandler("useCaseTags", {});

  const { user, isLoggedin } = values;

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await caseTagsApi.list({
        token: user?.access,
        params: {
          case: reviewCaseId,
        },
      });
      setList(data as unknown as CaseTag[]);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [reviewCaseId, errorHandler, user?.access]);

  useEffect(() => {
    if (isLoggedin && reviewCaseId) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedin, reviewCaseId]);

  return {
    loading,
    list,
    refresh,
  };
};

export const useImageTags = (case_id?: string) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ImageTag[]>([]);
  const { values } = useContext<AuthContextType>(AuthContext);
  const errorHandler = useApiErrorHandler("useImageTags", {});

  const { user, isLoggedin } = values;

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await imageTagsApi.list({
        token: user?.access,
        params: {
          case: case_id,
        },
      });
      setList(data as unknown as ImageTag[]);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [errorHandler, user?.access, case_id]);

  useEffect(() => {
    if (isLoggedin) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedin]);

  return {
    loading,
    list,
    refresh,
  };
};
