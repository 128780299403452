import { BaseApi } from "@/api/index";
import { Case } from "./cases";
import { CaseEvent, caseEventApi } from "./events";
import { useCallback, useContext, useEffect, useState } from "react";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface Timeline {
  id: number;
  deleted_at?: string;
  created_date: string;
  updated_date?: string;
  title: string;
  period: "WEEK" | "DAY" | "MONTH" | "YEAR";
  start_period?: string;
  end_period?: string;
  start_date?: string;
  end_date?: string;
  selected_tags: string;
  created_by: number;
  updated_by?: number;
  deleted_by?: number;
  case: Case;
}

class TimelineApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/timelines" });
  }
}

export const timelinesApi = new TimelineApi<Timeline>();

export interface EventTimelineResult {
  column: string;
  events: CaseEvent[];
}

export interface EventTimelines {
  results: EventTimelineResult[];
  start_period: string;
  end_period: string;
  start_date: string;
  end_date: string;
}

export const useEventTimeline = (defaultParams = {}) => {
  const [loading, setLoading] = useState(false);
  const [result, setResults] = useState<EventTimelines>();
  const { values } = useContext<AuthContextType>(AuthContext);
  const errorHandler = useApiErrorHandler("useEventTimeline", {});

  const { user, isLoggedin } = values;
  const stringQueryParams = JSON.stringify(defaultParams);

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await caseEventApi.list({
        url: "/timeline/",
        token: user?.access,
        params: {
          ...defaultParams,
        },
      });
      setResults(data as unknown as EventTimelines);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [defaultParams, errorHandler, user?.access]);

  useEffect(() => {
    if (isLoggedin) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedin, stringQueryParams]);

  return {
    loading,
    result,
    refresh,
  };
};
