import React, { useContext, useEffect, useState } from "react";
import { Case, casesApi } from "@/api/cases";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useListApi } from "@/hooks/useListApi";
import { BsPlayFill, BsStopFill } from "react-icons/bs";
import { DynamicSelector } from "../DynamicSelector";
import { caseTimerApi } from "@/api/caseTimer";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import useSound from "use-sound";
import moment from "moment";
import AreYouThere from "./AreYouThere";
import { padZero } from "@/lib/utils";
import { useTranslation } from "react-i18next";

export default function TimeTrackerWidget() {
  const { t } = useTranslation();
  const {
    values: contextValues,
    startTimer,
    stopTimer,
  } = useContext(AuthContext) as AuthContextType;
  const [playBeep] = useSound("/sounds/beep.mp3");

  const { user, timer } = contextValues;
  const [myTimer, setMyTimer] = useState<{
    description?: string;
    caseId?: string;
    caseName?: string;
  }>({});

  const [open, setOpen] = useState<boolean>(false);
  const [idealAlert, setIdealAlert] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<number>(Date.now());
  const errorHandler = useApiErrorHandler("TimeTrackerWidget", {});

  const {
    loading: caseLoading,
    search: caseSearch,
    loadNext: loadNextCases,
    list: casesList,
  } = useListApi<Case>({
    baseApiObject: casesApi,
    defaultPageSize: 10,
    enable: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setEndTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  async function handleSubmit() {
    try {
      if (timer?.startTime) {
        await caseTimerApi
          .create({
            url: "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              user_id: user?.user.id,
              case_id: timer.caseId,
              description: timer.description,
              start_time: new Date(timer.startTime).toISOString(),
              end_time: new Date().toISOString(),
              created_by: user?.user.id,
            },
            token: user?.access,
          })
          .then(() => {
            stopTimer?.();
            setOpen(false);
            toast({
              title: t("casetimer.toast.time_recorded_successfully"),
            });
          })
          .catch(() => {
            stopTimer?.();
            setOpen(false);
            toast({
              title: t("casetimer.toast.enter_time_manually"),
            });
          });
      } else {
        if (myTimer?.description && myTimer?.caseId && myTimer.caseName) {
          startTimer?.(
            myTimer.description,
            myTimer.caseId.toString(),
            myTimer.caseName,
          );
          setOpen(false);
          setMyTimer({});
        } else {
          toast({
            title: t("casetimer.toast.enter_desc_select_case"),
          });
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      playBeep();
    }
  }

  const duration = moment.duration(moment(endTime).diff(timer?.startTime));

  return (
    <>
      <AreYouThere
        open={idealAlert}
        setOpen={setIdealAlert}
        stopAndSaveTimer={handleSubmit}
      />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button className="relative md:fixed flex gap-2 bg-primary bottom-0 text-white text-2xl right-0 p-4 w-full md:w-auto md:m-20 z-50">
            {timer?.startTime ? (
              <>
                <BsStopFill className="text-3xl" />
                {padZero(duration.hours())}:{padZero(duration.minutes())}:
                {padZero(duration.seconds())}
              </>
            ) : (
              <>
                <BsPlayFill className="text-3xl" /> 00:00:00
              </>
            )}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {timer?.startTime
                ? t("casetimer.button.stop_timer")
                : t("casetimer.button.start_timer")}
            </DialogTitle>
          </DialogHeader>
          {!timer?.startTime ? (
            <div className="flex flex-col gap-4 py-4 w-full">
              <div className="flex flex-col items-start gap-4 w-full">
                <Label htmlFor="name" className="text-right">
                  {t("common.label.description")}
                </Label>
                <Textarea
                  placeholder={t("common.placeholder.description")}
                  className="resize-none w-full"
                  onChange={(value) =>
                    setMyTimer((prevTimer) => ({
                      ...prevTimer,
                      description: value.target.value,
                    }))
                  }
                  value={myTimer?.description}
                />
              </div>
              <div className="flex flex-col items-start gap-4 w-full">
                <Label htmlFor="username" className="text-right">
                  {t("common.label.case")}{" "}
                  {caseLoading ? t("common.button.loading_with_parens") : ""}
                </Label>
                <DynamicSelector
                  onValueChange={(value) => {
                    setMyTimer((prevTimer) => ({
                      ...prevTimer,
                      caseId: value,
                      caseName: casesList.find(
                        (item) => item.id.toString() === value,
                      )?.name,
                    }));
                  }}
                  value={myTimer?.caseId}
                  options={casesList.map((item) => ({
                    label: item.name,
                    value: item.id.toString(),
                  }))}
                  className="selector w-full"
                  onLoadNext={loadNextCases}
                  onSearch={(searchValue) => {
                    caseSearch.set(searchValue);
                  }}
                  placeholder={t("common.placeholder.case_select")}
                />
              </div>
            </div>
          ) : (
            <div className="w-full">
              <p>
                {t("common.label.description")}: <b>{timer?.description}</b>
              </p>
              <p>
                {t("common.label.case")}:{" "}
                <b>
                  {timer?.caseName} ({t("common.label.id")}: {timer.caseId} )
                </b>
              </p>
            </div>
          )}
          <DialogFooter>
            <Button onClick={handleSubmit} type="button">
              {timer?.startTime
                ? t("casetimer.button.stop")
                : t("casetimer.button.start")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
