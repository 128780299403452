import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Case, casesApi } from "@/api/cases";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import Loader from "@/components/ui/loader";
import { Button } from "@/components/ui/button";
import { caseEventApi, CaseTimeline } from "@/api/events";
import { AxiosResponse } from "axios";
import moment from "moment";
import { ImageTag, useCaseTags } from "@/api/tags";
import { timelinesApi } from "@/api/timelines";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { cn, renderDateString } from "@/lib/utils";
import { useTimelineEventColumn } from "./hooks";
import { flatten } from "lodash";
import { DataTable } from "@/components/ui/data-table";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { Trans, useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

const DOMAIN_REGEX = /http[s]?:\/\/[\w.]+[:\d]+\//;
export enum VIEW_TYPE {
  YEAR = "YEAR",
  MONTH = "MONTH",
  DAY = "DAY",
  WEEK = "WEEK",
}

const PrintTimeline = () => {
  const { t } = useTranslation();

  const FormSchema = z.object({
    title: z.string().min(1, t("common.statictext.field_required")),
    period: z.enum([
      VIEW_TYPE.DAY,
      VIEW_TYPE.WEEK,
      VIEW_TYPE.MONTH,
      VIEW_TYPE.YEAR,
    ]),
    start_date: z.date().nullable().optional(),
    end_date: z.date().nullable().optional(),
    selected_tags: z.string().array(),
  });

  const RefinedFormSchema = FormSchema.refine(
    (data) => {
      if (data.start_date && data.end_date) {
        return (
          data.end_date?.setHours(0, 0, 0, 0) >=
          data.start_date?.setHours(0, 0, 0, 0)
        );
      }
      return true;
    },
    {
      message: t("timelines.statictext.start_date_must_be_earlier"),
      path: ["start_date"],
    },
  );

  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [reviewCase, setReviewCase] = useState<Case>();
  const [caseTimeline, setCaseTimeline] = useState<CaseTimeline>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const timelineId = searchParams.get("timeline");
  const title = searchParams.get("title");
  const period = searchParams.get("period");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const selected_tags = searchParams.get("selected_tags");

  const { loading: caseTagsLoading, list: caseImageTags } = useCaseTags(id);

  const [filters, setFilters] = useState<{
    period: number;
    viewType: VIEW_TYPE;
    startDate: string;
    endDate: string;
    selectedTags: number[];
  }>({
    period: 0,
    ...(selected_tags
      ? {
          selectedTags: selected_tags?.split(",").map((item) => parseInt(item)),
        }
      : {
          selectedTags: [],
        }),
    ...(start_date
      ? {
          startDate: start_date,
        }
      : {
          startDate: "",
        }),
    ...(end_date
      ? {
          endDate: end_date,
        }
      : {
          endDate: "",
        }),
    ...(period
      ? {
          viewType: period as VIEW_TYPE,
        }
      : {
          viewType: VIEW_TYPE.WEEK,
        }),
  });

  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const errorHandler = useApiErrorHandler("PrintTimeline", {});
  const { user } = contextValues;
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof RefinedFormSchema>>({
    resolver: zodResolver(RefinedFormSchema),
    defaultValues: {
      title: "",
      period: VIEW_TYPE.WEEK,
      start_date: null,
      end_date: null,
      selected_tags: [],
    },
  });

  async function loadCaseDetails() {
    try {
      setLoading(true);
      const result = await casesApi.read({
        id: id as string,
        token: user?.access,
      });
      setReviewCase(result.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  const loadEvents = useCallback(async () => {
    try {
      setLoading(true);
      const result = await caseEventApi.request<
        NonNullable<unknown>,
        AxiosResponse<CaseTimeline>
      >({
        token: user?.access,
        url: "/timeline/",
        params: {
          tags: filters.selectedTags.join("|"),
          case: id,
          view: filters.viewType,
          ...(filters.period >= 0
            ? {
                period: filters.period,
              }
            : {}),
          ...(filters.startDate
            ? {
                start_date: moment(filters.startDate).format("YYYY-MM-DD"),
              }
            : {}),
          ...(filters.endDate
            ? {
                end_date: moment(filters.endDate).format("YYYY-MM-DD"),
              }
            : {}),
        },
      });
      setCaseTimeline(result.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, user?.access, id]);

  useEffect(() => {
    if (id) {
      loadCaseDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      loadEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, id]);

  const handleViewChange = (view: VIEW_TYPE) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      viewType: view,
      period: 0,
    }));
  };

  const handlePrev = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      period: prevFilters.period > 0 ? prevFilters.period - 1 : 0,
    }));
  };

  const handleNext = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      period: prevFilters.period + 1,
    }));
  };

  const getNewView = (numDays: number): VIEW_TYPE => {
    if (numDays > 90) {
      return VIEW_TYPE.YEAR;
    } else if (numDays > 7) {
      return VIEW_TYPE.MONTH;
    } else if (numDays > 1) {
      return VIEW_TYPE.WEEK;
    } else {
      return VIEW_TYPE.DAY;
    }
  };

  const handleStartDateChange = useCallback(
    (value?: Date) => {
      if (value) {
        const numDays = moment(filters.endDate).diff(moment(value), "days");
        const newView = getNewView(numDays);
        setFilters((prevFilters) => ({
          ...prevFilters,
          startDate: moment(value).format("MM-DD-YYYY"),
          viewType: newView,
        }));
      }
    },
    [filters.endDate],
  );

  const handleEndDateChange = useCallback(
    (value?: Date) => {
      if (value) {
        const numDays = moment(filters.startDate).diff(moment(value), "days");
        const newView = getNewView(numDays);
        setFilters((prevFilters) => ({
          ...prevFilters,
          endDate: moment(value).format("MM-DD-YYYY"),
          viewType: newView,
        }));
      }
    },
    [filters.startDate],
  );

  const onSubmit = useCallback(
    async (data: z.infer<typeof RefinedFormSchema>) => {
      await timelinesApi
        .create({
          url: `/upsert/`,
          token: user?.access,
          data: {
            timeline: timelineId,
            case: id,
            title: data.title,
            period: filters.viewType,
            selected_tags: filters.selectedTags.join(","),
            start_date: moment(filters.startDate).format("MM-DD-YYYY"),
            end_date: moment(filters.endDate).format("MM-DD-YYYY"),
          },
        })
        .then(() => {
          navigate("/timelines");
        });
    },
    [timelineId, id, filters, user?.access, navigate],
  );

  const exportTimeline = useCallback(async () => {
    try {
      const response = await timelinesApi.request({
        method: "GET",
        token: user?.access,
        responseType: "arraybuffer",
        url: `/download/`,
        params: {
          case: id,
          start_date: filters.startDate
            ? moment(filters.startDate).format("YYYY-MM-DD")
            : "",
          end_date: filters.endDate
            ? moment(filters.endDate).format("YYYY-MM-DD")
            : "",
          period: filters.viewType,
          selected_tags: filters.selectedTags
            ? filters.selectedTags.join(",")
            : "",
          download: 1,
        },
      });

      FileDownload(response.data, `timeline_${id}.pdf`);
    } catch (error) {
      errorHandler(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, user?.access, id]);

  const handleSelectTags = useCallback(
    (tag: ImageTag) => {
      const tagIndex = filters.selectedTags.indexOf(tag.id);
      const copySelectedTags = Object.assign([], filters.selectedTags);
      if (tagIndex > -1) {
        copySelectedTags.splice(tagIndex, 1);
      } else {
        copySelectedTags.push(tag.id);
      }
      setFilters((prevFilters) => ({
        ...prevFilters,
        selectedTags: copySelectedTags,
      }));
    },
    [filters.selectedTags],
  );

  const handleClear = () => {
    setFilters({
      period: 0,
      selectedTags: [],
      startDate: "",
      endDate: "",
      viewType: VIEW_TYPE.WEEK,
    });
  };

  const headerFormat =
    filters.viewType === VIEW_TYPE.YEAR ? "MMM YYYY" : "dddd";

  const getRowElements = useCallback(() => {
    let rowElements: JSX.Element[] = [];
    const MAX_ROW = filters.viewType === VIEW_TYPE.YEAR ? 7 : 8;
    const eventElements = caseTimeline?.results.map((bucket) => (
      <td key={`bucket_${bucket.column}`}>
        {bucket.events.slice(0, MAX_ROW).map((event) => (
          <div key={`event_${event.id}`}>
            <time>{moment(event.date).format("MMM D, YYYY")}</time>
            {event.title}
            <br />
          </div>
        ))}
        {bucket.events.length > MAX_ROW ? (
          <div key="extra_event">
            +{bucket.events.length - MAX_ROW} {t("timelines.statictext.events")}
          </div>
        ) : (
          ""
        )}
      </td>
    ));
    if (
      filters.viewType === VIEW_TYPE.MONTH ||
      filters.viewType === VIEW_TYPE.WEEK
    ) {
      for (
        let sliceIndex = 0;
        sliceIndex < (caseTimeline?.results.length || 0);
        sliceIndex += 7
      ) {
        rowElements.push(
          <tr key={`row_${sliceIndex}`}>
            {eventElements?.slice(sliceIndex, sliceIndex + 7)}
          </tr>,
        );
      }
    } else {
      rowElements = [<tr key={"0"}>{eventElements}</tr>];
    }
    return rowElements;
  }, [filters, caseTimeline, t]);

  const columns = useTimelineEventColumn();

  return !loading ? (
    <>
      {reviewCase?.id ? (
        <>
          <Button
            className="flex gap-2 w-full md:w-auto"
            variant="link"
            onClick={() => navigate(`/cases/${reviewCase.id}`)}
          >
            <BsArrowLeftCircleFill />
            {t("timelines.button.return_to_case_info")}
          </Button>

          <h1 className="w-full text-center text-3xl md:text-4xl mt-4">
            {reviewCase?.name}
          </h1>
          <div className="my-8 w-full flex flex-wrap flex-col md:flex-row gap-2 text-center justify-around items-center">
            <p className="text-md md:text-xl">
              <Trans
                components={{
                  from: (
                    <span className="text-md md:text-xl text-bold">
                      {moment(caseTimeline?.start_period).format("MMM D, YYYY")}
                    </span>
                  ),
                  to: (
                    <span className="text-md md:text-xl text-bold">
                      {moment(caseTimeline?.end_period).format("MMM D, YYYY")}{" "}
                    </span>
                  ),
                }}
                i18nKey="timelines.label.period"
              />
            </p>
            <div className="w-full flex flex-wrap gap-2 text-center justify-evenly items-center">
              <Button size={"icon"} onClick={() => handlePrev()}>
                <BsArrowLeftCircleFill />
              </Button>
              <Button size={"icon"} onClick={() => handleNext()}>
                <BsArrowRightCircleFill />
              </Button>
            </div>
          </div>

          <div className="my-8 w-full timeline overflow-auto whitespace-nowrap pt-4">
            <div>
              {loading ? (
                <h4>{t("common.button.loading")}</h4>
              ) : (
                <div id="single-timeline" className="w-full">
                  {caseTimeline?.results && caseTimeline.results.length > 0 ? (
                    <table className="table event-table w-full">
                      <thead>
                        <tr>
                          {filters.viewType === VIEW_TYPE.YEAR
                            ? caseTimeline?.results.map((bucket) => (
                                <th
                                  key={bucket.column}
                                  className="py-4 text-sm text-center"
                                >
                                  {moment(bucket.column).format(headerFormat)}
                                </th>
                              ))
                            : caseTimeline?.results
                                .slice(0, 7)
                                .map((bucket) => (
                                  <th
                                    key={bucket.column}
                                    className="py-4 text-sm text-center"
                                  >
                                    {moment(bucket.column).format(headerFormat)}
                                  </th>
                                ))}
                        </tr>
                      </thead>
                      <tbody className="timeline-content">
                        {getRowElements().map((item) => item)}
                      </tbody>
                    </table>
                  ) : (
                    <p className="text-center" style={{ fontSize: "18px" }}>
                      <b>{t("timelines.statictext.no_events_in_period")}</b>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col lg:flex-row w-full items-start justify-center border"
            >
              <div className="flex flex-col gap-2 w-[100%] p-4 h-[100%] border-r">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("common.label.title")}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("common.placeholder.title")}
                          {...field}
                          value={field.value || title || undefined}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-2 flex-wrap">
                  <Button disabled={loading} type="submit">
                    {loading
                      ? t("common.button.loading")
                      : t("common.button.save")}
                  </Button>
                  <Button
                    disabled={loading}
                    type="button"
                    onClick={exportTimeline}
                  >
                    {t("common.button.export")}
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={handleClear}
                    type="button"
                  >
                    {t("common.button.clear")}
                  </Button>
                </div>
              </div>
              <div className="h-[100%] w-[100%] p-4 border-r">
                <FormField
                  control={form.control}
                  name="period"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("timelines.title.views")}</FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={(value) => {
                            field.onChange(value);
                            handleViewChange(value as VIEW_TYPE);
                          }}
                          defaultValue={field.value}
                          className="flex flex-col space-y-1"
                        >
                          {[
                            {
                              label: t("timelines.values.1_year"),
                              value: "YEAR",
                            },
                            {
                              label: t("timelines.values.1_month"),
                              value: "MONTH",
                            },
                            {
                              label: t("timelines.values.1_week"),
                              value: "WEEK",
                            },
                            {
                              label: t("timelines.values.1_day"),
                              value: "DAY",
                            },
                          ].map((item) => (
                            <FormItem
                              key={item.value}
                              className="flex items-center space-x-3 space-y-0"
                            >
                              <FormControl>
                                <RadioGroupItem value={item.value} />
                              </FormControl>
                              <FormLabel className="font-normal">
                                {item.label}
                              </FormLabel>
                            </FormItem>
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="h-[100%] flex flex-col gap-4 w-[100%] p-4 border-r">
                <FormField
                  control={form.control}
                  name="start_date"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>{t("timelines.label.start_date")}</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-[240px] pl-3 text-left font-normal",
                                !field.value && "text-muted-foreground",
                              )}
                            >
                              {field.value ? (
                                renderDateString(
                                  field.value as unknown as number,
                                )
                              ) : filters.startDate ? (
                                filters.startDate
                              ) : (
                                <span>
                                  {t("common.statictext.pick_a_date")}
                                </span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            captionLayout="dropdown-buttons"
                            fromYear={1960}
                            toYear={2030}
                            onSelect={(value) => {
                              field.onChange(value);
                              handleStartDateChange(value);
                            }}
                            selected={
                              field.value ||
                              moment(
                                filters.startDate,
                                "YYYY-MM-DD",
                              ).toDate() ||
                              undefined
                            }
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="end_date"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>{t("timelines.label.end_date")}</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-[240px] pl-3 text-left font-normal",
                                !field.value && "text-muted-foreground",
                              )}
                            >
                              {field.value ? (
                                renderDateString(
                                  field.value as unknown as number,
                                )
                              ) : filters.endDate ? (
                                filters.endDate
                              ) : (
                                <span>
                                  {t("common.statictext.pick_a_date")}
                                </span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            captionLayout="dropdown-buttons"
                            fromYear={1960}
                            toYear={2030}
                            onSelect={(value) => {
                              field.onChange(value);
                              handleEndDateChange(value);
                            }}
                            selected={
                              field.value ||
                              moment(filters.endDate, "YYYY-MM-DD").toDate() ||
                              undefined
                            }
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {caseTagsLoading ? (
                t("common.button.loading")
              ) : (
                <div className="h-[100%] w-[100%] p-4">
                  <h4>{t("common.statictext.filter_by")}</h4>
                  <div className="flex gap-2 flex-wrap max-w-[300px] mt-4">
                    {caseImageTags?.map(({ tag, name }) => (
                      <img
                        key={name || tag.display_name}
                        className={
                          filters.selectedTags.indexOf(tag.id) > -1
                            ? "w-[32px] h-auto border border-white rounded-full"
                            : "w-[32px] h-auto grey-img border border-white rounded-full"
                        }
                        alt={name || tag.display_name}
                        title={name || tag.display_name}
                        data-tag={name || tag.display_name}
                        data-toggle="tooltip"
                        onClick={() => handleSelectTags(tag)}
                        src={tag.icon.replace(
                          DOMAIN_REGEX,
                          `${process.env.REACT_APP_BACKEND_URL || ""}/`,
                        )}
                      />
                    ))}
                  </div>
                </div>
              )}
            </form>
          </Form>
          <div className="row" style={{ paddingTop: "10px" }}>
            <DataTable
              columns={columns}
              data={flatten(
                caseTimeline?.results.map((col) => col.events) || [],
              )}
            />
          </div>
        </>
      ) : (
        <p>{t("timelines.statictext.case_not_found")}</p>
      )}
    </>
  ) : (
    <Loader />
  );
};

export default PrintTimeline;
