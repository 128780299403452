import React, { useState } from "react";
import { useResearchDocumentsColumns } from "../Documents/hooks";
import { DataTable } from "@/components/ui/data-table";
import { useListApi } from "@/hooks/useListApi";
import {
  ResearchDocument,
  researchDocumentApi,
  ResearchDocumentFolder,
  researchDocumentFolderApi,
} from "@/api/research";
import { Input } from "@/components/ui/input";
import Loader from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../../CustomPagination";
import { CustomPaginationInfo } from "../../CustomPaginationInfo";
import { AddUpdateResearchDocument } from "./AddUpdateResearchDocument";
import { ResourceInfoModal } from "../../ResourceInfoModal";
import { convertFileUrlToFileName } from "@/lib/utils";
import { MoveResearchDocument } from "./MoveResearchDocument";
import { Button } from "@/components/ui/button";
import { useIsCreatorOrAdmin } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

export function RenderDocumentsList({
  researchFolderId,
  citationType,
}: {
  researchFolderId: number;
  citationType: string;
}) {
  const { t } = useTranslation();
  const [selectedResearchDocument, selectResearchDocument] =
    useState<ResearchDocument>();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [openMove, setOpenMove] = useState<boolean>(false);

  const isCreatorOrAdmin = useIsCreatorOrAdmin(selectedResearchDocument);

  const { apiResult, refresh, pagination, search, loading, order } =
    useListApi<ResearchDocumentFolder>({
      baseApiObject: researchDocumentFolderApi,
      defaultParams: {
        folder: researchFolderId,
      },
      enable: true,
    });

  const columns = useResearchDocumentsColumns({
    citationType,
    refresh,
    setOrder: order.set,
    edit: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenEdit(true);
    },
    info: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenInfo(true);
    },
    handleMoveItem: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenMove(true);
    },
  });

  return (
    <>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {isCreatorOrAdmin && (
        <AddUpdateResearchDocument
          open={openEdit}
          setOpen={setOpenEdit}
          document={selectedResearchDocument}
          refresh={refresh}
        />
      )}
      {!!selectedResearchDocument && (
        <MoveResearchDocument
          document={selectedResearchDocument}
          refresh={refresh}
          open={openMove}
          setOpen={setOpenMove}
        />
      )}
      <ResourceInfoModal
        open={openInfo}
        setOpen={setOpenInfo}
        title={t("research.title.research_document")}
        mappings={{
          data: [
            {
              label: t("common.label.title"),
              value: selectedResearchDocument?.title || "--",
            },
            {
              label: t("common.label.file"),
              value: selectedResearchDocument?.file ? (
                <a
                  href={selectedResearchDocument?.file.toString()}
                  target="_blank"
                  className="text-left text-primary hover:underline cursor-pointer break-all"
                  rel="noreferrer"
                >
                  {convertFileUrlToFileName(
                    selectedResearchDocument.file.toString(),
                  )}
                </a>
              ) : (
                "--"
              ),
            },
            {
              label: t("common.label.cases"),
              value:
                selectedResearchDocument?.cases
                  .map((item) => item.name)
                  .join(", ") || "--",
            },
            {
              label: t("common.label.description"),
              value: selectedResearchDocument?.description || "--",
            },
            {
              label: t("research.label.apa_citation"),
              value: selectedResearchDocument?.apa_citation || "--",
            },
            {
              label: t("research.label.bluebook_citation"),
              value: selectedResearchDocument?.bluebook_citation || "--",
            },
          ],
        }}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <DataTable
            columns={columns}
            data={apiResult?.results.map((item) => item.document) || []}
          />
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
              <CustomPageSelector
                pageSize={pagination.pageSize}
                onPageSizeChange={(value) => {
                  pagination.setPageSize(value);
                  pagination.setPage(1);
                }}
              />
              <CustomPaginationInfo
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
              />
            </div>
            <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
              <CustomPagination
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
                onPageChange={(value) => pagination.setPage(value)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export function RenderDefaultDocumentsList({
  researchFolderId,
  citationType,
}: {
  researchFolderId: number;
  citationType: string;
}) {
  const { t } = useTranslation();
  const [selectedResearchDocument, selectResearchDocument] =
    useState<ResearchDocument>();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [openMove, setOpenMove] = useState<boolean>(false);
  const { apiResult, refresh, loading, search, pagination, order } =
    useListApi<ResearchDocument>({
      baseApiObject: researchDocumentApi,
      defaultParams: {
        ...(researchFolderId === 0 && {
          default_public: 1,
        }),
        ...(researchFolderId === -1 && {
          default_private: 1,
        }),
      },
      enable: true,
    });

  const columns = useResearchDocumentsColumns({
    citationType,
    refresh,
    setOrder: order.set,
    edit: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenEdit(true);
    },
    info: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenInfo(true);
    },
    handleMoveItem: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenMove(true);
    },
  });

  const isCreatorOrAdmin = useIsCreatorOrAdmin(selectedResearchDocument);

  return (
    <>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {!!selectedResearchDocument && (
        <MoveResearchDocument
          document={selectedResearchDocument}
          refresh={refresh}
          open={openMove}
          setOpen={setOpenMove}
        />
      )}
      {isCreatorOrAdmin && (
        <AddUpdateResearchDocument
          open={openEdit}
          setOpen={setOpenEdit}
          document={selectedResearchDocument}
          refresh={refresh}
        />
      )}
      <ResourceInfoModal
        open={openInfo}
        setOpen={setOpenInfo}
        title={t("research.title.research_document")}
        mappings={{
          data: [
            {
              label: t("common.label.title"),
              value: selectedResearchDocument?.title || "--",
            },
            {
              label: t("common.label.file"),
              value: selectedResearchDocument?.file ? (
                <a
                  href={selectedResearchDocument?.file.toString()}
                  target="_blank"
                  className="text-left text-primary hover:underline cursor-pointer break-all"
                  rel="noreferrer"
                >
                  {convertFileUrlToFileName(
                    selectedResearchDocument.file.toString(),
                  )}
                </a>
              ) : (
                "--"
              ),
            },
            {
              label: t("common.label.cases"),
              value:
                selectedResearchDocument?.cases
                  .map((item) => item.name)
                  .join(", ") || "--",
            },
            {
              label: t("common.label.description"),
              value: selectedResearchDocument?.description || "--",
            },
            {
              label: t("research.label.apa_citation"),
              value: selectedResearchDocument?.apa_citation || "--",
            },
            {
              label: t("research.label.bluebook_citation"),
              value: selectedResearchDocument?.bluebook_citation || "--",
            },
          ],
        }}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={apiResult?.results || []} />
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
              <CustomPageSelector
                pageSize={pagination.pageSize}
                onPageSizeChange={(value) => {
                  pagination.setPageSize(value);
                  pagination.setPage(1);
                }}
              />
              <CustomPaginationInfo
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
              />
            </div>
            <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
              <CustomPagination
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
                onPageChange={(value) => pagination.setPage(value)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
