import { useState, useEffect, useCallback, useContext } from "react";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { ApiList, BaseApi } from "@/api";
import { useDebounce } from "@uidotdev/usehooks";
import { compact, orderBy } from "lodash";
const { uniqBy } = require("lodash");

export interface UseListApi<T> {
  pagination: {
    page: number;
    pageSize: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
  };
  search: {
    value: string;
    set: (value: React.SetStateAction<string>) => void;
  };
  order: {
    value: string;
    set: React.Dispatch<React.SetStateAction<string>>;
  };
  loading: boolean;
  apiResult: ApiList<T> | undefined;
  list: T[];
  refresh: () => Promise<void>;
  loadNext: () => void;
}

export const useListApi = <T>({
  baseApiObject,
  defaultPageSize = 10,
  defaultParams = {},
  defaultOrder = "",
  disablePagination = false,
  enable,
  additionalListIds = [],
}: {
  baseApiObject: BaseApi<T>;
  defaultPageSize?: number;
  defaultParams?: Record<string, unknown>;
  defaultOrder?: string;
  disablePagination?: boolean;
  enable: boolean;
  additionalListIds?: unknown[];
}): UseListApi<T> => {
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [order, setOrder] = useState<string>(defaultOrder);
  const [customQueryParams, setCustomQueryParams] =
    useState<Record<string, unknown>>(defaultParams);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiResult, setApiResult] = useState<ApiList<T> | undefined>(undefined);
  const [additionalResults, setAdditionalResults] = useState<T[]>([]);
  const [list, setList] = useState<T[]>([]);
  const { values } = useContext<AuthContextType>(AuthContext);
  const debouncedSearchTerm = useDebounce(search, 500);
  const debouncedOrder = useDebounce(order, 500);
  const errorHandler = useApiErrorHandler("useListApi", {
    callback403: () => {}, // surpress toast messages.
  });

  const { user, isLoggedin } = values;
  const defaultStringQueryParams = JSON.stringify(defaultParams);
  const customStringQueryParams = JSON.stringify(customQueryParams);
  const cleanedAdditionalIds = compact(additionalListIds);
  const additionalListIdsString = JSON.stringify(cleanedAdditionalIds);

  const fetchAdditionalListIds = useCallback(async () => {
    if (cleanedAdditionalIds.length) {
      const results = await Promise.all(
        cleanedAdditionalIds.map(async (item) => {
          try {
            const { data } = await baseApiObject.read({
              token: user?.access,
              id: item as string,
            });
            return data;
          } catch (error) {
            errorHandler(error);
            return undefined;
          }
        }),
      );
      const cleanedResults = compact(results);
      setAdditionalResults(cleanedResults);
    }
  }, [cleanedAdditionalIds, user?.access, baseApiObject, errorHandler]);

  const refresh = useCallback(async () => {
    if (isLoggedin && enable) {
      try {
        setLoading(true);
        const { data } = await baseApiObject.list({
          token: user?.access,
          params: {
            search: debouncedSearchTerm,
            ordering: debouncedOrder,
            ...customQueryParams,
            ...(!!disablePagination
              ? {
                  page: undefined,
                  page_size: undefined,
                }
              : {
                  page: page,
                  page_size: pageSize,
                }),
          },
        });
        if (disablePagination) {
          setList(data as unknown as T[]);
          return;
        } else {
          setApiResult(data);
        }
        setList((previous) =>
          uniqBy(
            orderBy(
              [...previous, ...data.results],
              ["updated_date", "created_date"],
              ["desc", "desc"],
            ),
            "id",
          ),
        );
      } catch (error) {
        setApiResult(undefined);
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [
    isLoggedin,
    customQueryParams,
    baseApiObject,
    page,
    pageSize,
    errorHandler,
    user?.access,
    debouncedSearchTerm,
    debouncedOrder,
    disablePagination,
    enable,
  ]);

  const loadNext = () => {
    setPage((previousPage) => previousPage + 1);
  };

  const shouldRefresh = [
    page,
    debouncedSearchTerm,
    debouncedOrder,
    pageSize,
    customStringQueryParams,
    enable,
  ].join("_");

  const deboundRefreshKey = useDebounce(shouldRefresh, 1000);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deboundRefreshKey]);

  useEffect(() => {
    setList([]);
    setPage(1);
    setCustomQueryParams(defaultParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultStringQueryParams]);

  useEffect(() => {
    setList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (enable) {
      fetchAdditionalListIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalListIdsString, enable]);

  return {
    pagination: {
      page,
      pageSize,
      setPage,
      setPageSize,
    },
    search: {
      value: search,
      set: (value: React.SetStateAction<string>) => {
        setSearch(value);
        setPage(1);
      },
    },
    order: {
      value: order,
      set: setOrder,
    },
    loading,
    apiResult,
    list: uniqBy([...list, ...additionalResults], "id"),
    refresh,
    loadNext,
  };
};
