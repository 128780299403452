import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DataTable } from "@/components/ui/data-table";
import { useEventColumn } from "./hooks";
import { Button } from "@/components/ui/button";
import { BsPlusCircleFill } from "react-icons/bs";
import { AddUpdateEvents } from "./AddUpdateEvents";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import Loader, { InLineLoader } from "@/components/ui/loader";
import { Input } from "@/components/ui/input";
import { useListApi } from "@/hooks/useListApi";
import { CaseEvent, caseEventApi, legacyCaseEventApi } from "@/api/events";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { useEventTimeline } from "@/api/timelines";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ResourceInfoModal } from "../ResourceInfoModal";
import { convertFileUrlToFileName } from "@/lib/utils";
import sanitizeHtml from "sanitize-html";
import { usePermission } from "@/hooks/usePermission";
import { Case } from "@/api/cases";
import { useTranslation } from "react-i18next";
import { PDFFileViewerModal } from "./PDFFileViewerModal";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

function CaseEventTable({
  reviewCase,
  tags,
  setYearFilter,
  yearFilter,
  refreshKey,
  sendParentRefreshSignal,
}: {
  reviewCase: Case;
  tags?: string[];
  setYearFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  yearFilter?: string;
  refreshKey?: string;
  sendParentRefreshSignal?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [selectedItem, selectItem] = useState<CaseEvent>();
  const [loadingAll, setLoadingAll] = useState<boolean>(false);
  const [loadingSelected, setLoadingSelected] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const hasAddEventPermission = usePermission("review.add_event");

  const {
    loading: loadingList,
    apiResult,
    pagination,
    search,
    refresh,
    order,
  } = useListApi<CaseEvent>({
    baseApiObject: caseEventApi,
    defaultParams: {
      case: reviewCase.id,
      year: yearFilter,
      tags: tags ? tags.join("|") : undefined,
    },
    enable: true,
    defaultOrder: "title",
  });

  const errorHandler = useApiErrorHandler("CaseEventTable", {});
  const { loading: loadingEventTimeline, result: eventTimeline } =
    useEventTimeline({ case: reviewCase.id });

  const availableFilterYears = useMemo(() => {
    if (!loadingEventTimeline) {
      if (eventTimeline?.start_date && eventTimeline.end_date) {
        const years = [];
        const startYear = parseInt(
          eventTimeline.start_date.split("-").shift() as string,
        );
        const endYear = parseInt(
          eventTimeline.end_date.split("-").shift() as string,
        );
        for (let i = startYear; i <= endYear; i++) {
          years.push(i);
        }
        return years;
      }
    }
    return [];
  }, [loadingEventTimeline, eventTimeline]);

  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;

  const customRefresh = () => {
    if (sendParentRefreshSignal) {
      return sendParentRefreshSignal?.();
    } else {
      return refresh();
    }
  };

  const columns = useEventColumn({
    edit: (item: CaseEvent) => {
      selectItem(item);
      setOpenEdit(true);
    },
    info: (item: CaseEvent) => {
      selectItem(item);
      setOpenInfo(true);
    },
    fileInfo: (item: CaseEvent) => {
      selectItem(item);
      setOpenFile(true);
    },
    refresh: customRefresh,
    setOrder: order.set,
  });

  const handleDownloadEvents = async () => {
    try {
      setLoadingAll(true);
      const response = await legacyCaseEventApi.downloadEvents({
        caseId: reviewCase.id,
        token: user?.access,
      });
      FileDownload(response.data, `case_events_${reviewCase.name}.pdf`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingAll(false);
    }
  };

  const handleDownloadFilteredEvents = useCallback(async () => {
    try {
      setLoadingSelected(true);
      const response = await legacyCaseEventApi.downloadEvents({
        caseId: reviewCase.id,
        token: user?.access,
        params: {
          year: yearFilter,
        },
      });
      FileDownload(
        response.data,
        `case_events_${yearFilter}_${reviewCase.name}.pdf`,
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearFilter, reviewCase.name]);

  useEffect(() => {
    if (refreshKey) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  return (
    <div className="w-full my-8">
      <div className="flex flex-col md:flex-row md:justify-between my-4 gap-4 md:gap-0">
        <div className="flex flex-wrap justify-start gap-4 items-center">
          <AddUpdateEvents
            open={openEdit}
            setOpen={setOpenEdit}
            caseEvent={selectedItem}
            refresh={customRefresh}
            defaultCase={reviewCase}
          />
          {selectedItem?.source?.file && (
            <PDFFileViewerModal
              open={openFile}
              title={selectedItem.title}
              setOpen={setOpenFile}
              file={selectedItem?.source?.file as string}
              pageFrom={
                selectedItem.starting_page
                  ? parseInt(selectedItem.starting_page)
                  : 0
              }
              pageTo={
                selectedItem.ending_page
                  ? parseInt(selectedItem.ending_page)
                  : 0
              }
            />
          )}
          {hasAddEventPermission && (
            <AddUpdateEvents
              open={openCreate}
              setOpen={setOpenCreate}
              refresh={customRefresh}
              trigger={
                <Button className="flex gap-2">
                  <BsPlusCircleFill /> {t("events.button.add")}
                </Button>
              }
              defaultCase={reviewCase}
            />
          )}
          <Button onClick={handleDownloadEvents} disabled={loadingAll}>
            {t("events.button.download_all")}
            {loadingAll && <InLineLoader size={8} />}
          </Button>
          <Button
            onClick={handleDownloadFilteredEvents}
            disabled={loadingSelected}
          >
            {t("events.button.download_selected")}
            {loadingSelected && <InLineLoader size={8} />}
          </Button>
          <ResourceInfoModal
            open={openInfo}
            setOpen={setOpenInfo}
            title={t("events.title.event")}
            mappings={{
              data: [
                {
                  label: t("common.label.title"),
                  value: selectedItem?.title || "--",
                },
                {
                  label: t("common.label.author"),
                  value: selectedItem?.author || "--",
                },
                {
                  label: t("common.label.date"),
                  value: selectedItem?.date || "--",
                },
                {
                  label: t("common.label.author"),
                  value: sanitizeHtml(selectedItem?.description || "--"),
                },
                {
                  label: t("common.label.source"),
                  value: selectedItem?.source?.title || "--",
                },
                {
                  label: t("events.label.case_tags"),
                  value:
                    selectedItem?.tags?.map((item) => item.name).join(", ") ||
                    "--",
                },
                {
                  label: t("common.label.source_file"),
                  value: selectedItem?.source?.file ? (
                    <a
                      href={selectedItem.source.file.toString()}
                      target="_blank"
                      className="text-left text-primary hover:underline cursor-pointer break-all"
                      rel="noreferrer"
                    >
                      {convertFileUrlToFileName(
                        selectedItem.source.file.toString(),
                      )}
                    </a>
                  ) : (
                    "--"
                  ),
                },
                {
                  label: t("common.label.start_page"),
                  value: selectedItem?.starting_page || "--",
                },
                {
                  label: t("common.label.end_page"),
                  value: selectedItem?.ending_page || "--",
                },
                {
                  label: t("events.label.include_in_timeline"),
                  value:
                    selectedItem?.include_in_timeline.toString() === "true"
                      ? t("common.values.yes")
                      : t("common.values.no"),
                },
              ],
            }}
          />
        </div>
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                {yearFilter ? yearFilter : t("events.button.select_year")}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>
                {t("events.statictext.filter_by_year")}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup
                value={yearFilter?.toString()}
                onValueChange={(value) => setYearFilter(value)}
              >
                <DropdownMenuRadioItem value={""}>
                  {t("common.values.all")}
                </DropdownMenuRadioItem>
                {availableFilterYears.map((item) => (
                  <DropdownMenuRadioItem
                    key={item.toString()}
                    value={item.toString()}
                  >
                    {item}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loadingList ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CaseEventTable;
