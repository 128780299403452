import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CaseContentPDF from "./CaseContentPDF";

const SUPPORTED_EXTENSIONS = ["pdf", "jpg", "jpeg", "png", "gif", "txt"];

const getFileExtension = (url: string): string => {
  // Remove query string if present
  const cleanUrl = url.split("?")[0];
  return cleanUrl.split(".").pop()?.toLowerCase() || "";
};

interface FileViewerProps {
  fileUrl: string;
  pageFrom?: number;
  pageTo?: number;
}

const FileViewer: React.FC<FileViewerProps> = ({
  fileUrl,
  pageFrom,
  pageTo,
}) => {
  const { t } = useTranslation();
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    const extension = getFileExtension(fileUrl);
    setIsSupported(SUPPORTED_EXTENSIONS.includes(extension));
  }, [fileUrl]);

  return (
    <>
      {isSupported ? (
        <CaseContentPDF file={fileUrl} pageFrom={pageFrom} pageTo={pageTo} />
      ) : (
        <div className="text-center">
          <p className="m-4 md:mt-10">
            {t("components.statictext.cant_open_file")}
          </p>
          <p className="m-4">
            {" "}
            {t("components.statictext.to_download_click_link")}
          </p>
          <a
            href={fileUrl}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 break-all"
          >
            {t("common.button.download_file")}
          </a>
        </div>
      )}
    </>
  );
};

export default FileViewer;
