import { BaseApi } from "@/api/index";
import { Case } from "./cases";

export interface ResearchAuthor {
  id: number;
  deleted_at?: string;
  created_date: string;
  updated_date?: string;
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  created_by: number;
  updated_by?: number;
  deleted_by?: number;
  research: number; // Research Doc ID (Attach it after doc get created and update it when doc get updated)
}

export interface ResearchFolder {
  id: number;
  deleted_at?: string;
  created_date: string;
  updated_date?: string;
  name: string;
  public: boolean;
  created_by: number;
  updated_by?: number;
  deleted_by?: number;
  parent: number[];
}

export interface ResearchDocument {
  id: number;
  cases: Case[];
  deleted_at?: string;
  created_date: string;
  updated_date?: string;
  file: File | string;
  title: string;
  description: string;
  private: boolean;
  article?: string | null;
  journal_name?: string | null;
  journal_name_abbreviation?: string | null;
  volume?: string | null;
  supplement_or_issue?: string | null;
  year?: string | null;
  page_from?: string | null;
  page_to?: string | null;
  retrieved_url?: string | null;
  extracted_text?: string;
  extracted_text_per_page?: string;
  created_by: number;
  updated_by?: number;
  deleted_by?: number;
  bluebook_citation: string;
  apa_citation: string;

  // Write only field to write multiple cases
  case_ids?: string;
}

export interface ResearchDocumentFolder {
  id: number;
  deleted_at?: string;
  created_date: string;
  updated_date?: string;
  created_by: number;
  updated_by?: number;
  deleted_by?: number;
  document: ResearchDocument;
  folder: number;
}

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

class ResearchAuthorApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/research_authors/" });
  }
}
class ResearchFolderApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/research_folders/" });
  }
}
class ResearchDocumentApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/research_documents/" });
  }
}
class ResearchDocumentFolderApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/research_document_folders/" });
  }
}
class ResearchDocumentsLegacyApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "research" });
  }

  downloadDocumentsList({ token, caseId }: { token?: string; caseId: number }) {
    return this.request({
      method: "get",
      url: `/download_case_research/${caseId}/`,
      token,
      responseType: "arraybuffer",
    });
  }

  downloadAllDocuments({
    token,
    folderId,
  }: {
    token?: string;
    folderId: number;
  }) {
    return this.request({
      method: "get",
      url: `/download_documents/${folderId}`,
      token,
      responseType: "arraybuffer",
    });
  }

  downloadListInPdf({ token, folderId }: { token?: string; folderId: number }) {
    return this.request({
      method: "get",
      url: `/download_pdf/${folderId}`,
      token,
      responseType: "arraybuffer",
    });
  }

  moveDocuments({
    token,
    documentId,
    folderIds,
  }: {
    token?: string;
    documentId: number;
    folderIds: string[];
  }) {
    return this.request({
      method: "post",
      url: "/api/move-document/",
      token,
      data: {
        document: documentId,
        folder_ids: folderIds,
      },
    });
  }
}

export const researchAuthorApi = new ResearchAuthorApi<ResearchAuthor>();
export const researchFolderApi = new ResearchFolderApi<ResearchFolder>();
export const researchDocumentApi = new ResearchDocumentApi<ResearchDocument>();
export const researchDocumentFolderApi =
  new ResearchDocumentFolderApi<ResearchDocumentFolder>();
export const researchDocumentLegacyApi =
  new ResearchDocumentsLegacyApi<unknown>();
