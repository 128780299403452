import React, { useContext, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import useSound from "use-sound";
import { Trans, useTranslation } from "react-i18next";

const IDEAL_MINUTES = 30;

export default function AreYouThere({
  open,
  setOpen,
  stopAndSaveTimer,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  stopAndSaveTimer: () => void;
}) {
  const { t } = useTranslation();
  const [playBeep] = useSound("/sounds/beep.mp3");
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { timer } = contextValues;

  const autoStopTimer = useRef<NodeJS.Timeout | null>(null);
  const idealTimer = useRef<NodeJS.Timeout | null>(null);
  const beepInterval = useRef<NodeJS.Timeout | null>(null);

  const clearBeepInterval = () => {
    if (beepInterval.current) {
      clearInterval(beepInterval.current);
      beepInterval.current = null;
    }
  };

  const startBeepInterval = () => {
    if (!beepInterval.current) {
      beepInterval.current = setInterval(() => {
        playBeep();
      }, 3000);
    }
  };

  const clearIdealTimer = () => {
    if (idealTimer.current) {
      clearTimeout(idealTimer.current);
      idealTimer.current = null;
    }
  };

  const startIdealTimer = () => {
    if (!idealTimer.current) {
      idealTimer.current = setTimeout(
        () => {
          setOpen(true);
        },
        1000 * 60 * IDEAL_MINUTES,
      );
    }
  };

  const clearAutoStopTimer = () => {
    if (autoStopTimer.current) {
      clearTimeout(autoStopTimer.current);
      autoStopTimer.current = null;
    }
  };

  const startAutoStopTimer = () => {
    if (!autoStopTimer.current) {
      startBeepInterval();
      autoStopTimer.current = setTimeout(() => {
        clearBeepInterval();
        stopAndSaveTimer();
        setOpen(false);
      }, 10000);
    }
  };

  useEffect(() => {
    if (timer?.startTime) {
      if (open) {
        clearIdealTimer();
        startAutoStopTimer();
      } else {
        clearAutoStopTimer();
        startIdealTimer();
      }
    } else {
      clearAutoStopTimer();
      clearIdealTimer();
      clearBeepInterval();
    }

    return () => {
      clearAutoStopTimer();
      clearIdealTimer();
      clearBeepInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, timer?.startTime]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t("casetimer.title.are_you_there")}</DialogTitle>
        </DialogHeader>
        <p>
          <Trans
            i18nKey="casetimer.statictext.still_running_part_1"
            components={{
              yes: <b>{t("common.values.yes")}</b>,
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="casetimer.statictext.still_running_part_2"
            components={{
              stop: <b>{t("casetimer.button.stop_timer")}</b>,
              will_stop: <b>{t("casetimer.statictext.will_stop")}</b>,
            }}
          />
        </p>
        <DialogFooter>
          <Button
            variant="default"
            onClick={() => {
              setOpen(false);
            }}
            type="button"
          >
            {t("common.values.yes")}
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              setOpen(false);
              stopAndSaveTimer();
            }}
            type="button"
          >
            {t("casetimer.button.stop_timer")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
