import React, { useContext, useEffect, useState } from "react";
import { useResearchDocumentsColumns } from "../Documents/hooks";
import { DataTable } from "@/components/ui/data-table";
import { useListApi } from "@/hooks/useListApi";
import {
  ResearchDocument,
  researchDocumentApi,
  researchDocumentLegacyApi,
} from "@/api/research";
import { Input } from "@/components/ui/input";
import Loader, { InLineLoader } from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../../CustomPagination";
import { CustomPaginationInfo } from "../../CustomPaginationInfo";
import { AddUpdateResearchDocument } from "./AddUpdateResearchDocument";
import { BsPlusCircleFill } from "react-icons/bs";
import { Button } from "@/components/ui/button";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { ResourceInfoModal } from "../../ResourceInfoModal";
import { convertFileUrlToFileName } from "@/lib/utils";
import { MoveResearchDocument } from "./MoveResearchDocument";
import { useIsCreatorOrAdmin, usePermission } from "@/hooks/usePermission";
import { Case } from "@/api/cases";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

export function ResearchDocumentTable({
  reviewCase,
  refreshKey,
}: {
  reviewCase: Case;
  refreshKey?: string;
}) {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const errorHandler = useApiErrorHandler("ResearchDocumentTable", {});

  const [selectedResearchDocument, selectResearchDocument] =
    useState<ResearchDocument>();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [openMove, setOpenMove] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [citationType, setCitationType] = useState<"bluebook" | "apa">(
    "bluebook",
  );
  const hasAddResearchPermission = usePermission("research.add_research");
  const isCreatorOrAdmin = useIsCreatorOrAdmin(selectedResearchDocument);

  const {
    apiResult,
    refresh,
    pagination,
    search,
    loading: loadingList,
    order,
  } = useListApi<ResearchDocument>({
    baseApiObject: researchDocumentApi,
    defaultParams: {
      case_id: reviewCase.id,
    },
    enable: true,
  });

  const columns = useResearchDocumentsColumns({
    citationType,
    refresh,
    setOrder: order.set,
    edit: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenEdit(true);
    },
    info: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenInfo(true);
    },
    handleMoveItem: (item: ResearchDocument) => {
      selectResearchDocument(item);
      setOpenMove(true);
    },
    hideMoveAction: true,
  });

  const handleDownloadList = async () => {
    try {
      setLoading(true);
      const response = await researchDocumentLegacyApi.downloadDocumentsList({
        token: user?.access,
        caseId: reviewCase.id,
      });
      FileDownload(response.data, `research_as_pdf_${reviewCase.name}.pdf`);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (refreshKey) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  return (
    <>
      <div className="flex flex-wrap justify-between items-center gap-4 md:gap-0">
        {hasAddResearchPermission && (
          <AddUpdateResearchDocument
            open={openCreate}
            setOpen={setOpenCreate}
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("research.button.add")}
              </Button>
            }
            refresh={refresh}
            defaultCase={reviewCase.id}
          />
        )}
        {!!selectedResearchDocument && (
          <MoveResearchDocument
            document={selectedResearchDocument}
            refresh={refresh}
            open={openMove}
            setOpen={setOpenMove}
          />
        )}
        {isCreatorOrAdmin && (
          <AddUpdateResearchDocument
            open={openEdit}
            setOpen={setOpenEdit}
            document={selectedResearchDocument}
            refresh={refresh}
            defaultCase={reviewCase.id}
          />
        )}
        <Button onClick={() => handleDownloadList()} disabled={loading}>
          {t("research.button.download_as_pdf")}
          {loading && <InLineLoader size={8} />}
        </Button>
        <ResourceInfoModal
          open={openInfo}
          setOpen={setOpenInfo}
          title={t("research.title.research_document")}
          mappings={{
            data: [
              {
                label: t("common.label.title"),
                value: selectedResearchDocument?.title || "--",
              },
              {
                label: t("common.label.file"),
                value: selectedResearchDocument?.file ? (
                  <a
                    href={selectedResearchDocument?.file.toString()}
                    target="_blank"
                    className="text-left text-primary hover:underline cursor-pointer break-all"
                    rel="noreferrer"
                  >
                    {convertFileUrlToFileName(
                      selectedResearchDocument.file.toString(),
                    )}
                  </a>
                ) : (
                  "--"
                ),
              },
              {
                label: t("common.label.cases"),
                value:
                  selectedResearchDocument?.cases
                    .map((item) => item.name)
                    .join(", ") || "--",
              },
              {
                label: t("common.label.description"),
                value: selectedResearchDocument?.description || "--",
              },
              {
                label: t("research.label.apa_citation"),
                value: selectedResearchDocument?.apa_citation || "--",
              },
              {
                label: t("research.label.bluebook_citation"),
                value: selectedResearchDocument?.bluebook_citation || "--",
              },
            ],
          }}
        />
      </div>
      <div className="w-full my-4 flex justify-between">
        <div className="flex gap-2 w-full my-4 md:w-[410px]">
          <Input
            value={search.value}
            type="text"
            className="md:w-[410px]"
            placeholder={t("common.placeholder.search")}
            onChange={(event) => search.set(event.target.value)}
          />
          {search.value && (
            <Button onClick={() => search.set("")}>
              {t("common.button.clear")}
            </Button>
          )}
        </div>
        <div className="flex items-center">
          <Button
            onClick={() => setCitationType("bluebook")}
            variant="link"
            className={citationType === "bluebook" ? "underline" : ""}
          >
            {t("research.button.bluebook")}
          </Button>
          |
          <Button
            onClick={() => setCitationType("apa")}
            variant="link"
            className={citationType === "apa" ? "underline" : ""}
          >
            {t("research.button.apa")}
          </Button>
        </div>
      </div>
      {loadingList ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
