import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { DeleteNote } from "./DeleteNote";
import { Note } from "@/api/notes";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { usePermission } from "@/hooks/usePermission";
import { renderDateForTable } from "@/lib/utils";
import LongDescription from "../LongDescription";
import { useTranslation } from "react-i18next";

export const useNotesColumn = ({
  refresh,
  setOrder,
  edit,
  info,
}: {
  refresh: () => Promise<void>;
  setOrder: Dispatch<SetStateAction<string>>;
  edit: (item: Note) => void;
  info: (item: Note) => void;
}) => {
  const { t } = useTranslation();
  const hasEditPermission = usePermission("notes.change_note");
  const hasDeletePermission = usePermission("notes.delete_note");

  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<Note>[] = [
    {
      accessorKey: "title",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("title");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.title")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "title"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "title"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const noteItem = row.original;
        const value = (row.getValue("title") as string) || "--";
        return (
          <div
            className="text-left text-primary hover:underline cursor-pointer"
            onClick={() => info(noteItem)}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessorKey: "author",
      header: () => <div className="text-left">{t("common.label.author")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("author") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "date",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("date");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.date")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "date"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "date"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = renderDateForTable(row.getValue("date") || "--");
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "description",
      header: () => (
        <div className="text-left">{t("common.label.description")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("description") as string) || "--";
        return (
          <LongDescription text={value} className="text-left max-w-[400px]" />
        );
      },
    },
    {
      accessorKey: "starting_page",
      header: () => <div className="text-left">{t("common.label.page")}</div>,
      cell: ({ row }) => {
        const noteItem = row.original;
        return (
          <div className="text-left">
            {noteItem.starting_page} - {noteItem.ending_page}
          </div>
        );
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const noteItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasEditPermission && (
              <BsFillPencilFill
                className="hover:text-primary cursor-pointer"
                onClick={() => edit(noteItem)}
              />
            )}
            {hasDeletePermission && (
              <DeleteNote
                note={noteItem}
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
