import React, { useContext, useEffect, useState } from "react";
import Timeline from "timelinejs-react";
import { reviewApi } from "@/api/review";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { Button } from "@/components/ui/button";
import Loader from "@/components/ui/loader";
import { useTranslation } from "react-i18next";

const CaseTimelineWidget = ({
  caseId,
  tags,
  year,
  refreshKey,
}: {
  caseId: number;
  tags?: string[];
  year?: string;
  refreshKey?: string;
}) => {
  const defaultOptions: TimelineOptions = {
    timenav_height_min: 25,
    marker_padding: 2,
    start_at_end: true,
  };

  const initialData = {
    events: [],
    title: {
      text: {
        headline: "",
        text: "",
      },
    },
  };

  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<{
    events: Slide[];
    title: TitleSlide;
  }>(initialData);
  const [filteredEvents, setFilteredEvents] = useState<Slide[]>([]);

  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const errorHandler = useApiErrorHandler("CaseTimelineWidget", {});

  const fetchCaseTimeline = async (
    filterTags?: string[],
    filterYear?: string,
  ) => {
    try {
      setLoading(true);
      const result = await reviewApi.caseTimeline({
        id: caseId,
        token: user?.access,
        tags: filterTags ? filterTags.join("|") : undefined,
        year: filterYear,
      });
      const data = result.data as unknown as {
        events: Slide[];
        title: TitleSlide;
      };
      if (data.events !== undefined && data.title !== undefined) {
        setEvents(data);
        setFilteredEvents(data.events);
      } else {
        setEvents(initialData);
        setFilteredEvents([]);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.access) {
      fetchCaseTimeline(tags, year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.access, tags, year, refreshKey]);

  const gotoStart = () => {
    setFilteredEvents([events.events[0]]);
  };

  const gotoEnd = () => {
    setFilteredEvents([events.events[events.events.length - 1]]);
  };

  const key = JSON.stringify(filteredEvents);

  return !loading ? (
    <>
      {filteredEvents.length > 0 ? (
        <>
          <div className="h-[500px] w-[100%]">
            <Timeline
              key={key}
              target={
                <div className="custom-timeline timeline h-[100%] w-[100%]" />
              }
              events={filteredEvents}
              title={events.title}
              options={defaultOptions}
            />
          </div>
          <div className="w-[100%] flex justify-between mt-4">
            <Button onClick={() => gotoStart()}>
              {t("components.button.go_to_start")}
            </Button>
            <Button onClick={() => gotoEnd()}>
              {t("components.button.go_to_end")}
            </Button>
          </div>
        </>
      ) : (
        <p>{t("components.statictext.no_events_found")}</p>
      )}
    </>
  ) : (
    <div className="h-[500px] w-[100%]">
      <Loader size={32} />
    </div>
  );
};

export default CaseTimelineWidget;
