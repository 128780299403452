import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { DataTable } from "@/components/ui/data-table";
import { caseReviewAuthApi } from "@/api/auth";
import { User, UserDetail } from "@/api/users";
import JsonDisplay from "../JsonDisplay";
import {
  useUserCasePermissionColumn,
  useUserCaseSourcesPermissionColumn,
  useUserSourcesPermissionColumn,
} from "./hooks";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import Loader from "@/components/ui/loader";
import { useTranslation } from "react-i18next";

export function UserManager({
  open,
  setOpen,
  myUser,
  trigger,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  myUser?: User;
  trigger?: JSX.Element;
}) {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const [loading, setLoading] = useState<boolean>();
  const [is2FAEnabled, set2FAEnabled] = useState<boolean>(false);
  const [is2FAConfirmed, set2FAConfirmed] = useState<boolean>(false);
  const [selectedAccordionItem, selectAccordionItem] =
    useState<string>("editors");
  const [selectedCollapsible, selectCollapsible] = useState<string>();
  const errorHandler = useApiErrorHandler("UserManager", {});

  const [userDetail, setUserDetail] = useState<UserDetail>();

  const check2FAEnabled = async (userId: number) => {
    try {
      const result = await caseReviewAuthApi.check2FAEnabled({
        token: user?.access,
        id: userId.toString(),
      });
      set2FAEnabled(result.data.enabled || false);
      set2FAConfirmed(result.data.confirmed || false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const forceDisable2FA = async (userId: number) => {
    try {
      await caseReviewAuthApi.forceDisable2FA({
        token: user?.access,
        id: userId.toString(),
      });
      await check2FAEnabled(userId);
    } catch (error) {
      errorHandler(error);
    }
  };

  async function fetchUserDetail() {
    try {
      if (myUser) {
        setLoading(true);
        const result = await caseReviewAuthApi.fetchUserDetails({
          userId: myUser.id,
          token: user?.access,
        });
        setUserDetail(result.data);
        check2FAEnabled(result.data.user_profile.id);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  const caseColumns = useUserCasePermissionColumn(myUser, fetchUserDetail);
  const caseSourcesColumn = useUserCaseSourcesPermissionColumn(
    myUser,
    fetchUserDetail,
  );
  const sourcesColumn = useUserSourcesPermissionColumn(myUser, fetchUserDetail);

  useEffect(() => {
    fetchUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser?.id]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="w-full md:min-w-[70%] max-h-screen overflow-y-auto">
        <div className="mb-8">
          <h3 className="text-3xl my-4 text-center">
            {t("users.title.user_detail")}
          </h3>
          <JsonDisplay
            data={{
              // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
              "First Name": userDetail?.user_profile.first_name,
              // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
              "Last Name": userDetail?.user_profile.last_name,
              // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
              "User Name": userDetail?.user_profile.username,
              Email: userDetail?.user_profile.email,
              Groups: userDetail?.user_profile.groups
                .map((item) => item.name)
                .join(","),
            }}
          />
          {is2FAEnabled && !!userDetail?.user_profile.id ? (
            <>
              {!is2FAConfirmed && (
                <p className="text-red-700 my-2">
                  {t("users.statictext.user_doesnt_confirmed_2fa")}
                </p>
              )}
              <Button
                className="mt-2 mb-8"
                variant={"destructive"}
                disabled={loading}
                onClick={() => {
                  forceDisable2FA(userDetail?.user_profile.id);
                }}
              >
                {t("users.button.disable_user_2fa")}
              </Button>
            </>
          ) : (
            <p className="text-red-700 my-2">
              {t("users.statictext.user_doesnt_have_2fa")}
            </p>
          )}
        </div>
        {!!loading && (
          <div className="flex items-center justify-center h-[100%] w-full">
            <Loader />
          </div>
        )}
        <Accordion
          type="single"
          collapsible
          defaultValue="case"
          value={selectedAccordionItem}
          className="flex flex-col gap-8 overflow-y-auto"
        >
          <AccordionItem
            value={"case"}
            key={"case"}
            onClick={() => selectAccordionItem("case")}
          >
            <AccordionTrigger className="bg-primary px-4 text-white">
              {t("users.label.case_permission")}
            </AccordionTrigger>
            <AccordionContent>
              {userDetail?.user_profile.is_staff ||
              userDetail?.user_profile.is_superuser ? (
                <p className="text-center w-full">
                  {t("users.statictext.admins_have_access_to_all_cases")}
                </p>
              ) : (
                <DataTable
                  columns={caseColumns}
                  data={userDetail?.cases || []}
                />
              )}
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value={"all-source"}
            key={"all-source"}
            onClick={() => selectAccordionItem("all-source")}
          >
            <AccordionTrigger className="bg-primary px-4 text-white">
              {t("users.statictext.permissions_to_access_all_sources_per_case")}
            </AccordionTrigger>
            <AccordionContent>
              {userDetail?.user_profile.is_staff ||
              userDetail?.user_profile.is_superuser ? (
                <p className="text-center w-full">
                  {t("users.statictext.admins_have_access_to_all_cases")}
                </p>
              ) : (
                <DataTable
                  columns={caseSourcesColumn}
                  data={userDetail?.cases || []}
                />
              )}
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value={"source"}
            key={"source"}
            onClick={() => selectAccordionItem("source")}
          >
            <AccordionTrigger className="bg-primary px-4 text-white">
              {t("users.statictext.source_permissions")}
            </AccordionTrigger>
            <AccordionContent>
              {userDetail?.user_profile.is_staff ||
              userDetail?.user_profile.is_superuser ? (
                <p className="text-center w-full">
                  {t("users.statictext.admins_have_access_to_all_cases")}
                </p>
              ) : (
                userDetail?.grouped_sources.map((item) => {
                  return (
                    <Collapsible
                      key={item[0].id}
                      className="border w-full mt-8"
                      open={selectedCollapsible === item[0].name}
                    >
                      <CollapsibleTrigger
                        asChild
                        onClick={() => {
                          selectCollapsible(item[0].name);
                        }}
                      >
                        <h3 className="bg-primary text-white p-2 text-xl font-bold">
                          {item[0].name}
                        </h3>
                      </CollapsibleTrigger>
                      <CollapsibleContent className="space-y-2">
                        <div className="w-full p-4">
                          <DataTable
                            columns={sourcesColumn}
                            data={item[1] || []}
                          />
                        </div>
                      </CollapsibleContent>
                    </Collapsible>
                  );
                })
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
}
