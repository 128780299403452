import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { DeletePleading } from "./DeletePleading";
import { Pleading } from "@/api/pleading";
import { usePermission } from "@/hooks/usePermission";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { renderDateForTable } from "@/lib/utils";
import LongDescription from "../LongDescription";
import { useTranslation } from "react-i18next";

export const usePleadingColumn = ({
  refresh,
  setOrder,
  edit,
  info,
}: {
  refresh: () => Promise<void>;
  setOrder: Dispatch<SetStateAction<string>>;
  edit: (item: Pleading) => void;
  info: (item: Pleading) => void;
}) => {
  const { t } = useTranslation();
  const hasEditPermission = usePermission("pleadings.change_pleading");
  const hasDeletePermission = usePermission("pleadings.delete_pleading");

  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<Pleading>[] = [
    {
      accessorKey: "title",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("title");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.title")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "title"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "title"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const pleadingItem = row.original;
        const value = (row.getValue("title") as string) || "--";
        return pleadingItem.file ? (
          <a
            className="text-left text-primary hover:underline cursor-pointer break-all"
            href={pleadingItem.file.toString()}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        ) : (
          <div
            className="text-left text-primary hover:underline cursor-pointer"
            onClick={() => info(pleadingItem)}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessorKey: "party",
      header: () => (
        <div className="text-left">{t("pleadings.label.party")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("party") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "description",
      header: () => (
        <div className="text-left">{t("common.label.description")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("description") as string) || "--";
        return (
          <LongDescription text={value} className="text-left max-w-[400px]" />
        );
      },
    },
    {
      accessorKey: "type",
      header: () => <div className="text-left">{t("common.label.type")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("type") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "response_necessary",
      header: () => (
        <div className="text-left">
          {t("pleadings.label.response_necessary")}
        </div>
      ),
      cell: ({ row }) => {
        const value = row.getValue("response_necessary") as boolean;
        return (
          <div className="text-left">
            {value ? t("common.values.yes") : t("common.values.no")}
          </div>
        );
      },
    },
    {
      accessorKey: "due_date",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("due_date");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("pleadings.label.due_date")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "due_date"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "due_date"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = renderDateForTable(row.getValue("due_date") || "--");
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const pleadingItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasEditPermission && (
              <BsFillPencilFill
                className="hover:text-primary cursor-pointer"
                onClick={() => edit(pleadingItem)}
              />
            )}
            {hasDeletePermission && (
              <DeletePleading
                pleading={pleadingItem}
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
