import React, { useContext } from "react";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ children }: { children: JSX.Element }): JSX.Element {
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default ProtectedRoute;
