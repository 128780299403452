import React, { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useReadApi } from "@/hooks/useReadApi";
import { Source, sourcesApi } from "@/api/source";
import { casesApi } from "@/api/cases";
import SourceNotesTable from "../Notes/SourceNotesTable";
import SourceEventTable from "../Events/SourceEventTable";
import { useEditorPermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

function SourceDetails() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>();
  const [caseSource, setCaseSource] = useState<Source>();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const errorHandler = useApiErrorHandler("SourceDetails", {});
  const { user } = contextValues;
  const navigate = useNavigate();
  const hasEditPermission = useEditorPermission();

  async function loadCaseSourceDetails() {
    try {
      setLoading(true);
      const result = await sourcesApi.read({
        id: id as string,
        token: user?.access,
      });
      setCaseSource(result.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      loadCaseSourceDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reviewCase = useReadApi(casesApi, caseSource?.case?.toString());

  return (
    <>
      <Button
        className="flex gap-2 w-full md:w-auto"
        variant="link"
        onClick={() => {
          if (hasEditPermission) {
            navigate(`/cases/${caseSource?.case}`);
          } else {
            navigate("/sources");
          }
        }}
      >
        <BsArrowLeftCircleFill />{" "}
        {t("sources.button.return_to", {
          caseListText: hasEditPermission
            ? t("sources.button.case_list")
            : t("sources.button.sources"),
        })}
      </Button>
      <div className="w-full my-8 flex flex-col justify-center items-center">
        <h3 className="text-3xl font-bold text-center mb-8">
          {t("sources.title.source_information")}
        </h3>
        {loading && <h3 className="text-xl">{t("common.button.loading")}</h3>}
        <div className="flex flex-col md:flex-row gap-2 md:w-[80%]">
          <div className="w-full flex flex-wrap">
            <div className="w-full md:w-[50%]">
              <p>
                <b>{t("common.label.title")}: </b>
                {caseSource?.title}
              </p>
              <p>
                <b>{t("sources.label.institution")}: </b>
                {caseSource?.institution}
              </p>
              <p>
                <b>{t("common.label.description")}: </b>
                {caseSource?.description}
              </p>
              <p>
                <b>{t("common.label.type")}: </b>
                {caseSource?.type}
              </p>
              {reviewCase?.apiResult && (
                <p>
                  <b>{t("common.label.case")}: </b>
                  {reviewCase?.apiResult?.name}
                </p>
              )}
            </div>
            <div className="w-full md:w-[50%]">
              <p>
                <b>{t("sources.label.start_date")}: </b>
                {caseSource?.start_date}
              </p>
              <p>
                <b>{t("sources.label.end_date")}: </b>
                {caseSource?.end_date}
              </p>
              <p>
                <b>{t("sources.label.total_pages")}: </b>
                {caseSource?.total_pages}
              </p>
            </div>
          </div>
        </div>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3 className="bg-primary text-white p-2 text-xl font-bold">
              {t("common.title.events")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!caseSource?.id && (
              <>
                <div className="w-full p-4">
                  <SourceEventTable source={caseSource} />
                </div>
              </>
            )}
          </CollapsibleContent>
        </Collapsible>

        {hasEditPermission && (
          <Collapsible className="border w-full mt-8" defaultOpen>
            <CollapsibleTrigger asChild>
              <h3 className="bg-primary text-white p-2 text-xl font-bold">
                {t("common.title.notes")}
              </h3>
            </CollapsibleTrigger>

            <CollapsibleContent className="space-y-2">
              {!!caseSource?.id && (
                <div className="w-full p-4">
                  <SourceNotesTable caseSource={caseSource} />
                </div>
              )}
            </CollapsibleContent>
          </Collapsible>
        )}
      </div>
    </>
  );
}

export default SourceDetails;
