import React, { useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { onlyUnique } from "@/lib/utils";
import { useCaseColumn } from "./hooks";
import { Case, CaseCivil, CaseCriminal } from "@/api/cases";
import Loader from "@/components/ui/loader";
import { AddUpdateCivilCase } from "./AddUpdateCivilCase";
import { AddUpdateCriminalCase } from "./AddUpdateCriminalCase";
import { PermissionManager } from "./PermissionManager";
import { InfoRequest } from "../InfoRequest/InfoRequest";
import { useAdminPermission } from "@/hooks/usePermission";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";

function CaseFolderTable({
  loading,
  list,
  loadNext,
  refresh,
  search,
}: {
  loading: boolean;
  list: Case[];
  refresh: () => Promise<void>;
  loadNext: () => void;
  search: {
    value: string;
    set: (value: React.SetStateAction<string>) => void;
  };
}) {
  const { t } = useTranslation();
  const [selectedCivilCase, selectCivilCase] = useState<CaseCivil>();
  const [selectedAccordionItem, selectAccordionItem] = useState<string>(
    t("common.values.unnamed"),
  );
  const [selectedCriminalCase, selectCriminalCase] = useState<CaseCriminal>();
  const [selectedCase, selectCase] = useState<Case>();
  const [openCivilCaseEdit, setCivilCaseOpenEdit] = useState<boolean>(false);
  const [openRequestInfo, setOpenRequestInfo] = useState<boolean>(false);
  const [openPermissionManager, setOpenPermissionManager] =
    useState<boolean>(false);
  const [openCriminalCaseEdit, setCriminalCaseOpenEdit] =
    useState<boolean>(false);
  const hasAdminPermission = useAdminPermission();

  const columns = useCaseColumn({
    refresh,
    editPermissions: (item: CaseCivil) => {
      selectCase(item);
      setOpenPermissionManager(true);
    },
    editCivil: (item: CaseCivil) => {
      selectCivilCase(item);
      setCivilCaseOpenEdit(true);
    },
    editCriminal: (item: CaseCriminal) => {
      selectCriminalCase(item);
      setCriminalCaseOpenEdit(true);
    },
    handleRequestInfo: (item: Case) => {
      selectCase(item);
      setOpenRequestInfo(true);
    },
  });

  const folders = list?.map((item) =>
    item.folder ? item.folder : t("common.values.unnamed"),
  );
  const uniqueFolders = folders.filter(onlyUnique).sort(function (a, b) {
    return ("" + a).localeCompare(b);
  });
  const sortedCases = list.sort(function (a, b) {
    return ("" + a.name).localeCompare(b.name);
  });

  return (
    <div className="w-full my-8">
      {loading ? <Loader /> : ""}
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {hasAdminPermission && (
        <>
          <AddUpdateCivilCase
            open={openCivilCaseEdit}
            setOpen={setCivilCaseOpenEdit}
            reviewCase={selectedCivilCase}
            refresh={refresh}
          />
          <AddUpdateCriminalCase
            open={openCriminalCaseEdit}
            setOpen={setCriminalCaseOpenEdit}
            reviewCase={selectedCriminalCase}
            refresh={refresh}
          />
          <PermissionManager
            open={openPermissionManager}
            setOpen={setOpenPermissionManager}
            reviewCase={selectedCase}
          />
        </>
      )}
      {!!selectedCase && (
        <InfoRequest
          key={selectedCase.id}
          open={openRequestInfo}
          setOpen={setOpenRequestInfo}
          caseId={selectedCase.id.toString()}
        />
      )}
      <Accordion
        type="single"
        collapsible
        defaultValue={t("common.values.unnamed")}
        value={selectedAccordionItem}
        className="flex flex-col gap-8"
      >
        {uniqueFolders.map((item) => {
          const data =
            sortedCases.filter((case_item) =>
              case_item.folder
                ? case_item.folder === item
                : item === t("common.values.unnamed"),
            ) || [];
          return (
            <AccordionItem
              value={item}
              key={item}
              onClick={() => selectAccordionItem(item)}
            >
              <AccordionTrigger className="bg-primary px-4 text-white">
                {item}
                {` (${data.length})`}
              </AccordionTrigger>
              <AccordionContent>
                <DataTable columns={columns} data={data} />
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
      {!uniqueFolders.length ? (
        <p className="mt-8">
          {t("common.statictext.no_results_found_in_search")}
        </p>
      ) : (
        <>
          <p className="mt-8">{t("common.statictext.showing_top_100")}</p>
          <Button onClick={loadNext} variant="link">
            {t("common.button.load_more_results")}
          </Button>
        </>
      )}
    </div>
  );
}

export default CaseFolderTable;
