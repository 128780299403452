import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { useCaseTimerColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import Loader from "@/components/ui/loader";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { CaseTimer, caseTimerApi } from "@/api/caseTimer";
import { AddUpdateCaseTimer } from "./AddUpdateCaseTimer";
import { Button } from "../../ui/button";
import { BsPlusCircleFill } from "react-icons/bs";
import moment from "moment";
import { Case, casesApi } from "@/api/cases";
import { MultiSelect } from "@/components/ui/select";
import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { cn, padZero } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { usePDF } from "react-to-pdf";
import { pdfOptions } from "./pdfOptions";
import { useAdminPermission } from "@/hooks/usePermission";
import { User, userApi } from "@/api/users";
import { Trans, useTranslation } from "react-i18next";

function CaseTimerTable() {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user, timer } = contextValues;

  const [selectedItem, selectItem] = useState<CaseTimer>();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedCases, selectCases] = useState<string[]>([]);
  const [selectedUsers, selectUsers] = useState<string[]>([]);
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: addDays(new Date(), -7),
    to: addDays(new Date(), 1),
  });

  const hasAdminPermission = useAdminPermission();

  const {
    loading: loadingList,
    refresh,
    list,
    order,
  } = useListApi<CaseTimer>({
    baseApiObject: caseTimerApi,
    defaultPageSize: 10,
    defaultParams: {
      user_id: hasAdminPermission
        ? selectedUsers.length > 0
          ? selectedUsers.join(",")
          : user?.user.id
        : user?.user.id,
      case_id: selectedCases.length > 0 ? selectedCases.join(",") : undefined,
      ...(date?.from && {
        start_time: date?.from?.toLocaleDateString("sv"),
      }),
      ...(date?.to && {
        end_time: date?.to?.toLocaleDateString("sv"),
      }),
    },
    defaultOrder: "-end_time",
    disablePagination: true,
    enable: Boolean(user?.user.email),
  });

  useEffect(() => {
    if (timer === undefined) {
      setTimeout(() => {
        refresh();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const columns = useCaseTimerColumn({
    refresh,
    setOrder: order.set,
    edit: (item: CaseTimer) => {
      selectItem(item);
      setOpenEdit(true);
    },
  });

  const totalTime = list.reduce(
    (acc, item) => {
      const duration = moment.duration(
        moment(item.end_time).diff(item.start_time),
      );

      let totalHours = acc.hours + duration.hours();
      let totalMinutes = acc.minutes + duration.minutes();
      let totalSeconds = acc.seconds + duration.seconds();

      if (totalSeconds > 60) {
        totalMinutes += Math.floor(totalSeconds / 60);
        totalSeconds = totalSeconds % 60;
      }

      if (totalMinutes > 60) {
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes = totalMinutes % 60;
      }

      acc = {
        hours: totalHours,
        minutes: totalMinutes,
        seconds: totalSeconds,
      };

      return acc;
    },
    {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  );

  const { list: casesList } = useListApi<Case>({
    baseApiObject: casesApi,
    defaultPageSize: 1000,
    enable: true,
  });

  const { list: usersList } = useListApi<User>({
    baseApiObject: userApi,
    defaultPageSize: 1000,
    enable: true,
  });

  const selectedUserNames = usersList
    .filter((item) => selectedUsers.includes(item.id.toString()))
    .map((item) => item.username)
    .join(", ");

  const selectedCaseNames = casesList
    .filter((item) => selectedCases.includes(item.id.toString()))
    .map((item) => item.name)
    .join(", ");

  const { toPDF, targetRef } = usePDF({
    filename: `time_report_of${selectedUserNames || user?.user.username}_FROM_${date?.from?.toDateString() || ""}_TO_${date?.to?.toDateString() || ""}.pdf`,
  });

  return (
    <div className="w-full my-8">
      <h3 className="text-3xl font-bold text-center mb-8">
        {t("casetimer.title.timer")}
      </h3>
      <div className="flex flex-wrap justify-between items-center gap-4 md:gap-0 my-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 flex-wrap">
            <AddUpdateCaseTimer
              open={openCreate}
              setOpen={setOpenCreate}
              trigger={
                <Button className="flex gap-2">
                  <BsPlusCircleFill /> {t("casetimer.button.add_timer")}
                </Button>
              }
              refresh={refresh}
            />
            <Button onClick={() => toPDF(pdfOptions)} disabled={loadingList}>
              {t("common.button.download_as_pdf")}
            </Button>
            <AddUpdateCaseTimer
              open={openEdit}
              setOpen={setOpenEdit}
              caseTimer={selectedItem}
              refresh={refresh}
            />
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className={cn("grid gap-2")}>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    id="date"
                    variant={"outline"}
                    className={cn(
                      "w-[300px] justify-start text-left font-normal",
                      !date && "text-muted-foreground",
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                      date.to ? (
                        <>
                          {format(date.from, "LLL dd, y")} -{" "}
                          {format(date.to, "LLL dd, y")}
                        </>
                      ) : (
                        format(date.from, "LLL dd, y")
                      )
                    ) : (
                      <span>{t("common.statictext.pick_a_date")}</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className="sm:w-[510px] border-black">
              <MultiSelect
                selected={selectedCases}
                options={casesList.map((item) => ({
                  label: item.name,
                  value: item.id.toString(),
                }))}
                placeholder={t("casetimer.placeholder.select_cases")}
                onChange={selectCases}
              />
            </div>
            {hasAdminPermission && (
              <div className="sm:w-[510px] border-black">
                <MultiSelect
                  selected={selectedUsers}
                  options={usersList.map((item) => ({
                    label: item.username,
                    value: item.id.toString(),
                  }))}
                  placeholder={t("casetimer.placeholder.select_users")}
                  onChange={selectUsers}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {loadingList ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <div
          ref={targetRef}
          className="w-full min-h-[300px] md:min-h-[450px] max-h-auto"
        >
          <div className="mt-16 mb-8 mx-4">
            <h3 className="text-3xl text-center w-full capitalize">
              {t("casetimer.title.timer_report_for", {
                name:
                  selectedUsers.length > 0
                    ? selectedUserNames
                    : user?.user.username,
              })}
            </h3>
            {selectedCaseNames && (
              <p className="text-xl text-center w-full capitalize">
                {t("casetimer.statictext.selected_cases")}
                {": "}
                {selectedCaseNames}
              </p>
            )}
            <div className="text-xl mt-8 mb-4 gap-2 flex justify-center items-center w-full">
              <Trans
                i18nKey="casetimer.statictext.total_time"
                components={{
                  time: (
                    <b>
                      {padZero(totalTime.hours)}:{padZero(totalTime.minutes)}:
                      {padZero(totalTime.seconds)}
                    </b>
                  ),
                }}
                values={{
                  from: date?.from?.toLocaleDateString(),
                  to: date?.to?.toLocaleDateString(),
                }}
              />
            </div>
            <DataTable columns={columns} data={list} />
          </div>
        </div>
      )}
    </div>
  );
}

export default CaseTimerTable;
