import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillFolderSymlinkFill, BsTrashFill } from "react-icons/bs";
import { DeleteDiscovery } from "./DeleteDiscovery";
import { Discovery } from "@/api/discovery";
import { Source } from "@/api/source";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { convertFileUrlToFileName } from "@/lib/utils";
import { usePermission } from "@/hooks/usePermission";
import { CustomToolTip } from "../CustomToolTip";
import { useTranslation } from "react-i18next";

export const useDiscoveryColumn = ({
  refresh,
  setOrder,
  caseId,
  handleAddFromDiscovery,
}: {
  refresh: () => Promise<void>;
  setOrder: Dispatch<SetStateAction<string>>;
  caseId: number;
  handleAddFromDiscovery: (item: Discovery) => void;
}) => {
  const { t } = useTranslation();
  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();
  const hasEditPermission = usePermission("discovery.change_discovery");
  const hasDeletePermission = usePermission("discovery.delete_discovery");

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<Discovery>[] = [
    {
      accessorKey: "file",
      header: () => <div className="text-left">{t("common.label.file")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("file") as string) || "--";
        const fileName = convertFileUrlToFileName(value);
        return (
          <div className="text-left">
            <a
              className="text-primary hover:underline break-all"
              href={value}
              target="_blank"
              rel="noreferrer"
            >
              {fileName}
            </a>
          </div>
        );
      },
    },
    {
      accessorKey: "source",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("source");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.source")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "source"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "source"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("source") as Source) || { title: "--" };
        return <div className="text-left">{value.title}</div>;
      },
    },
    {
      accessorKey: "created_date",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("created_date");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("discovery.label.uploaded_date")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "created_date"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "created_date"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (new Date(row.getValue("created_date")) as Date) || "--";
        return <div className="text-left">{value.toLocaleDateString()}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const discoveryItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasEditPermission && (
              <CustomToolTip
                trigger={
                  <BsFillFolderSymlinkFill
                    className="hover:text-primary cursor-pointer"
                    onClick={() => handleAddFromDiscovery(discoveryItem)}
                  />
                }
                tip={t("discovery.statictext.create_source_from_discovery")}
              />
            )}
            {hasDeletePermission && (
              <DeleteDiscovery
                discovery={discoveryItem}
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                caseId={caseId}
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
