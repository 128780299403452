import React, { useEffect, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";
import { BsPlusCircleFill } from "react-icons/bs";
import { AddUpdatePleading } from "./AddUpdatePleading";
import { usePleadingColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Input } from "@/components/ui/input";
import Loader from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import { Pleading, pleadingApi } from "@/api/pleading";
import { Case } from "@/api/cases";
import { ResourceInfoModal } from "../ResourceInfoModal";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

function PleadingTable({
  reviewCase,
  refreshKey,
}: {
  reviewCase: Case;
  refreshKey?: string;
}) {
  const { t } = useTranslation();
  const { loading, refresh, apiResult, search, pagination, order } =
    useListApi<Pleading>({
      baseApiObject: pleadingApi,
      defaultParams: { case: reviewCase.id },
      defaultOrder: "due_date",
      enable: true,
    });
  const [selectedItem, selectItem] = useState<Pleading>();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const columns = usePleadingColumn({
    refresh,
    setOrder: order.set,
    edit: (item: Pleading) => {
      selectItem(item);
      setOpenEdit(true);
    },
    info: (item: Pleading) => {
      selectItem(item);
      setOpenInfo(true);
    },
  });

  useEffect(() => {
    if (refreshKey) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const hasAddPleadingPermission = usePermission("pleadings.add_pleading");

  return (
    <div className="w-full my-8">
      <div className="flex justify-between items-center">
        {hasAddPleadingPermission && (
          <AddUpdatePleading
            reviewCase={reviewCase}
            open={openCreate}
            setOpen={setOpenCreate}
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("pleadings.button.add")}
              </Button>
            }
            refresh={refresh}
          />
        )}
        <AddUpdatePleading
          reviewCase={reviewCase}
          open={openEdit}
          setOpen={setOpenEdit}
          pleading={selectedItem}
          refresh={refresh}
        />
        <ResourceInfoModal
          open={openInfo}
          setOpen={setOpenInfo}
          title={t("pleadings.title.pleading")}
          mappings={{
            data: [
              {
                label: t("common.label.title"),
                value: selectedItem?.title || "--",
              },
              {
                label: t("pleadings.label.party"),
                value: selectedItem?.party || "--",
              },
              {
                label: t("common.label.description"),
                value: selectedItem?.description || "--",
              },
              {
                label: t("common.label.type"),
                value: selectedItem?.type || "--",
              },
              {
                label: t("pleadings.label.filing_date"),
                value: selectedItem?.filing_date || "--",
              },
              {
                label: t("common.label.file"),
                value: selectedItem?.file?.toString() || "--",
              },
              {
                label: t("pleadings.label.associated_pleading"),
                value: selectedItem?.associated_pleading?.title || "--",
              },
              {
                label: t("pleadings.label.response_necessary"),
                value: selectedItem?.response_necessary.toString() || "--",
              },
              {
                label: t("pleadings.label.due_date"),
                value: selectedItem?.due_date || "--",
              },
              {
                label: t("common.label.case"),
                value: selectedItem?.case.name || "--",
              },
              {
                label: t("common.label.username"),
                value: selectedItem?.user.username || "--",
              },
            ],
          }}
        />
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loading ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PleadingTable;
