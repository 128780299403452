import { BaseApi } from "@/api/index";
import { AxiosResponse } from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface Source {
  id: number;

  case: number;
  title: string;
  institution: string;
  description: string;
  type: string;
  start_date: string | null;
  end_date: string | null;
  total_pages: string | null;
  file: File | string | null;
  requires_text_extraction: boolean;
  extracted_text: string | null;
  extracted_text_per_page: string | null;
  use_bates_numbers: boolean;
  source_starting_bates_number: string;
  corresponding_page_number: string | null;

  file_uploaded_date?: string | null;
  created_date: string;
  updated_date: string | null;
  deleted_at: string | null;
  created_by: number;
  updated_by: number | null;
  deleted_by: number | null;
}

class SourceApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/sources" });
  }

  totalPages({
    token,
    params,
  }: {
    token?: string;
    params: Record<string, unknown>;
  }) {
    return this.request<unknown, AxiosResponse<{ total_pages__sum: number }>>({
      method: "GET",
      url: "/total_pages/",
      token,
      params: params,
    });
  }
}

class LegacySourceApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "sources" });
  }
  downloadExcelList({ token, caseId }: { token?: string; caseId: number }) {
    return this.request({
      method: "get",
      url: `/download_sources_excel/${caseId}/`,
      token,
      responseType: "arraybuffer",
    });
  }
  downloadAllSources({ token, caseId }: { token?: string; caseId: number }) {
    return this.request({
      method: "get",
      url: `/download_sources/${caseId}/`,
      token,
      responseType: "arraybuffer",
    });
  }
}

export const sourcesApi = new SourceApi<Source>();
export const legacySourcesApi = new LegacySourceApi();
