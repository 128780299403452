import React, { createRef, useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { customCasesApi } from "@/api/cases";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

export function AddCustomClientCase({
  trigger,
  refresh,
}: {
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading } = contextValues;
  const { setLoading } = set;
  const captchaRef = createRef<ReCAPTCHA>();

  const FormSchema = z.object({
    your_name: z
      .string()
      .min(1, { message: t("common.statictext.field_required") }),
    your_email: z
      .string()
      .min(1, { message: t("common.statictext.field_required") })
      .email(t("common.statictext.invalid_email")),
    organization: z.string().optional(),
    attorney_name: z
      .string()
      .min(1, { message: t("common.statictext.field_required") }),
    attorney_email: z
      .string()
      .min(1, { message: t("common.statictext.field_required") }),
    client_name: z
      .string()
      .min(1, { message: t("common.statictext.field_required") }),
    case_number: z.string().optional(),
    expedite_request: z.string().optional(),
    case_type: z.string().optional(),
    documents: z.instanceof(FileList).nullable().optional(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      your_name: "",
      your_email: "",
      organization: "",
      attorney_name: "",
      attorney_email: "",
      client_name: "",
      case_number: "",
      expedite_request: "",
      // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
      case_type: "civil case",
      documents: null,
    },
  });

  const errorHandler = useApiErrorHandler("AddCustomClientCase", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      const captchaToken = captchaRef.current?.getValue();
      if (!captchaToken?.trim()) {
        toast({
          title: t("common.toast.recaptcha_required"),
        });
        captchaRef.current?.reset();
        return;
      }
      await customCasesApi.createClientCaseUpload({
        data: {
          ...data,
          ...(data.documents ? { documents: data.documents } : {}),
          captcha_token: captchaToken || "",
        },
      });
      captchaRef.current?.reset();
      toast({
        title: t("common.toast.added_successfully"),
      });
      await refresh?.();
      setOpen(false);
      form.reset();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen} modal={false}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="min-w-[70%] overflow-scroll overflow-x-hidden h-full"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {t("cases.title.create_case")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="your_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("cases.label.name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="your_email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("cases.label.email")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.email")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="organization"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.organization")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.organization")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="attorney_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("cases.label.attorney_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.attorney_name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="attorney_email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("cases.label.attorney_email")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.attorney_email")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="client_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("cases.label.client_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.client_name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="case_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.case_number")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={
                        t("common.statictext.casereview") +
                        " " +
                        t("cases.placeholder.case_number")
                      }
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="case_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.case_type")}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t("cases.placeholder.expedite_request")}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {/* eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized */}
                      <SelectItem value="civil case">
                        {t("cases.value.civil_case")}
                      </SelectItem>
                      {/* eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized */}
                      <SelectItem value="criminal case">
                        {t("cases.value.criminal_case")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    {t("cases.statictext.choose_case_type")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="documents"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t("cases.label.documents")}</FormLabel>
                    <FormControl>
                      <Input
                        type="file"
                        {...field}
                        value={undefined}
                        multiple
                        onChange={(event) =>
                          form.setValue(
                            "documents",
                            event.target.files ? event.target.files : undefined,
                          )
                        }
                      />
                    </FormControl>
                    <FormDescription>
                      {t("cases.statictext.upload_documents")}
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="expedite_request"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.expedite")}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t("cases.placeholder.expedite_request")}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="no">
                        {t("common.values.no")}
                      </SelectItem>
                      <SelectItem value="7_days">
                        {t("cases.value.within_7_days")}
                      </SelectItem>
                      <SelectItem value="14_days">
                        {t("cases.value.within_14_days")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    <p className="mb-4">
                      {t("cases.statictext.pricing_description")}
                    </p>
                    <p>
                      {t("cases.statictext.invoices_will_be_sent_to_emails")}
                    </p>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_PUBLIC_KEY || ""}
              ref={captchaRef}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading
                  ? t("common.button.loading")
                  : t("common.button.create")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
