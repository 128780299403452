import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { Case } from "@/api/cases";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import {
  reviewApi,
  ReviewCaseAddEditCustomTags,
  ReviewCaseAddEditDefaultTags,
  ReviewCaseAddEditTags,
} from "@/api/review";
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";

function AddEditTags({
  trigger,
  reviewCase,
  refresh,
}: {
  reviewCase: Case;
  trigger: JSX.Element;
  refresh: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [caseTags, setTags] = useState<ReviewCaseAddEditTags>();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const errorHandler = useApiErrorHandler("AddEditTags", {});
  const { loading, user } = contextValues;
  const { setLoading } = set;

  async function fetchCaseTags() {
    try {
      const result = await reviewApi.getCaseTags({
        id: reviewCase.id,
        token: user?.access,
      });
      setTags(result.data);
    } catch (error) {
      errorHandler(error);
    }
  }

  useEffect(() => {
    fetchCaseTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCase.id]);

  async function onSubmit() {
    try {
      setLoading?.(true);
      await reviewApi.addEditCaseTags({
        id: reviewCase.id,
        token: user?.access,
        data: {
          tags: [
            ...(caseTags?.default_tags || []),
            ...(caseTags?.custom_tags || []),
          ]
            .filter((item) => item.checked)
            .map((item) => ({
              key: item.id.toString(),
              value: item.name,
            })),
        },
      });
      toast({
        title: t("common.toast.updated_successfully", {
          resource: t("common.label.tag"),
        }),
      });
      await refresh();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  function onCancel() {
    setOpen(false);
  }

  const onCheckboxChange = useCallback(
    (checked: boolean, tagId: number, tagType: "default" | "custom") => {
      if (tagType === "default") {
        let tempTags = Object.assign(
          [],
          caseTags?.default_tags,
        ) as ReviewCaseAddEditDefaultTags[];
        tempTags = tempTags.map((item) => {
          if (item.id === tagId) {
            return {
              ...item,
              checked: checked,
            };
          } else {
            return item;
          }
        });
        setTags({
          case_id: caseTags?.case_id || 0,
          default_tags: tempTags,
          custom_tags: caseTags?.custom_tags || [],
        });
      } else {
        let tempTags = Object.assign(
          [],
          caseTags?.custom_tags,
        ) as ReviewCaseAddEditCustomTags[];
        tempTags = tempTags.map((item) => {
          if (item.id === tagId) {
            return {
              ...item,
              checked: checked,
            };
          } else {
            return item;
          }
        });
        setTags({
          case_id: caseTags?.case_id || 0,
          default_tags: caseTags?.default_tags || [],
          custom_tags: tempTags,
        });
      }
    },
    [caseTags],
  );

  const onTagValueChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      tagId: number,
      tagType: "default" | "custom",
    ) => {
      if (tagType === "default") {
        let tempTags = Object.assign(
          [],
          caseTags?.default_tags,
        ) as ReviewCaseAddEditDefaultTags[];
        tempTags = tempTags.map((item) => {
          if (item.id === tagId) {
            return {
              ...item,
              name: e.target.value,
            };
          } else {
            return item;
          }
        });
        setTags({
          case_id: caseTags?.case_id || 0,
          default_tags: tempTags,
          custom_tags: caseTags?.custom_tags || [],
        });
      } else {
        let tempTags = Object.assign(
          [],
          caseTags?.custom_tags,
        ) as ReviewCaseAddEditCustomTags[];
        tempTags = tempTags.map((item) => {
          if (item.id === tagId) {
            return {
              ...item,
              name: e.target.value,
            };
          } else {
            return item;
          }
        });
        setTags({
          case_id: caseTags?.case_id || 0,
          default_tags: caseTags?.default_tags || [],
          custom_tags: tempTags,
        });
      }
    },
    [caseTags],
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="w-full md:w-[500px] max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("cases.title.add_edit_tags")}</DialogTitle>
          <DialogDescription>
            {t("cases.statictext.add_update_custom_labels_for", {
              name: reviewCase.name,
            })}
          </DialogDescription>
        </DialogHeader>

        <h3 className="text-xl">{t("cases.title.default_tags")}</h3>
        <table>
          {caseTags?.default_tags.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <tr>
              <td>
                <Checkbox
                  checked={item.checked}
                  onCheckedChange={(checked: boolean) =>
                    onCheckboxChange(checked, item.id, "default")
                  }
                />
              </td>
              <td>
                <img
                  className="w-[32px]"
                  alt={item.name}
                  src={`${process.env.REACT_APP_BACKEND_URL}${item.url}`}
                />
              </td>
              <td>
                <Input
                  type="text"
                  disabled
                  value={item.name}
                  className=""
                  onChange={(e) => onTagValueChange(e, item.id, "default")}
                />
              </td>
            </tr>
          ))}
        </table>
        <br />
        <br />
        <h3 className="text-xl">{t("cases.title.custom_tags")}</h3>
        <table>
          {caseTags?.custom_tags.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <tr>
              <td>
                <Checkbox
                  checked={item.checked}
                  onCheckedChange={(checked: boolean) =>
                    onCheckboxChange(checked, item.id, "custom")
                  }
                />
              </td>
              <td>
                <img
                  className="w-[32px]"
                  alt={item.placeholder || item.name}
                  src={`${process.env.REACT_APP_BACKEND_URL}${item.url}`}
                />
              </td>
              <td>
                <Input
                  type="text"
                  value={item.name}
                  placeholder={item.placeholder}
                  onChange={(e) => onTagValueChange(e, item.id, "custom")}
                />
              </td>
            </tr>
          ))}
        </table>

        <DialogFooter>
          <Button variant="secondary" disabled={loading} onClick={onCancel}>
            {loading ? t("common.button.loading") : t("common.button.cancel")}
          </Button>
          <Button variant="default" disabled={loading} onClick={onSubmit}>
            {loading ? t("common.button.loading") : t("common.button.confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default AddEditTags;
