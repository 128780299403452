import React, { useContext, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { caseReviewAuthApi } from "@/api/auth";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import Loader from "../ui/loader";
import { Input } from "../ui/input";
import { useTranslation } from "react-i18next";
import { useToast } from "../ui/use-toast";

function Setup2FA() {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>();
  const [is2FAEnabled, set2FAEnabled] = useState<boolean>(false);
  const [is2FAConfirmed, set2FAConfirmed] = useState<boolean>(false);
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { toast } = useToast();

  const { loading, user } = contextValues;
  const errorHandler = useApiErrorHandler("Setup2FA", {});

  const check2FAEnabled = async () => {
    try {
      const result = await caseReviewAuthApi.check2FAEnabled({
        token: user?.access,
      });
      set2FAEnabled(result.data.enabled || false);
      set2FAConfirmed(result.data.confirmed || false);
      if (result.data.enabled) {
        await fetchQrCode();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const disableMy2FA = async () => {
    try {
      await caseReviewAuthApi.disableMy2FA({
        token: user?.access,
      });
      await check2FAEnabled();
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchQrCode = async () => {
    try {
      const result = await caseReviewAuthApi.fetch2FaQa({
        token: user?.access,
      });
      setQrCode(result.data.qr_code_url);
      set2FAEnabled(true);
    } catch (error) {
      errorHandler(error);
    }
  };

  const verifyQrCode = async () => {
    try {
      const result = await caseReviewAuthApi.verify2FaCode({
        token: user?.access,
        code: verificationCode,
      });
      if (result.data.success) {
        toast({
          title: t("twofactor.statictext.2fa_confirmed"),
        });
        await check2FAEnabled();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    check2FAEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex text-center justify-center w-full items-center">
      {loading && <Loader />}
      <div className="w-full md:w-[50%] flex flex-col text-center justify-center items-center">
        {is2FAEnabled ? (
          <>
            <p className="text-green-700 mb-2 text-xl">
              {t("twofactor.statictext.2fa_enabled")}
            </p>
            <Button
              className="mt-4 mb-8"
              variant={"destructive"}
              disabled={loading}
              onClick={() => {
                disableMy2FA();
              }}
            >
              {t("twofactor.button.disable_2fa")}
            </Button>

            {is2FAConfirmed ? (
              <p className="text-green-700 mb-2 text-l">
                {t("twofactor.statictext.2fa_confirmed")}
              </p>
            ) : (
              <>
                <p className="text-red-700 mb-2 text-l">
                  {t("twofactor.statictext.2fa_not_confirmed")}
                </p>

                <p>{t("twofactor.statictext.scan_qr_code")}</p>
                <img
                  src={qrCode}
                  alt="2fa qr code"
                  className="w-[350px] mt-2"
                />
                <Input
                  placeholder={t("components.placeholder.enter_code")}
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value);
                  }}
                />
                <Button
                  className="mt-4"
                  disabled={!verificationCode || loading}
                  onClick={verifyQrCode}
                >
                  {t("twofactor.button.verify_2fa")}
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <p className="text-red-700 mb-2 text-xl">
              {t("twofactor.statictext.2fa_disabled")}
            </p>
            <p className="mb-4">
              {t("twofactor.statictext.click_enable_to_enable")}
            </p>
            <Button
              onClick={() => {
                fetchQrCode();
              }}
            >
              {t("twofactor.button.enable_2fa")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default Setup2FA;
