import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import { stringify } from "qs";

export interface RequestConfig<T, TP = Record<string, unknown>>
  extends AxiosRequestConfig<T> {
  params?: TP;
  token?: string;
}

// {
//   "count": 4,
//   "next": "http://127.0.0.1:8000/api/cases/?archived=0&page=3&page_size=1",
//   "previous": "http://127.0.0.1:8000/api/cases/?archived=0&page_size=1",
//   "results": [
//   ]
// }
export interface ApiList<T> {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
}

export class BaseApi<T> {
  axios: AxiosInstance;
  listParams: Record<string, unknown>;

  constructor(props: {
    apiBaseUrl: string;
    base?: string;
    listParams?: Record<string, unknown>;
  }) {
    this.axios = axios.create({
      baseURL: `${props.apiBaseUrl}${props.base ? `/${props.base}` : ""}`,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 200 * 60 * 1000,
    });

    this.listParams = props.listParams || {};
  }

  private setAuthHeader(token?: string) {
    if (token) {
      this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    // Un-Comment Below Line for testing auto logout on IP mis-match locally (without nginx reverse proxy)
    // this.axios.defaults.headers.common["x-forwarded-for"] = "11.11.11.12";
  }

  request<RT = T, R = AxiosResponse<RT>>({
    token,
    method,
    url,
    data,
    params,
    headers,
    responseType,
  }: RequestConfig<Partial<RT>>) {
    this.setAuthHeader(token);
    return this.axios.request<RT, R>({
      method,
      url,
      data,
      params,
      headers,
      responseType,
      paramsSerializer: {
        serialize: (parameter: Record<string, unknown>): string => {
          return stringify(parameter, {
            arrayFormat: "comma",
          });
        },
      },
    });
  }

  create<CT = T, R = AxiosResponse<T>>({
    token,
    url,
    data,
    headers,
  }: RequestConfig<Partial<CT>>) {
    this.setAuthHeader(token);
    return this.axios.request<CT, R>({
      method: "post",
      url,
      data,
      headers,
    });
  }

  read({ token, id, params }: RequestConfig<Partial<T>> & { id: string }) {
    this.setAuthHeader(token);
    return this.axios.request<T>({
      method: "get",
      url: id,
      params: {
        ...this.listParams,
        ...params,
      },
      paramsSerializer: {
        serialize: (parameter: Record<string, unknown>): string => {
          return stringify(parameter, {
            arrayFormat: "comma",
          });
        },
      },
    });
  }

  list({ token, url, params }: RequestConfig<Partial<T>>) {
    this.setAuthHeader(token);
    return this.axios.request<T, AxiosResponse<ApiList<T>>>({
      method: "get",
      url,
      params: {
        ...this.listParams,
        ...params,
      },
      paramsSerializer: {
        serialize: (parameter: Record<string, unknown>): string => {
          return stringify(parameter, {
            arrayFormat: "comma",
          });
        },
      },
    });
  }

  update<R = AxiosResponse<T>>({
    token,
    id,
    data,
    headers,
  }: RequestConfig<Partial<T>> & { id: string }) {
    this.setAuthHeader(token);
    return this.axios.request<T, R>({
      method: "patch",
      url: id,
      data,
      headers,
    });
  }

  delete({
    token,
    id,
    data,
    params,
  }: RequestConfig<Partial<T>> & { id: string }) {
    this.setAuthHeader(token);
    return this.axios.request<T>({
      method: "delete",
      url: id,
      data,
      params,
    });
  }
}
