import { type ClassValue, clsx } from "clsx";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";
import * as z from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function onlyUnique(value: unknown, index: number, array: unknown[]) {
  return array.indexOf(value) === index;
}

// get zod object keys recursively
export const zodKeys = <T extends z.ZodTypeAny>(schema: T): string[] => {
  // make sure schema is not null or undefined
  if (schema === null || schema === undefined) return [];
  // check if schema is nullable or optional
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional)
    return zodKeys(schema.unwrap());
  // check if schema is an array
  if (schema instanceof z.ZodArray) return zodKeys(schema.element);
  // check if schema is an object
  if (schema instanceof z.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries(schema.shape);
    // loop through key/value pairs
    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested =
        value instanceof z.ZodType
          ? zodKeys(value).map((subKey) => `${key}.${subKey}`)
          : [];
      // return nested keys
      return nested.length ? nested : key;
    });
  }
  // return empty array
  return [];
};

export function convertFileUrlToFileName(fileUrl: string) {
  let fileName = fileUrl;
  const simpleUrl = fileName.split("?")[0];
  fileName = simpleUrl.split("/").pop() || "--";
  return fileName;
}

export function padZero(value: number) {
  return ("0" + value).slice(-2);
}

export function renderDateString(
  value: string | number,
  input: boolean = false,
) {
  try {
    if (value === "--") return "--";
    let dt = new Date(value);
    if (input) {
      // For Input Component, Don't Change format here.
      dt = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
      return `${format(dt, "yyyy-MM-dd")}T00:00:00.000`;
    }
    return format(dt, "MM/dd/yyyy");
  } catch (error) {
    console.error("renderDateString: ", error);
    return value;
  }
}

export function renderDateForTable(value: string | number) {
  // This will first convert UTC to user timezone adjusted date and then render it in formatted way.
  return renderDateString(renderDateString(value, true));
}
