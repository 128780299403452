import React, { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Case, casesApi } from "@/api/cases";
import { Button } from "@/components/ui/button";
import {
  BsArrowDownCircleFill,
  BsArrowLeftCircleFill,
  BsFillPlusCircleFill,
} from "react-icons/bs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AddUpdateCivilCase } from "./AddUpdateCivilCase";
import { AddUpdateCriminalCase } from "./AddUpdateCriminalCase";
import { AddUpdateCaseSource } from "../Sources/AddUpdateCaseSource";
import { AddUpdateResearchDocument } from "../Research/Documents/AddUpdateResearchDocument";
import DiscoveryTable from "../Discovery/DiscoveryTable";
import PleadingTable from "../Pleadings/PleadingTable";
import CommunicationTable from "../Communications/CommunicationTable";
import NotesTable from "../Notes/NotesTable";
import { useReadApi } from "@/hooks/useReadApi";
import { attorneyContactApi } from "@/api/attorneyContacts";
import { ResearchDocumentTable } from "../Research/Documents/ResearchDocumentTable";
import DeadlineTableForCase from "../Deadlines/DeadlineTableForCase";
import CaseSourceTableForCase from "../Sources/CaseSourceTableForCase";
import CaseEventTable from "../Events/EventTable";
import CaseTimelineWidget from "../CaseTimelineWidget";
import AddEditTags from "./AddEditTags";
import { AddUpdateEvents } from "../Events/AddUpdateEvents";
import { AddUpdateNote } from "../Notes/AddUpdateNote";
import { AddUpdateDeadline } from "../Deadlines/AddUpdateDeadline";
import { AddUpdateCommunication } from "../Communications/AddUpdateCommunication";
import { AddUpdatePleading } from "../Pleadings/AddUpdatePleading";
import ScrollToAnchor from "../ScrollToAnchor";
import { usePermission } from "@/hooks/usePermission";
import { userApi } from "@/api/users";
import { CaseTag, useImageTags } from "@/api/tags";
import { v4 as uuidv4 } from "uuid";
import { renderDateForTable } from "@/lib/utils";
import { useTranslation } from "react-i18next";

function ManageCase() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [openAddResearchDocument, setOpenAddResearchDocument] =
    useState<boolean>(false);
  const [openCreateCaseSource, setOpenCreateCaseSource] =
    useState<boolean>(false);
  const [openCreateCaseEvent, setOpenCreateCaseEvent] =
    useState<boolean>(false);

  const [refreshKey, setRefreshKey] = useState<{
    communication: string;
    note: string;
    researchDocument: string;
    deadline: string;
    source: string;
    event: string;
    pleading: string;
  }>({
    communication: "",
    note: "",
    researchDocument: "",
    deadline: "",
    source: "",
    event: "",
    pleading: "",
  });

  const [openCreateDeadline, setOpenCreateDeadline] = useState<boolean>(false);
  const [openCreatePleading, setOpenCreatePleading] = useState<boolean>(false);
  const [openCreateCommunication, setOpenCreateCommunication] =
    useState<boolean>(false);

  const [openCreateNote, setOpenCreateNote] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [yearFilter, setYearFilter] = useState<string>();

  const [tags, setTags] = useState<string[]>([]);
  const [openCivilCaseEdit, setCivilCaseOpenEdit] = useState<boolean>(false);
  const [openCriminalCaseEdit, setCriminalCaseOpenEdit] =
    useState<boolean>(false);
  const [reviewCase, setReviewCase] = useState<Case>();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const errorHandler = useApiErrorHandler("ManageCase", {});
  const { user } = contextValues;
  const navigate = useNavigate();

  const hasAddSourcePermission = usePermission("review.add_source");
  const hasAddDeadlinePermission = usePermission("review.add_deadline");
  const hasAddResearchPermission = usePermission("research.add_research");
  const hasAddPleadingPermission = usePermission("pleadings.add_pleading");
  const hasAddEventPermission = usePermission("review.add_event");
  const hasAddNotePermission = usePermission("notes.add_note");
  const hasAddCommunicationsPermission = usePermission(
    "communications.add_communication",
  );

  const hasEditCasePermission = usePermission("review.change_case");
  const { list: caseTagsList, refresh: refreshCaseTagsList } = useImageTags(id);
  const usedImageTags = caseTagsList.map((item) => item.name);

  async function loadCaseDetails() {
    try {
      setLoading(true);
      const result = await casesApi.read({
        id: id as string,
        token: user?.access,
      });
      setReviewCase(result.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      loadCaseDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Mimics as promise to match signature with add-update forms.
  const updateRefreshKey = (
    key:
      | "communication"
      | "note"
      | "researchDocument"
      | "deadline"
      | "source"
      | "event"
      | "pleading",
  ) => {
    const newKey = uuidv4();
    setRefreshKey((previousState) => ({ ...previousState, [key]: newKey }));
    return Promise.resolve();
  };

  const attorneyContact = useReadApi(
    attorneyContactApi,
    reviewCase?.attorney_contact?.toString(),
  );

  const internalContact = useReadApi(
    userApi,
    reviewCase?.internal_contact?.toString(),
  );

  function updateTagFilter(tag: CaseTag) {
    const tagsIndex = tags.indexOf(tag.tag.name);
    if (tagsIndex > -1) {
      const tempTags = Object.assign([], tags);
      tempTags.splice(tagsIndex, 1);
      setTags(tempTags);
    } else {
      const tempTags = Object.assign([], tags);
      tempTags.push(tag.tag.name);
      setTags(tempTags);
    }
  }

  return (
    <>
      <ScrollToAnchor />
      <Button
        className="flex gap-2 w-full md:w-auto"
        variant="link"
        onClick={() => navigate("/home")}
      >
        <BsArrowLeftCircleFill /> {t("cases.button.return_to_case_list")}
      </Button>
      <div className="w-full my-8 flex flex-col justify-center items-center">
        {loading && <h3 className="text-xl">{t("common.button.loading")}</h3>}
        <h1 className="w-full text-center text-3xl md:text-4xl mb-12">
          {reviewCase?.name}
        </h1>
        <div className="flex flex-col md:flex-row gap-2 md:w-[80%]">
          <div className="w-full flex flex-wrap">
            <div className="w-full md:w-[50%]">
              <p>
                <b>{t("common.label.name")}: </b>
                {reviewCase?.name}
              </p>
              <p>
                <b>{t("cases.label.case_number")}: </b>
                {reviewCase?.case_number}
              </p>
              <p>
                <b>{t("common.label.court")}: </b>
                {reviewCase?.court}
              </p>
              <p>
                <b>{t("cases.label.overview")}: </b>
                {reviewCase?.overview}
              </p>
            </div>
            <div className="w-full md:w-[50%]">
              {reviewCase?.exact_trial_date && (
                <p>
                  <b>{t("cases.label.exact_trial_date")}: </b>
                  {renderDateForTable(reviewCase.exact_trial_date)}
                </p>
              )}
              {reviewCase?.inexact_trial_date && (
                <p>
                  <b>{t("cases.label.inexact_trial_date")}: </b>
                  {reviewCase.inexact_trial_date}
                </p>
              )}
              {attorneyContact?.apiResult && (
                <p>
                  <b>{t("cases.label.contact")}: </b>
                  {attorneyContact?.apiResult?.first_name}{" "}
                  {attorneyContact?.apiResult?.last_name}
                </p>
              )}
              {internalContact?.apiResult && (
                <p>
                  <b>{t("cases.label.internal_contact")}: </b>
                  {internalContact?.apiResult?.username} (
                  {internalContact?.apiResult?.email})
                </p>
              )}
            </div>
          </div>
          <div className="w-full md:w-[50%] flex flex-row flex-wrap md:flex-col items-center md:items-end gap-2 mt-4 md:mt-0">
            {hasEditCasePermission && (
              <>
                {reviewCase?.is_civil ? (
                  <AddUpdateCivilCase
                    trigger={
                      <Button className="w-auto md:w-[230px]">
                        {t("cases.button.edit_case")}
                      </Button>
                    }
                    reviewCase={reviewCase}
                    refresh={loadCaseDetails}
                    open={openCivilCaseEdit}
                    setOpen={setCivilCaseOpenEdit}
                  />
                ) : (
                  <AddUpdateCriminalCase
                    trigger={
                      <Button className="w-auto md:w-[230px]">
                        {t("cases.button.edit_case")}
                      </Button>
                    }
                    reviewCase={reviewCase}
                    refresh={loadCaseDetails}
                    open={openCriminalCaseEdit}
                    setOpen={setCriminalCaseOpenEdit}
                  />
                )}
              </>
            )}
            {reviewCase?.id && hasAddEventPermission && (
              <AddUpdateEvents
                setOpen={setOpenCreateCaseEvent}
                open={openCreateCaseEvent}
                refresh={() =>
                  updateRefreshKey("event").then(refreshCaseTagsList)
                }
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill /> {t("cases.button.add_event")}
                  </Button>
                }
                defaultCase={reviewCase}
              />
            )}
            {reviewCase?.id && hasAddNotePermission && (
              <AddUpdateNote
                reviewCaseId={reviewCase.id}
                open={openCreateNote}
                setOpen={setOpenCreateNote}
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill /> {t("cases.button.add_note")}
                  </Button>
                }
                refresh={() => updateRefreshKey("note")}
              />
            )}
            {hasAddSourcePermission && (
              <AddUpdateCaseSource
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill /> {t("cases.button.add_source")}
                  </Button>
                }
                defaultCase={reviewCase}
                open={openCreateCaseSource}
                setOpen={setOpenCreateCaseSource}
                refresh={() => updateRefreshKey("source")}
              />
            )}

            {hasAddDeadlinePermission && (
              <AddUpdateDeadline
                open={openCreateDeadline}
                setOpen={setOpenCreateDeadline}
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill /> {t("cases.button.add_deadline")}
                  </Button>
                }
                refresh={() => updateRefreshKey("deadline")}
                defaultCase={reviewCase}
              />
            )}

            {hasAddResearchPermission && (
              <AddUpdateResearchDocument
                open={openAddResearchDocument}
                setOpen={setOpenAddResearchDocument}
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill />{" "}
                    {t("cases.button.add_research_document")}
                  </Button>
                }
                refresh={() => updateRefreshKey("researchDocument")}
                defaultCase={reviewCase?.id}
              />
            )}

            {reviewCase?.id && hasAddCommunicationsPermission && (
              <AddUpdateCommunication
                reviewCase={reviewCase}
                open={openCreateCommunication}
                setOpen={setOpenCreateCommunication}
                refresh={() => updateRefreshKey("communication")}
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill />{" "}
                    {t("cases.button.add_communication")}
                  </Button>
                }
              />
            )}

            {reviewCase?.id && hasAddPleadingPermission && (
              <AddUpdatePleading
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill /> {t("cases.button.add_pleading")}
                  </Button>
                }
                reviewCase={reviewCase}
                open={openCreatePleading}
                setOpen={setOpenCreatePleading}
                refresh={() => updateRefreshKey("pleading")}
              />
            )}

            {reviewCase?.id && (
              <AddEditTags
                refresh={loadCaseDetails}
                trigger={
                  <Button className="w-auto md:w-[230px] flex gap-2">
                    <BsFillPlusCircleFill /> {t("cases.button.add_edit_tags")}
                  </Button>
                }
                reviewCase={reviewCase}
              />
            )}

            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button className="w-auto md:w-[230px] flex gap-2">
                  <span>{t("cases.button.goto")}</span>{" "}
                  <BsArrowDownCircleFill />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-auto md:w-[230px]">
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-events">
                    {t("cases.button.events")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-sources">
                    {t("cases.button.sources")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-deadlines">
                    {t("cases.button.deadlines")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-researches">
                    {t("cases.button.research")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-notes">
                    {t("cases.button.notes")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-communications">
                    {t("cases.button.communications")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-pleadings">
                    {t("cases.button.pleading")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link className="w-full h-full" to="#goto-discoveries">
                    {t("cases.button.discovery")}
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-4 w-full md:w-[80%] justify-center mt-8 px-4">
          {reviewCase?.default_tags.map((item) => (
            <TooltipProvider key={item.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <img
                    onClick={() => updateTagFilter(item)}
                    className={`cursor-pointer w-[3em] ${
                      tags.indexOf(item.tag.name) > -1
                        ? "border-b-4 border-indigo-500"
                        : ""
                    }${
                      usedImageTags.includes(item.tag.name) ? "" : "grey-img"
                    }`}
                    src={item.tag.icon.replace(
                      "http://127.0.0.1:8001",
                      process.env.REACT_APP_BACKEND_URL || "",
                    )}
                    alt={item.name}
                  />
                </TooltipTrigger>
                <TooltipContent>{item.name}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
        <div className="flex flex-row flex-wrap gap-4 w-full md:w-[80%] justify-center mt-4 px-4">
          {reviewCase?.custom_tags.map((item) => (
            <TooltipProvider key={item.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <img
                    onClick={() => updateTagFilter(item)}
                    className={`cursor-pointer w-[3em] ${
                      tags.indexOf(item.tag.name) > -1
                        ? "border-b-4 border-indigo-500"
                        : ""
                    }${
                      usedImageTags.includes(item.tag.name) ? "" : "grey-img"
                    }`}
                    src={item.tag.icon.replace(
                      "http://127.0.0.1:8001",
                      process.env.REACT_APP_BACKEND_URL || "",
                    )}
                    alt={item.name}
                  />
                </TooltipTrigger>
                <TooltipContent>{item.name}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>

        <Collapsible className="border w-full mt-16" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3 className="bg-primary text-white p-2 text-xl font-bold">
              {t("cases.button.timeline")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <>
                <div className="w-full flex justify-end px-4">
                  <Button
                    className="my-4"
                    onClick={() => navigate(`/timelines/case/${reviewCase.id}`)}
                  >
                    {t("cases.button.print_timeline")}
                  </Button>
                </div>
                <div className="w-full p-4">
                  <CaseTimelineWidget
                    caseId={reviewCase.id}
                    tags={tags}
                    year={yearFilter}
                    refreshKey={refreshKey.event}
                  />
                </div>
              </>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-events"
            >
              {t("cases.button.events")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <>
                <div className="w-full p-4">
                  <CaseEventTable
                    reviewCase={reviewCase}
                    tags={tags}
                    yearFilter={yearFilter}
                    setYearFilter={setYearFilter}
                    refreshKey={refreshKey.event}
                    sendParentRefreshSignal={() => {
                      updateRefreshKey("event").then(refreshCaseTagsList);
                      return Promise.resolve();
                    }}
                  />
                </div>
              </>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-sources"
            >
              {t("cases.button.sources")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <>
                <div className="w-full p-4">
                  <CaseSourceTableForCase
                    reviewCase={reviewCase}
                    refreshKey={refreshKey.source}
                  />
                </div>
              </>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-deadlines"
            >
              {t("cases.button.deadlines")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <>
                <div className="w-full p-4">
                  <DeadlineTableForCase
                    reviewCase={reviewCase}
                    refreshKey={refreshKey.deadline}
                  />
                </div>
              </>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-researches"
            >
              {t("cases.button.research")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <>
                <div className="w-full p-4">
                  <ResearchDocumentTable
                    reviewCase={reviewCase}
                    refreshKey={refreshKey.researchDocument}
                  />
                </div>
              </>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-notes"
            >
              {t("cases.button.notes")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <div className="w-full p-4">
                <NotesTable
                  reviewCase={reviewCase}
                  refreshKey={refreshKey.note}
                />
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-communications"
            >
              {t("cases.button.communications")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <div className="w-full p-4">
                <CommunicationTable
                  reviewCase={reviewCase}
                  refreshKey={refreshKey.communication}
                />
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-pleadings"
            >
              {t("cases.button.pleadings")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <div className="w-full p-4">
                <PleadingTable
                  reviewCase={reviewCase}
                  refreshKey={refreshKey.pleading}
                />
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible className="border w-full mt-8" defaultOpen>
          <CollapsibleTrigger asChild>
            <h3
              className="bg-primary text-white p-2 text-xl font-bold"
              id="goto-discoveries"
            >
              {t("cases.button.discovery")}
            </h3>
          </CollapsibleTrigger>

          <CollapsibleContent className="space-y-2">
            {!!reviewCase?.id && (
              <div className="w-full p-4">
                <DiscoveryTable caseId={reviewCase.id} />
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>
      </div>
    </>
  );
}

export default ManageCase;
