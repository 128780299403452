import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ResearchDocument } from "@/api/research";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { Button } from "@/components/ui/button";
import { DeleteResearchDocument } from "./DeleteResearchDocument";
import sanitizeHtml from "sanitize-html";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import LongDescription from "../../LongDescription";
import { useTranslation } from "react-i18next";

export const useResearchDocumentsColumns = ({
  citationType,
  refresh,
  edit,
  info,
  handleMoveItem,
  hideMoveAction = false,
  setOrder,
}: {
  citationType: string;
  refresh: () => Promise<void>;
  edit: (item: ResearchDocument) => void;
  info: (item: ResearchDocument) => void;
  handleMoveItem: (item: ResearchDocument) => void;
  hideMoveAction?: boolean;
  setOrder: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user, isAdmin } = contextValues;

  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<ResearchDocument>[] = [
    {
      accessorKey: "title",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("title");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.title")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "title"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "title"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const researchDocumentItem = row.original;

        const value = (row.getValue("title") as string) || "--";
        return (
          <div
            className="text-left text-primary hover:underline cursor-pointer"
            onClick={() => info(researchDocumentItem)}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessorKey: "description",
      header: () => (
        <div className="text-left">{t("common.label.description")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("description") as string) || "--";
        return (
          <LongDescription text={value} className="text-left max-w-[400px]" />
        );
      },
    },
    {
      accessorKey: "bluebook_citation",
      header: () => (
        <div className="text-left">
          {citationType === "bluebook"
            ? t("research.label.bluebook_citation")
            : t("research.label.apa_citation")}
        </div>
      ),
      cell: ({ row }) => {
        const researchDocumentItem = row.original;

        const citation =
          citationType === "bluebook" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(researchDocumentItem.bluebook_citation),
              }}
            />
          ) : (
            researchDocumentItem.apa_citation
          );

        return <div className="text-left">{citation}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const researchDocumentItem = row.original;
        return (
          <div className="flex gap-2 text-lg items-center">
            {(isAdmin || researchDocumentItem.created_by === user?.user.id) && (
              <BsFillPencilFill
                className="hover:text-black cursor-pointer"
                onClick={() => edit(researchDocumentItem)}
              />
            )}
            {(isAdmin || researchDocumentItem.created_by === user?.user.id) && (
              <DeleteResearchDocument
                trigger={
                  <BsTrashFill className="hover:text-black cursor-pointer" />
                }
                document={researchDocumentItem}
                refresh={refresh}
              />
            )}
            {!hideMoveAction && (
              <Button onClick={() => handleMoveItem(researchDocumentItem)}>
                {t("common.button.move")}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
