import { BaseApi } from "@/api/index";

export interface AttorneyContact {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  created_by?: number;
  updated_by?: number;
  deleted_by?: number;
}

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

class AttorneyContactApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/attorney_contacts/" });
  }
}

export const attorneyContactApi = new AttorneyContactApi<AttorneyContact>();
