import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { Deadline } from "@/api/deadlines";
import { DeleteDeadline } from "./DeleteDeadline";
import { usePermission } from "@/hooks/usePermission";
import { renderDateForTable } from "@/lib/utils";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import LongDescription from "../LongDescription";
import { useTranslation } from "react-i18next";

export const useDeadlineColumn = ({
  refresh,
  edit,
  setOrder,
}: {
  refresh: () => Promise<void>;
  edit: (item: Deadline) => void;
  setOrder: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const hasEditPermission = usePermission("review.change_deadline");
  const hasDeletePermission = usePermission("review.delete_deadline");

  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);
  const columns: ColumnDef<Deadline>[] = [
    {
      accessorKey: "name",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("name");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.name")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "name"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "name"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("name") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "date",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("date");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.date")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "date"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "date"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = renderDateForTable(row.getValue("date") || "--");
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "notes",
      header: () => <div className="text-left">{t("common.label.notes")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("notes") as string) || "--";
        return (
          <LongDescription text={value} className="text-left max-w-[400px]" />
        );
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const deadlineItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {hasEditPermission && (
              <BsFillPencilFill
                className="hover:text-primary cursor-pointer"
                onClick={() => edit(deadlineItem)}
              />
            )}
            {hasDeletePermission && (
              <DeleteDeadline
                deadline={deadlineItem}
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
