import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CaseTimer } from "@/api/caseTimer";
import { DeleteCaseTimer } from "./DeleteCaseTimer";
import moment from "moment";
import { useAdminPermission } from "@/hooks/usePermission";
import { padZero } from "@/lib/utils";
import { useTranslation } from "react-i18next";

export const useCaseTimerColumn = ({
  refresh,
  setOrder,
  edit,
}: {
  refresh: () => Promise<void>;
  setOrder: Dispatch<SetStateAction<string>>;
  edit: (item: CaseTimer) => void;
}) => {
  const { t } = useTranslation();
  const [descendingDate, setDescendingDate] = useState<boolean>(true);

  const getSortedValues = useCallback(() => {
    return [!descendingDate ? "end_time" : "-end_time"].join(",");
  }, [descendingDate]);

  const ordering = getSortedValues();
  const hasAdminPermission = useAdminPermission();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<CaseTimer>[] = [
    {
      accessorKey: "description",
      header: () => (
        <div className="text-left">{t("common.label.description")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("description") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "start_time",
      header: () => (
        <div className="text-left">{t("casetimer.label.start_time")}</div>
      ),
      cell: ({ row }) => {
        const value = (new Date(row.getValue("start_time")) as Date) || "--";
        return <div className="text-left">{value.toLocaleString()}</div>;
      },
    },
    {
      accessorKey: "end_time",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => setDescendingDate(!descendingDate)}
        >
          {t("casetimer.label.end_time")}
          {""}
          <div className="text-xs">
            <IoIosArrowUp color={descendingDate ? undefined : "#000000"} />
            <IoIosArrowDown color={!descendingDate ? undefined : "#000000"} />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (new Date(row.getValue("end_time")) as Date) || "--";
        return <div className="text-left">{value.toLocaleString()}</div>;
      },
    },
    {
      accessorKey: "id",
      header: () => (
        <div className="text-left">{t("casetimer.label.total_time")}</div>
      ),
      cell: ({ row }) => {
        const caseTimerItem = row.original;
        const duration = moment.duration(
          moment(caseTimerItem.end_time).diff(caseTimerItem.start_time),
        );
        return (
          <div className="text-left">
            {padZero(duration.hours())}:{padZero(duration.minutes())}:
            {padZero(duration.seconds())}
          </div>
        );
      },
    },
    {
      accessorKey: "case.name",
      header: () => <div className="text-left">{t("common.label.case")}</div>,
      cell: ({ row }) => {
        const caseTimerItem = row.original;
        return <div className="text-left">{caseTimerItem.case.name}</div>;
      },
    },
  ];

  if (hasAdminPermission) {
    columns.push({
      accessorKey: "user.username",
      header: () => (
        <div className="text-left">{t("common.label.username")}</div>
      ),
      cell: ({ row }) => {
        const caseTimerItem = row.original;
        return <div className="text-left">{caseTimerItem.user.username}</div>;
      },
    });
  }

  columns.push({
    accessorKey: "Actions",
    header: () => <div className="text-left">{t("common.label.commands")}</div>,
    cell: ({ row }) => {
      const caseTimerItem = row.original;
      return (
        <div className="flex gap-4 text-lg">
          <BsFillPencilFill
            className="hover:text-primary cursor-pointer"
            onClick={() => edit(caseTimerItem)}
          />
          <DeleteCaseTimer
            caseTimer={caseTimerItem}
            trigger={
              <BsTrashFill className="hover:text-primary cursor-pointer" />
            }
            refresh={refresh}
          />
        </div>
      );
    },
  });

  return columns;
};
