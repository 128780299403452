import React, { useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { useArchivedCaseColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Case, CaseCivil, CaseCriminal, casesApi } from "@/api/cases";
import { Input } from "@/components/ui/input";
import Loader from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import { AddUpdateCivilCase } from "./AddUpdateCivilCase";
import { AddUpdateCriminalCase } from "./AddUpdateCriminalCase";
import { InfoRequest } from "../InfoRequest/InfoRequest";
import { useAdminPermission } from "@/hooks/usePermission";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

function ArchivedCasesTable() {
  const { t } = useTranslation();
  const [selectedCivilCase, selectCivilCase] = useState<CaseCivil>();
  const [selectedCriminalCase, selectCriminalCase] = useState<CaseCriminal>();
  const [openCivilCaseEdit, setCivilCaseOpenEdit] = useState<boolean>(false);
  const [openCriminalCaseEdit, setCriminalCaseOpenEdit] =
    useState<boolean>(false);
  const [openRequestInfo, setOpenRequestInfo] = useState<boolean>(false);
  const [selectedCase, selectCase] = useState<Case>();
  const hasAdminPermission = useAdminPermission();

  const { loading, apiResult, pagination, search, refresh, order } =
    useListApi<Case>({
      baseApiObject: casesApi,
      defaultPageSize: 10,
      defaultParams: {
        archived: 1,
      },
      enable: true,
      defaultOrder: "name",
    });

  const columns = useArchivedCaseColumn({
    refresh,
    setOrder: order.set,
    editCivil: (item: CaseCivil) => {
      selectCivilCase(item);
      setCivilCaseOpenEdit(true);
    },
    editCriminal: (item: CaseCriminal) => {
      selectCriminalCase(item);
      setCriminalCaseOpenEdit(true);
    },
    handleRequestInfo: (item: Case) => {
      selectCase(item);
      setOpenRequestInfo(true);
    },
  });

  return (
    <div className="w-full my-8">
      <h3 className="text-3xl font-bold text-center mb-8">
        {t("cases.title.archived_cases")}
      </h3>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
        {hasAdminPermission && (
          <>
            <AddUpdateCivilCase
              open={openCivilCaseEdit}
              setOpen={setCivilCaseOpenEdit}
              reviewCase={selectedCivilCase}
              refresh={refresh}
            />
            <AddUpdateCriminalCase
              open={openCriminalCaseEdit}
              setOpen={setCriminalCaseOpenEdit}
              reviewCase={selectedCriminalCase}
              refresh={refresh}
            />
          </>
        )}
        {!!selectedCase && (
          <InfoRequest
            key={selectedCase.id}
            open={openRequestInfo}
            setOpen={setOpenRequestInfo}
            caseId={selectedCase.id.toString()}
          />
        )}
      </div>
      {loading ? (
        <div className="flex min-h-[300px] md:min-h-[450px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[450px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ArchivedCasesTable;
