import { BaseApi } from "@/api/index";
import { Case } from "./cases";
import { Source } from "./source";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface Discovery {
  id: number;

  case: Case;
  source: Source | null;
  file: string;
  case_id?: number;

  created_date: string;
  updated_date: string | null;
  deleted_at: string | null;
  created_by: number;
  updated_by: number | null;
  deleted_by: number | null;
}

class DiscoveryApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/discovery" });
  }
}

export const discoveryApi = new DiscoveryApi<Discovery>();
