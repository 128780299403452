import React, { useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";
import { BsPlusCircleFill } from "react-icons/bs";
import { AddUpdateNote } from "./AddUpdateNote";
import { useNotesColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Input } from "@/components/ui/input";
import Loader from "@/components/ui/loader";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import { Note, notesApi } from "@/api/notes";
import { Source } from "@/api/source";
import { ResourceInfoModal } from "../ResourceInfoModal";
import { convertFileUrlToFileName } from "@/lib/utils";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

function SourceNotesTable({ caseSource }: { caseSource: Source }) {
  const { t } = useTranslation();
  const { loading, refresh, apiResult, search, pagination, order } =
    useListApi<Note>({
      baseApiObject: notesApi,
      defaultParams: { source: caseSource.id },
      enable: true,
    });
  const [selectedItem, selectItem] = useState<Note>();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const hasAddNotePermission = usePermission("notes.add_note");

  const columns = useNotesColumn({
    refresh,
    setOrder: order.set,
    edit: (item: Note) => {
      selectItem(item);
      setOpenEdit(true);
    },
    info: (item: Note) => {
      selectItem(item);
      setOpenInfo(true);
    },
  });

  return (
    <div className="w-full my-8">
      <div className="flex justify-between items-center">
        {hasAddNotePermission && (
          <AddUpdateNote
            reviewCaseId={caseSource.case}
            open={openCreate}
            setOpen={setOpenCreate}
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("notes.button.add")}
              </Button>
            }
            refresh={refresh}
            defaultSourceId={caseSource.id}
          />
        )}
        <AddUpdateNote
          reviewCaseId={caseSource.case}
          open={openEdit}
          setOpen={setOpenEdit}
          note={selectedItem}
          refresh={refresh}
          defaultSourceId={caseSource.id}
        />
        <ResourceInfoModal
          open={openInfo}
          setOpen={setOpenInfo}
          title={t("notes.title.note")}
          mappings={{
            data: [
              {
                label: t("common.label.title"),
                value: selectedItem?.title || "--",
              },
              {
                label: t("common.label.author"),
                value: selectedItem?.author || "--",
              },
              {
                label: t("common.label.date"),
                value: selectedItem?.date || "--",
              },
              {
                label: t("common.label.description"),
                value: selectedItem?.description || "--",
              },
              {
                label: t("common.label.file"),
                value: selectedItem?.file ? (
                  <a
                    href={selectedItem.file.toString()}
                    target="_blank"
                    rel="noreferrer"
                    className="text-left text-primary hover:underline cursor-pointer break-all"
                  >
                    {convertFileUrlToFileName(selectedItem.file.toString())}
                  </a>
                ) : (
                  "--"
                ),
              },
              {
                label: t("common.label.source"),
                value: selectedItem?.source.title || "--",
              },
              {
                label: t("common.label.case"),
                value: selectedItem?.case.name.toString() || "--",
              },
              {
                label: t("common.label.source_file"),
                value: selectedItem?.source.file ? (
                  <a
                    href={selectedItem?.source.file.toString()}
                    target="_blank"
                    className="text-left text-primary hover:underline cursor-pointer break-all"
                    rel="noreferrer"
                  >
                    {convertFileUrlToFileName(
                      selectedItem.source.file.toString(),
                    )}
                  </a>
                ) : (
                  "--"
                ),
              },
              {
                label: t("common.label.start_page"),
                value: selectedItem?.starting_page || "--",
              },
              {
                label: t("common.label.end_page"),
                value: selectedItem?.ending_page || "--",
              },
              {
                label: t("common.label.start_bates_number"),
                value: selectedItem?.starting_bates_number || "--",
              },
              {
                label: t("common.label.end_bates_number"),
                value: selectedItem?.ending_bates_number || "--",
              },
            ],
          }}
        />
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={apiResult?.results || []} />
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
              <CustomPageSelector
                pageSize={pagination.pageSize}
                onPageSizeChange={(value) => {
                  pagination.setPageSize(value);
                  pagination.setPage(1);
                }}
              />
              <CustomPaginationInfo
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
              />
            </div>
            <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
              <CustomPagination
                totalCount={apiResult?.count || 0}
                pageSize={pagination.pageSize}
                currentPage={pagination.page}
                onPageChange={(value) => pagination.setPage(value)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SourceNotesTable;
