import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CaseWithAllowance,
  SourceWithAllowance,
  User,
  UserGroup,
} from "@/api/users";
import { ColumnDef } from "@tanstack/react-table";
import {
  BsAsterisk,
  BsFillPencilFill,
  BsPlus,
  BsTrashFill,
  BsX,
} from "react-icons/bs";
import { DeleteUser } from "./DeleteUser";
import { SetPassword } from "./SetPassword";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { reviewApi } from "@/api/review";
import { Button } from "@/components/ui/button";
import { convertFileUrlToFileName } from "@/lib/utils";
import { CustomToolTip } from "../CustomToolTip";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { toast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";

export const useUserColumn = ({
  refresh,
  setOrder,
  edit,
  manage,
}: {
  refresh: () => Promise<void>;
  setOrder: Dispatch<SetStateAction<string>>;
  edit: (item: User) => void;
  manage: (item: User) => void;
}) => {
  const { t } = useTranslation();
  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<User>[] = [
    {
      accessorKey: "username",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("username");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.username")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "username"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "username"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const userObject = row.original;
        const value = (row.getValue("username") as string) || "--";
        return (
          <div
            className="text-left text-primary hover:underline cursor-pointer"
            onClick={() => manage(userObject)}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessorKey: "last_name",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("last_name");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.last_name")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "last_name"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "last_name"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("last_name") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "first_name",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("first_name");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.first_name")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "first_name"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "first_name"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("first_name") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "email",
      header: () => <div className="text-left">{t("common.label.email")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("email") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "groups",
      header: () => <div className="text-left">{t("users.label.group")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("groups") as UserGroup[])[0]?.name || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const userObject = row.original;
        return (
          <div className="flex gap-4 text-lg">
            <BsFillPencilFill
              className="hover:text-primary cursor-pointer"
              onClick={() => edit(userObject)}
            />
            <CustomToolTip
              tip={t("users.statictext.set_password_for_user")}
              trigger={
                <SetPassword
                  trigger={
                    <BsAsterisk className="hover:text-primary cursor-pointer" />
                  }
                  refresh={refresh}
                  userObject={userObject}
                />
              }
            />
            <DeleteUser
              trigger={
                <BsTrashFill className="hover:text-primary cursor-pointer" />
              }
              userObject={userObject}
              refresh={refresh}
            />
          </div>
        );
      },
    },
  ];

  return columns;
};

export const useUserCasePermissionColumn = (
  user?: User,
  refresh?: () => Promise<void>,
) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user: globalUser } = contextValues;

  const errorHandler = useApiErrorHandler("useUserCasePermissionColumn", {});

  const updatingToastMessage = t("common.toast.updating");
  const updateSucceededToastMessage = t("common.toast.permissions_updated");
  const updateFailedToastMessage = t("common.toast.permissions_update_failed");

  async function updateCasePermission(
    caseId: number,
    operation: "add" | "remove",
    userId?: number,
  ) {
    try {
      if (userId) {
        toast({
          title: updatingToastMessage,
        });
        await reviewApi.updatePermissions({
          id: caseId,
          token: globalUser?.access,
          data: {
            user_id: userId,
            operation: operation,
            model: "case",
            permission: "access_case",
          },
        });
        await refresh?.();
        toast({
          title: updateSucceededToastMessage,
        });
      }
    } catch (error) {
      errorHandler(error);
      toast({
        title: updateFailedToastMessage,
      });
    }
  }

  const columns: ColumnDef<CaseWithAllowance>[] = [
    {
      accessorKey: "name",
      header: () => <div className="text-left">{t("common.label.name")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("name") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "id",
      header: () => (
        <div className="text-left">{t("users.label.access_type")}</div>
      ),
      cell: ({ row }) => {
        const reviewCase = row.original;
        return (
          <div className="text-left">
            {reviewCase.allowed ? (
              <Button
                variant="link"
                className="p-0 m-0 text-green-600"
                onClick={() =>
                  updateCasePermission(reviewCase.id, "remove", user?.id)
                }
              >
                <BsX className="text-3xl" /> {t("common.values.allowed")}
              </Button>
            ) : (
              <Button
                variant="link"
                className="p-0 m-0 text-red-600"
                onClick={() =>
                  updateCasePermission(reviewCase.id, "add", user?.id)
                }
              >
                <BsPlus className="text-3xl" /> {t("common.values.not_allowed")}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "court",
      header: () => <div className="text-left">{t("common.label.court")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("court") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "exact_trial_date",
      header: () => (
        <div className="text-left">{t("cases.label.exact_trial_date")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("exact_trial_date") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
  ];

  return columns;
};

export const useUserCaseSourcesPermissionColumn = (
  user?: User,
  refresh?: () => Promise<void>,
) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user: globalUser } = contextValues;

  const updatingToastMessage = t("common.toast.updating");
  const updateSucceededToastMessage = t("common.toast.permissions_updated");
  const updateFailedToastMessage = t("common.toast.permissions_update_failed");

  const errorHandler = useApiErrorHandler(
    "useUserCaseSourcesPermissionColumn",
    {},
  );

  async function updateCasePermission(
    caseId: number,
    operation: "add" | "remove",
    userId?: number,
  ) {
    try {
      if (userId) {
        toast({
          title: updatingToastMessage,
        });
        await reviewApi.updateCaseSourcesPermissions({
          id: caseId,
          token: globalUser?.access,
          data: {
            user_id: userId,
            operation: operation,
          },
        });
        await refresh?.();
        toast({
          title: updateSucceededToastMessage,
        });
      }
    } catch (error) {
      errorHandler(error);
      toast({
        title: updateFailedToastMessage,
      });
    }
  }

  const columns: ColumnDef<CaseWithAllowance>[] = [
    {
      accessorKey: "name",
      header: () => <div className="text-left">{t("common.label.name")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("name") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "id",
      header: () => (
        <div className="text-left">{t("users.label.access_type")}</div>
      ),
      cell: ({ row }) => {
        const reviewCase = row.original;
        return (
          <div className="text-left">
            {reviewCase.allowed ? (
              <Button
                variant="link"
                className="p-0 m-0 text-green-600"
                onClick={() =>
                  updateCasePermission(reviewCase.id, "remove", user?.id)
                }
              >
                <BsX className="text-3xl" /> {t("common.values.allowed")}
              </Button>
            ) : (
              <Button
                variant="link"
                className="p-0 m-0 text-red-600"
                onClick={() =>
                  updateCasePermission(reviewCase.id, "add", user?.id)
                }
              >
                <BsPlus className="text-3xl" /> {t("common.values.not_allowed")}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};

export const useUserSourcesPermissionColumn = (
  user?: User,
  refresh?: () => Promise<void>,
) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user: globalUser } = contextValues;

  const updatingToastMessage = t("common.toast.updating");
  const updateSucceededToastMessage = t("common.toast.permissions_updated");
  const updateFailedToastMessage = t("common.toast.permissions_update_failed");

  const errorHandler = useApiErrorHandler("useUserSourcesPermissionColumn", {});

  async function updateCasePermission(
    sourceId: number,
    operation: "add" | "remove",
    userId?: number,
  ) {
    try {
      if (userId) {
        toast({
          title: updatingToastMessage,
        });
        await reviewApi.updatePermissions({
          id: sourceId,
          token: globalUser?.access,
          data: {
            user_id: userId,
            operation: operation,
            model: "source",
            permission: "access_source",
          },
        });
        await refresh?.();
        toast({
          title: updateSucceededToastMessage,
        });
      }
    } catch (error) {
      errorHandler(error);
      toast({
        title: updateFailedToastMessage,
      });
    }
  }

  const columns: ColumnDef<SourceWithAllowance>[] = [
    {
      accessorKey: "title",
      header: () => <div className="text-left">{t("common.label.title")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("title") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "id",
      header: () => (
        <div className="text-left">{t("users.label.access_type")}</div>
      ),
      cell: ({ row }) => {
        const source = row.original;
        return (
          <div className="text-left">
            {source.allowed ? (
              <Button
                variant="link"
                className="p-0 m-0 text-green-600"
                onClick={() =>
                  updateCasePermission(source.id, "remove", user?.id)
                }
              >
                <BsX className="text-3xl" /> {t("common.values.allowed")}
              </Button>
            ) : (
              <Button
                variant="link"
                className="p-0 m-0 text-red-600"
                onClick={() => updateCasePermission(source.id, "add", user?.id)}
              >
                <BsPlus className="text-3xl" /> {t("common.values.not_allowed")}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "file",
      header: () => <div className="text-left">{t("common.label.file")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("file") as string) || "--";
        const fileName = convertFileUrlToFileName(value);
        return (
          <div className="text-left">
            {value !== "--" ? (
              <a className="text-primary underline" href={value}>
                {fileName}
              </a>
            ) : (
              value
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
