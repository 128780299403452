import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError, useWatch } from "react-hook-form";
import * as z from "zod";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import {
  cn,
  convertFileUrlToFileName,
  renderDateString,
  zodKeys,
} from "@/lib/utils";
import { Source, sourcesApi } from "@/api/source";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { FaPaperclip } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { MultiSelect } from "@/components/ui/select";
import { useListApi } from "@/hooks/useListApi";
import { Case, casesApi } from "@/api/cases";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { User, userApi } from "@/api/users";
import { CustomToolTip } from "../CustomToolTip";
import { useTranslation } from "react-i18next";

export function AddUpdateCaseSource({
  caseSource,
  trigger,
  defaultCase,
  refresh,
  open,
  setOpen,
}: {
  caseSource?: Source;
  trigger?: JSX.Element;
  defaultCase?: Case;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();

  const FormSchema = z.object({
    title: z.string().min(2, {
      message: t("sources.statictext.title_too_short"),
    }),
    case: z.string().min(1, {
      message: t("sources.statictext.select_a_case"),
    }),
    institution: z.string().optional(),
    description: z.string().optional(),
    type: z.string().optional(),
    start_date: z.date().nullable().optional(),
    end_date: z.date().nullable().optional(),
    total_pages: z.string().nullable().optional(),
    file: z.instanceof(File).or(z.string()),
    requires_text_extraction: z.boolean().optional().default(false),
    extracted_text: z.string().nullable().optional(),
    extracted_text_per_page: z.string().nullable().optional(),
    use_bates_numbers: z.boolean().optional().default(false),
    source_starting_bates_number: z.string().optional(),
    corresponding_page_number: z.string().nullable().optional(),

    // Only required when creating source.
    send_notification_emails: z.boolean().nullable().optional().default(false),
    notification_recipients: z.string().array().nullable().optional(),
  });

  const RefinedFormSchema = FormSchema.refine(
    (data) => {
      if (data.start_date && data.end_date) {
        return (
          data.end_date?.setHours(0, 0, 0, 0) >=
          data.start_date?.setHours(0, 0, 0, 0)
        );
      }
      return true;
    },
    {
      message: t("sources.statictext.start_date_must_be_earlier"),
      path: ["start_date"],
    },
  );

  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const { loading: usersLoading, list: usersList } = useListApi<User>({
    baseApiObject: userApi,
    defaultPageSize: 1000,
    enable: open,
  });

  const {
    loading: casesLoading,
    search: caseSearch,
    loadNext: loadNextCase,
    list: casesList,

    apiResult: casesResult,
    pagination,
  } = useListApi<Case>({
    baseApiObject: casesApi,
    enable: open,
    additionalListIds: [caseSource?.case.toString()],
  });

  const form = useForm<z.infer<typeof RefinedFormSchema>>({
    resolver: zodResolver(RefinedFormSchema),
    defaultValues: {
      title: "",
      case: "",
      institution: "",
      description: "",
      type: "",
      start_date: null,
      end_date: null,
      total_pages: "",
      file: new File([], ""),
      requires_text_extraction: false,
      extracted_text: "",
      extracted_text_per_page: "",
      use_bates_numbers: false,
      source_starting_bates_number: "",
      corresponding_page_number: "",
    },
  });

  const send_notification_emails = useWatch({
    name: "send_notification_emails",
    control: form.control,
  });

  const errorHandler = useApiErrorHandler("AddUpdateCaseSource", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof RefinedFormSchema>) {
    try {
      setLoading?.(true);
      if (caseSource && caseSource.id) {
        await sourcesApi.update({
          id: caseSource.id.toString() + "/",
          headers: {
            "content-type": "multipart/form-data",
          },
          data: {
            title: data.title,
            case: parseInt(data.case),
            institution: data.institution,
            description: data.description,
            type: data.type,
            total_pages: data.total_pages,
            requires_text_extraction: data.requires_text_extraction,
            extracted_text: data.extracted_text,
            extracted_text_per_page: data.extracted_text_per_page,
            use_bates_numbers: data.use_bates_numbers,
            source_starting_bates_number: data.source_starting_bates_number,
            corresponding_page_number: data.corresponding_page_number,
            ...(typeof data.file === "string" ? {} : { file: data.file }),
            start_date: data.start_date?.toLocaleDateString("sv"),
            end_date: data.end_date?.toLocaleDateString("sv"),
            updated_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.updated_successfully", {
            resource: t("sources.title.source"),
          }),
        });
      } else {
        await sourcesApi.create({
          url: "/",
          headers: {
            "content-type": "multipart/form-data",
          },
          data: {
            title: data.title,
            case: parseInt(data.case),
            institution: data.institution,
            description: data.description,
            type: data.type,
            total_pages: data.total_pages,
            requires_text_extraction: data.requires_text_extraction,
            extracted_text: data.extracted_text,
            extracted_text_per_page: data.extracted_text_per_page,
            use_bates_numbers: data.use_bates_numbers,
            source_starting_bates_number: data.source_starting_bates_number,
            corresponding_page_number: data.corresponding_page_number,
            ...(typeof data.file === "string" ? {} : { file: data.file }),
            start_date: data.start_date?.toLocaleDateString("sv"),
            end_date: data.end_date?.toLocaleDateString("sv"),
            send_notification_emails: data.send_notification_emails
              ? "on"
              : "off",
            notification_recipients:
              data.notification_recipients?.join(",") || "",
            created_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.added_successfully", {
            resource: t("sources.title.source"),
          }),
        });
        form.reset();
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (caseSource) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...caseSource,
            start_date: caseSource.start_date
              ? new Date(renderDateString(caseSource.start_date, true))
              : caseSource.start_date,
            end_date: caseSource.end_date
              ? new Date(renderDateString(caseSource.end_date, true))
              : caseSource.end_date,
            case: caseSource.case.toString(),
            send_notification_emails: false,
            notification_recipients: [],
            total_pages: caseSource.total_pages?.toString(),
            file: caseSource.file === null ? new File([], "") : caseSource.file,
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseSource]);

  useEffect(() => {
    if (defaultCase && open) {
      form.setValue("case", defaultCase.id.toString(), { shouldTouch: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCase, open]);

  const buttonAction = caseSource?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="w-full md:w-[500px] overflow-scroll overflow-x-hidden h-full">
        <Form {...form}>
          <form
            id="case-source-form"
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("sources.title.source")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.title")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.title")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="institution"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("sources.label.institution")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("sources.placeholder.institution")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.description")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("common.placeholder.description")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.type")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("sources.placeholder.type")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="start_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>{t("sources.label.start_date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("sources.statictext.start_date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("start_date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="end_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>{t("sources.label.end_date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("sources.statictext.end_date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("end_date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="total_pages"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("sources.label.total_pages")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("sources.placeholder.total_pages")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => {
                let fileName = null;
                if (typeof field.value === "string") {
                  fileName = convertFileUrlToFileName(field.value);
                }
                return (
                  <FormItem>
                    <FormLabel>{t("common.label.file")}</FormLabel>
                    {fileName && (
                      <>
                        <div className="flex gap-2 items-center">
                          <FaPaperclip color="skyblue" />
                          <a
                            href={field.value as string}
                            target="_blank"
                            rel="noreferrer"
                            className="underline text-primary break-all w-[80%] break-all"
                          >
                            {fileName}
                          </a>
                          <CustomToolTip
                            tip="Remove"
                            trigger={
                              <ImCross
                                className="cursor-pointer"
                                onClick={() => {
                                  form.setValue("file", new File([], ""));
                                }}
                                color="red"
                              />
                            }
                          />
                        </div>
                        {!!caseSource?.file_uploaded_date && (
                          <p className="pl-6">
                            {t("common.label.uploaded_at")}
                            {": "}
                            <i>
                              {renderDateString(caseSource.file_uploaded_date)}
                            </i>
                          </p>
                        )}
                      </>
                    )}
                    <FormControl>
                      <Input
                        type="file"
                        {...field}
                        value={undefined}
                        onChange={(event) =>
                          form.setValue(
                            "file",
                            event.target.files
                              ? event.target.files[0]
                              : new File([], ""),
                          )
                        }
                      />
                    </FormControl>
                    <FormDescription>
                      {t("common.statictext.upload_files")}
                      <p className="text-sm text-info">
                        {t("common.statictext.upload_files_detail")}
                      </p>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="use_bates_numbers"
              render={({ field }) => (
                <div className="flex flex-col gap-1">
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("sources.label.use_bates_numbers")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                  <FormDescription>
                    {t("sources.statictext.use_bates_numbers")}
                  </FormDescription>
                </div>
              )}
            />
            {!caseSource && (
              <FormField
                control={form.control}
                name="send_notification_emails"
                render={({ field }) => (
                  <div>
                    <FormItem className="flex flex-row items-center gap-2">
                      <FormControl>
                        <Checkbox
                          checked={field.value || false}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="!mt-0">
                        {t("sources.label.send_notification_emails")}
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />
            )}

            {send_notification_emails && (
              <FormField
                control={form.control}
                name="notification_recipients"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("sources.label.notification_recipients")}{" "}
                      {usersLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <MultiSelect
                      selected={field.value || []}
                      options={usersList
                        .filter((item) => item.email)
                        .map((item) => ({
                          // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
                          label: `${item.first_name} ${item.last_name} (${item.email})`,
                          value: item.id.toString(),
                        }))}
                      {...field}
                      className="sm:w-[510px]"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="source_starting_bates_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("sources.label.starting_bates_number")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        "sources.placeholder.starting_bates_number",
                      )}
                      {...field}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="corresponding_page_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("sources.label.corresponding_page_number")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        "sources.placeholder.corresponding_page_number",
                      )}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {defaultCase ? (
              defaultCase.name
            ) : (
              <FormField
                control={form.control}
                name="case"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("common.label.case")}{" "}
                      {casesLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <DynamicSelectorV2
                      onValueChange={field.onChange}
                      value={field.value || undefined}
                      options={casesList.map((item) => ({
                        label: item.name,
                        value: item.id.toString(),
                      }))}
                      className="selector"
                      onLoadNext={loadNextCase}
                      onSearch={(searchValue) => {
                        caseSearch.set(searchValue);
                      }}
                      placeholder={t("sources.placeholder.select_case")}
                      totalCount={casesResult?.count || 0}
                      pageSize={pagination.pageSize}
                      currentPage={pagination.page}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <DialogFooter>
              <Button disabled={loading} type="submit" form="case-source-form">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
