import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import * as SelectPrimitive from "@radix-ui/react-select";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";

interface DynamicSelectorOption {
  label: string;
  value: string;
}

interface DynamicSelectorInterface extends SelectPrimitive.SelectProps {
  placeholder: string;
  className: string;
  options: DynamicSelectorOption[];
  loading?: boolean;
  onLoadNext: () => void;
  onSearch: (value: string) => void;
  includeAllOption?: boolean;
  disabled?: boolean;
}

export function DynamicSelector({
  onValueChange,
  value,
  placeholder,
  className,
  options,
  onSearch,
  onLoadNext,
  includeAllOption = false,
  disabled = false,
}: DynamicSelectorInterface) {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState("");
  return (
    <Select onValueChange={onValueChange} value={value}>
      <div>
        <SelectTrigger className={className} disabled={disabled}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
      </div>
      <SelectContent>
        <SelectGroup>
          <Input
            value={search}
            type="text"
            placeholder={t("common.placeholder.search")}
            onChange={(event) => {
              setSearch(event.target.value);
              onSearch(event.target.value);
            }}
          />
        </SelectGroup>
        <SelectSeparator />
        <SelectGroup className="overflow-y-auto max-h-[10rem]">
          {!!includeAllOption && (
            <SelectItem value={"all"} key={"all"}>
              {t("common.values.all")}
            </SelectItem>
          )}
          {options.map((item) => {
            return (
              <SelectItem value={item.value} key={item.value}>
                {item.label}
              </SelectItem>
            );
          })}
        </SelectGroup>
        <SelectSeparator />
        <SelectGroup className="flex flex-wrap gap-2 items-center justify-center">
          <Button variant="link" onClick={onLoadNext}>
            {t("components.button.load_more")}
          </Button>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
