import React, { useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { useCaseSourceColumn } from "./hooks";
import { useListApi } from "@/hooks/useListApi";
import { Button } from "@/components/ui/button";
import { BsPlusCircleFill } from "react-icons/bs";
import { AddUpdateCaseSource } from "./AddUpdateCaseSource";
import { CustomPageSelector, CustomPagination } from "../CustomPagination";
import { CustomPaginationInfo } from "../CustomPaginationInfo";
import Loader from "@/components/ui/loader";
import { Input } from "@/components/ui/input";
import { Source, sourcesApi } from "@/api/source";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

function CaseSourceTable() {
  const { t } = useTranslation();
  const [selectedCaseSource, selectCaseSource] = useState<Source>();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const hasAddSourcePermission = usePermission("review.add_source");

  const { refresh, loading, pagination, apiResult, search, order } =
    useListApi<Source>({ baseApiObject: sourcesApi, enable: true });

  const { columns } = useCaseSourceColumn({
    refresh: refresh,
    setOrder: order.set,
    edit: (item: Source) => {
      selectCaseSource(item);
      setOpenEdit(true);
    },
    hideDownloadAction: true,
  });

  return (
    <div className="w-full my-8">
      <h3 className="text-3xl font-bold text-center mb-8">
        {t("sources.title.sources")}
      </h3>
      <div className="flex justify-between items-center">
        <AddUpdateCaseSource
          open={openEdit}
          setOpen={setOpenEdit}
          caseSource={selectedCaseSource}
          refresh={refresh}
        />
        {hasAddSourcePermission && (
          <AddUpdateCaseSource
            trigger={
              <Button className="flex gap-2">
                <BsPlusCircleFill /> {t("sources.button.add")}
              </Button>
            }
            open={openCreate}
            setOpen={setOpenCreate}
            refresh={refresh}
          />
        )}
      </div>
      <div className="flex gap-2 w-full my-4 md:w-[410px]">
        <Input
          value={search.value}
          type="text"
          placeholder={t("common.placeholder.search")}
          onChange={(event) => search.set(event.target.value)}
        />
        {search.value && (
          <Button onClick={() => search.set("")}>
            {t("common.button.clear")}
          </Button>
        )}
      </div>
      {loading ? (
        <div className="flex min-h-[300px] md:min-h-[500px] items-center justify-center max-h-auto">
          <Loader />
        </div>
      ) : (
        <>
          <div className="min-h-[300px] md:min-h-[500px] max-h-auto">
            <DataTable columns={columns} data={apiResult?.results || []} />
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="flex flex-col md:flex-row md:w-2/3   md:space-x-4 mt-3">
                <CustomPageSelector
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(value) => {
                    pagination.setPageSize(value);
                    pagination.setPage(1);
                  }}
                />
                <CustomPaginationInfo
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                />
              </div>
              <div className="flex md:space-x-4 mt-4 pr-5 pt-2">
                <CustomPagination
                  totalCount={apiResult?.count || 0}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.page}
                  onPageChange={(value) => pagination.setPage(value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CaseSourceTable;
