import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Source, sourcesApi } from "@/api/source";
import { ColumnDef } from "@tanstack/react-table";
import { BsDownload, BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { DeleteCaseSource } from "./DeleteCaseSource";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { toast } from "@/components/ui/use-toast";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { convertFileUrlToFileName, renderDateForTable } from "@/lib/utils";
import { usePermission } from "@/hooks/usePermission";
import { useTranslation } from "react-i18next";

export const useCaseSourceColumn = ({
  refresh,
  setOrder,
  edit,
  hideDownloadAction = false,
}: {
  refresh: () => Promise<void>;
  setOrder: Dispatch<SetStateAction<string>>;
  edit: (item: Source) => void;
  hideDownloadAction?: boolean;
}) => {
  const { t } = useTranslation();
  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const navigate = useNavigate();
  const hasEditPermission = usePermission("review.change_source");
  const hasDeletePermission = usePermission("review.delete_source");

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<Source>[] = [
    {
      accessorKey: "title",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("title");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.title")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "title"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "title"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const sourceItem = row.original;
        const value = (row.getValue("title") as string) || "--";
        return (
          <div className="text-left">
            <span
              className="text-left text-primary hover:underline cursor-pointer"
              onClick={() => navigate(`/sources/${sourceItem.id}`)}
            >
              {value}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "institution",
      header: () => (
        <div className="text-left">{t("sources.label.institution")}</div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("institution") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "file",
      header: () => <div className="text-left">{t("common.label.file")}</div>,
      cell: ({ row }) => {
        const sourceItem = row.original;
        const value = (row.getValue("file") as string) || "--";
        const fileName = convertFileUrlToFileName(value);
        return (
          <div className="text-left">
            {value !== "--" ? (
              <>
                <a
                  className="text-left text-primary hover:underline cursor-pointer break-all"
                  href={sourceItem.file as string}
                  target="_blank"
                  rel="noreferrer"
                >
                  {fileName}
                </a>
                {!!sourceItem.file_uploaded_date && (
                  <p>
                    {t("common.label.uploaded_at")}
                    {": "}
                    <i>{renderDateForTable(sourceItem.file_uploaded_date)}</i>
                  </p>
                )}
              </>
            ) : (
              value
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "total_pages",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("total_pages");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("sources.label.total_pages")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "total_pages"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "total_pages"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("total_pages") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const sourceItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            {!hideDownloadAction && (
              <a
                href={sourceItem.file as string}
                target="_blank"
                rel="noreferrer"
                className="break-all"
              >
                <BsDownload />
              </a>
            )}
            {hasEditPermission && (
              <BsFillPencilFill
                className="hover:text-primary cursor-pointer"
                onClick={() => edit(sourceItem)}
              />
            )}
            {hasDeletePermission && (
              <DeleteCaseSource
                trigger={
                  <BsTrashFill className="hover:text-primary cursor-pointer" />
                }
                caseSource={sourceItem}
                refresh={refresh}
              />
            )}
          </div>
        );
      },
    },
  ];

  return { columns, getSortedValues };
};

export const useCaseSourceDeleteApi = ({
  refresh,
}: {
  refresh?: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { values } = useContext<AuthContextType>(AuthContext);
  const { user } = values;

  const errorHandler = useApiErrorHandler("useCaseSourceDeleteApi", {});

  const [loading, setLoading] = useState<boolean>(false);

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        await sourcesApi.delete({
          id: id,
          token: user?.access,
          data: {
            deleted_by: user?.user.id,
          },
        });
        toast({
          title: t("common.toast.deleted_successfully", {
            resource: t("sources.title.source"),
          }),
        });
        await refresh?.();
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
    [user?.user.id, user?.access, errorHandler, refresh, t],
  );

  return {
    loading,
    deleteItem,
  };
};
