import { useToast } from "@/components/ui/use-toast";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import axios from "axios";
import { useCallback, useContext } from "react";
import { UseFormSetError } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const useApiErrorHandler = (
  name: string,
  {
    callback401,
    callback500,
    callback403,
    setFormError,
  }: {
    callback401?: () => void;
    callback500?: () => void;
    callback403?: () => void;
    setFormError?: UseFormSetError<Record<string, unknown>>;
  },
) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext) as AuthContextType;
  const { toast } = useToast();

  return useCallback(
    (error: unknown) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status && error.response?.status === 400) {
          if (error.response?.data) {
            Object.keys(error.response.data).map((item, index) => {
              if (setFormError) {
                return setFormError?.(
                  item,
                  {
                    message:
                      error.response?.data[item] ||
                      t("common.title.invalid_value"),
                  },
                  {
                    shouldFocus: index === 0,
                  },
                );
              } else {
                return toast({
                  title: t("common.toast.error", { item }),
                  description: error.response?.data[item],
                });
              }
            });
          }
        }
        if (error.response?.status && error.response?.status === 500) {
          toast({
            title: t("common.toast.something_went_wrong"),
            description: t("common.statictext.try_again_later"),
          });
          callback500?.();
        }
        if (error.response?.status && error.response?.status === 403) {
          if (typeof callback403 === "function") {
            callback403();
          } else {
            toast({
              title: t("common.toast.prohibited"),
              description: t("common.statictext.user_doesnt_have_permissions"),
            });
          }
        }
        if (error.response?.status && error.response?.status === 401) {
          if (typeof callback401 === "function") {
            callback401();
          } else {
            logout?.();
          }
        }
      }
      console.error(name, error);
    },
    [
      name,
      logout,
      toast,
      callback401,
      callback500,
      callback403,
      setFormError,
      t,
    ],
  );
};
