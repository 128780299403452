import { BaseApi } from "@/api/index";
import { Case } from "./cases";
import { Source } from "./source";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface UserGroup {
  id: number;
  name: string;
}

export interface User {
  id: number;
  last_login?: string;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  group?: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  groups: UserGroup[];
  user_permissions: number[];
  deleted_by?: number;
  updated_by?: number;
  password?: string;
}

export interface ResourceAllowence {
  allowed: boolean;
}

export type CaseWithAllowance = Case & ResourceAllowence;
export type SourceWithAllowance = Source & ResourceAllowence;

export interface UserDetail {
  user_profile: User;
  cases: CaseWithAllowance[];
  casesources: CaseWithAllowance[];
  grouped_sources: [Case, SourceWithAllowance[]][];
}

class UserApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/users" });
  }
}

class UserGroupApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/user_groups" });
  }
}

export const userApi = new UserApi<User>();
export const userGroupApi = new UserGroupApi<UserGroup>();
