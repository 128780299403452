import React, { useCallback, useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import { MultiSelect } from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { convertFileUrlToFileName, zodKeys } from "@/lib/utils";
import {
  ResearchAuthor,
  researchAuthorApi,
  ResearchDocument,
  researchDocumentApi,
} from "@/api/research";
import { Case, casesApi } from "@/api/cases";
import { Checkbox } from "@/components/ui/checkbox";
import { Textarea } from "@/components/ui/textarea";
import { FaPaperclip } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { useListApi } from "@/hooks/useListApi";
import { CustomToolTip } from "../../CustomToolTip";
import { useTranslation } from "react-i18next";

export function AddUpdateResearchDocument({
  open,
  setOpen,
  document,
  trigger,
  defaultCase,
  refresh,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  document?: ResearchDocument;
  trigger?: JSX.Element;
  defaultCase?: number;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const { loading: researchAuthorLoading, apiResult } =
    useListApi<ResearchAuthor>({
      baseApiObject: researchAuthorApi,
      defaultParams: {
        research: document?.id,
      },
      enable: open,
    });

  const author = apiResult?.results.find(
    (item) => item.research === document?.id,
  );

  const { loading: casesLoading, list: casesList } = useListApi<Case>({
    baseApiObject: casesApi,
    defaultPageSize: 1000,
    enable: open,
  });

  const FormSchema = z.object({
    title: z.string().min(3, {
      message: t("research.statictext.title_too_short"),
    }),
    description: z.string().min(3, {
      message: t("research.statictext.description_too_short"),
    }),
    file: z.instanceof(File).or(z.string()),
    private: z.boolean().default(false).optional(),
    cases: z.string().array(),

    // Citation.
    article: z.string().nullable().optional(),
    journal_name: z.string().nullable().optional(),
    volume: z.string().nullable().optional(),
    supplement_or_issue: z.string().nullable().optional(),
    year: z.string().nullable().optional(),
    page_from: z.string().nullable().optional(),
    page_to: z.string().nullable().optional(),
    retrieved_url: z.string().nullable().optional(),

    // Author
    first_name: z.string().nullable().optional(),
    middle_name: z.string().nullable().optional(),
    last_name: z.string().nullable().optional(),
  });

  const RefinedFormSchema = FormSchema.refine(
    (data) => {
      const pageFrom = data.page_from || 0;
      const pageTo = data.page_to || 0;
      return +pageFrom <= +pageTo;
    },
    {
      message: t("research.statictext.page_to_must_be_greater"),
      path: ["page_to"], // specify the field that will be invalid
    },
  );

  const form = useForm<z.infer<typeof RefinedFormSchema>>({
    resolver: zodResolver(RefinedFormSchema),
    defaultValues: {
      title: "",
      description: "",
      file: new File([], ""),
      cases: [],
      private: false,

      article: "",
      journal_name: "",
      volume: "",
      supplement_or_issue: "",
      year: "",
      page_from: "",
      page_to: "",
      retrieved_url: "",

      first_name: "",
      middle_name: "",
      last_name: "",
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdateResearchDocument", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  const onSubmit = useCallback(
    async (data: z.infer<typeof RefinedFormSchema>) => {
      try {
        setLoading?.(true);
        if (document && document.id) {
          await researchDocumentApi.update({
            id: document.id.toString() + "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              title: data.title,
              description: data.description,
              ...(typeof data.file === "string" ? {} : { file: data.file }), // Skip if file it not changed.
              private: data.private,
              article: data.article,
              journal_name: data.journal_name,
              volume: data.volume,
              supplement_or_issue: data.supplement_or_issue,
              year: data.year,
              case_ids: data.cases.join(","),
              page_from: data.page_from,
              page_to: data.page_to,
              retrieved_url: data.retrieved_url,
              updated_by: user?.user.id,
            },
            token: user?.access,
          });

          if (author && author.id) {
            if (!data.first_name && !data.middle_name && !data.last_name) {
              await researchAuthorApi.delete({
                id: author.id.toString() + "/",
                data: {
                  deleted_by: user?.user.id,
                },
                token: user?.access,
              });
            } else {
              await researchAuthorApi.update({
                id: author.id.toString() + "/",
                data: {
                  first_name: data.first_name,
                  middle_name: data.middle_name,
                  last_name: data.last_name,
                  updated_by: user?.user.id,
                },
                token: user?.access,
              });
            }
          }
          toast({
            title: t("common.toast.updated_successfully", {
              resource: t("research.title.research_document"),
            }),
          });
        } else {
          const research = await researchDocumentApi.create({
            url: "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              title: data.title,
              description: data.description,
              file: data.file,
              private: data.private,
              article: data.article,
              journal_name: data.journal_name,
              volume: data.volume,
              supplement_or_issue: data.supplement_or_issue,
              year: data.year,
              page_from: data.page_from,
              case_ids: data.cases.join(","),
              page_to: data.page_to,
              retrieved_url: data.retrieved_url,
              created_by: user?.user.id,
            },
            token: user?.access,
          });

          if (data.first_name || data.middle_name || data.last_name) {
            await researchAuthorApi.create({
              data: {
                first_name: data.first_name,
                middle_name: data.middle_name,
                last_name: data.last_name,
                created_by: user?.user.id,
                research: research.data.id,
              },
              token: user?.access,
            });
          }
          toast({
            title: t("common.toast.added_successfully", {
              resource: t("research.title.research_document"),
            }),
          });
          form.reset();
        }
        await refresh?.();
        setOpen(false);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading?.(false);
      }
    },
    [
      author,
      user,
      document,
      errorHandler,
      refresh,
      setLoading,
      setOpen,
      form,
      t,
    ],
  );

  useEffect(() => {
    if (document) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...document,
            ...(author ? author : {}),
            cases: document.cases.map((item) => item.toString()),
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, author]);

  useEffect(() => {
    if (defaultCase !== undefined && open) {
      form.setValue("cases", [defaultCase.toString()], { shouldTouch: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCase, open]);

  const buttonAction = document?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="min-w-[70%] overflow-scroll overflow-x-hidden h-full">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {buttonAction} {t("research.title.research_document")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.title")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.title")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="private"
              render={({ field }) => (
                <div>
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("common.label.private")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                  <FormLabel className="text-slate-400">
                    {t("research.statictext.private")}
                  </FormLabel>
                </div>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.description")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("common.placeholder.description")}
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => {
                let fileName = null;
                if (typeof field.value === "string") {
                  fileName = convertFileUrlToFileName(field.value);
                }
                return (
                  <FormItem>
                    <FormLabel>* {t("common.label.file")}</FormLabel>
                    {fileName && (
                      <div className="flex gap-2 items-center">
                        <FaPaperclip color="skyblue" />
                        <a
                          href={field.value as string}
                          target="_blank"
                          rel="noreferrer"
                          className="underline text-primary break-all"
                        >
                          {fileName}
                        </a>
                        <CustomToolTip
                          tip={t("common.statictext.remove_file")}
                          trigger={
                            <ImCross
                              className="cursor-pointer"
                              onClick={() => {
                                form.setValue("file", new File([], ""));
                              }}
                              color="red"
                            />
                          }
                        />
                      </div>
                    )}
                    <FormControl>
                      <Input
                        type="file"
                        {...field}
                        value={undefined}
                        onChange={(event) =>
                          form.setValue(
                            "file",
                            event.target.files
                              ? event.target.files[0]
                              : new File([], ""),
                          )
                        }
                      />
                    </FormControl>
                    <FormDescription>
                      {t("common.statictext.upload_files")}
                      <p className="text-sm text-info">
                        {t("common.statictext.upload_files_detail")}
                      </p>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {!defaultCase && (
              <FormField
                control={form.control}
                name="cases"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("common.label.case")}{" "}
                      {casesLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <MultiSelect
                      selected={field.value}
                      options={
                        casesList.map((item) => ({
                          label: item.name,
                          value: item.id.toString(),
                        })) || []
                      }
                      {...field}
                      className="sm:w-[510px]"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <h3 className="text-2xl mt-8">{t("research.label.citation")}</h3>
            <hr />
            <div className="flex flex-row gap-4 items-center">
              <FormLabel>{t("research.label.citation_author")}</FormLabel>
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("common.label.first_name")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("common.placeholder.first_name")}
                        {...field}
                        value={field.value || undefined}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="middle_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("common.label.middle_name")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("common.placeholder.middle_name")}
                        {...field}
                        value={field.value || undefined}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("common.placeholder.last_name")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("common.placeholder.last_name")}
                        {...field}
                        value={field.value || undefined}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="article"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("research.label.article")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("research.placeholder.article")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="journal_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("research.label.journal_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("research.placeholder.journal_name")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="volume"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("research.label.volume")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("research.placeholder.volume")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="supplement_or_issue"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("research.label.supplement_or_issue")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        "research.placeholder.supplement_or_issue",
                      )}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("research.label.year")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("research.placeholder.year")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="page_from"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.start_page")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("research.placeholder.start_page")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="page_to"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.end_page")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("research.placeholder.end_page")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="retrieved_url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("research.label.retrieved_url")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("research.placeholder.retrieved_url")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={loading || researchAuthorLoading} type="submit">
                {loading || researchAuthorLoading
                  ? t("common.button.loading")
                  : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
